import { HttpService } from '~/helpers';
import { UserService } from '~/services';

const endpoint = 'sistema/pedido';

const formatFilters = filters =>
  filters
    ? [
        { nome_campo: 'a.cliente', valor: filters.cliente, tipo: 'int' },
        { nome_campo: 'a.filial', valor: filters.filial, tipo: 'int' },
        { nome_campo: 'a.data', valor: filters.inicio && filters.fim ? `${filters.inicio} AND ${filters.fim}` : '', tipo: 'datas' },
        { nome_campo: 'a.codigo', valor: filters.codigo, tipo: 'int' },
        { nome_campo: 'a.mapa', valor: filters.mapa, tipo: 'int' },
      ]
    : [];

const formatValues = values => ({
  ...values,
  usuario: UserService.getUser().login,
});

const PedidoService = {
  getAll: (values, onSuccess, onError) => {
    HttpService.call(endpoint, 'getAll', [formatFilters(values)], onSuccess, onError);
  },
  getAllProdutos: (values, pesquisa, isInventario, onSuccess, onError) => {
    HttpService.call(endpoint, 'getAllProdutos', [formatValues(values), pesquisa, isInventario], onSuccess, onError);
  },
  getAllItens: (codigo, onSuccess, onError) => {
    HttpService.call(endpoint, 'getAllItens', [codigo], onSuccess, onError);
  },
  getDataVenda: (onSuccess, onError) => {
    HttpService.call(endpoint, 'getDataVenda', [], onSuccess, onError);
  },
  inserir: (values, onSuccess, onError) => {
    HttpService.call(endpoint, 'inserir', [formatValues(values)], onSuccess, onError);
  },
  alterar: (values, onSuccess, onError) => {
    HttpService.call(endpoint, 'alterar', [formatValues(values)], onSuccess, onError);
  },
  excluir: (venda, onSuccess, onError) => {
    HttpService.call(endpoint, 'excluir', [venda, UserService.getUser().login], onSuccess, onError);
  },
  verificaDataPedido: (origem, dataPedido, onSuccess, onError) => {
    HttpService.call(endpoint, 'verificaDataPedido', [origem, dataPedido], onSuccess, onError);
  },
};

export default PedidoService;
