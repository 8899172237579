import React from 'react';
import { Menu } from 'antd';
import PubSub from 'pubsub-js';
import { SwapOutlined } from '@ant-design/icons';
import { Strings } from '~/helpers/general';

class Empresas extends React.PureComponent {
  onClick = () => {
    const { onClick } = this.props;

    PubSub.publish(Strings.PUBSUB_EMPRESAS);

    if (onClick) {
      onClick();
    }
  };

  render() {
    const { onClick, ...rest } = this.props;

    return (
      <Menu.Item {...rest} onClick={this.onClick}>
        <SwapOutlined />
        Empresas
      </Menu.Item>
    );
  }
}

export default Empresas;
