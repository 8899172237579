import { HttpService } from '~/helpers';
import { UserService } from '~/services';

const endpoint = 'sistema/contentores_embalagens';

const formatFilters = filters =>
  filters.codigo
    ? [{ nome_campo: 'a.idt', valor: filters.codigo, tipo: 'int' }]
    : [
        { nome_campo: 'a.cliente', valor: filters.cliente, tipo: 'int' },
        { nome_campo: 'a.filial', valor: filters.filial, tipo: 'int' },
        { nome_campo: 'a.data', valor: `${filters.inicio} AND ${filters.fim}`, tipo: 'datas' },
        { nome_campo: 'a.filial', valor: filters.filial, tipo: 'int' },
        { nome_campo: 'a.placa', valor: filters.placa, tipo: 'string' },
        { nome_campo: 'a.nome_usuario', valor: filters.nome_usuario, tipo: 'string' },
        { nome_campo: 'a.nome_motorista', valor: filters.nome_motorista, tipo: 'string' },
      ];

const formatValues = values => ({
  ...values,
  usuario: UserService.getUser().nome,
});

const ContentoresEmbalagensService = {
  getAll: (values, onSuccess, onError) => {
    HttpService.call(endpoint, 'getAll', [formatFilters(values)], onSuccess, onError);
  },
  getAllProdutos: (onSuccess, onError) => {
    HttpService.call(endpoint, 'getAllProdutos', [], onSuccess, onError);
  },
  getAllItens: (codigo, onSuccess, onError) => {
    HttpService.call(endpoint, 'getAllItens', [codigo], onSuccess, onError);
  },
  inserir: (values, onSuccess, onError) => {
    HttpService.call(endpoint, 'inserir', [formatValues(values)], onSuccess, onError);
  },
  alterar: (values, onSuccess, onError) => {
    HttpService.call(endpoint, 'alterar', [formatValues(values)], onSuccess, onError);
  },
  excluir: (venda, onSuccess, onError) => {
    HttpService.call(endpoint, 'excluir', [venda, UserService.getUser().nome], onSuccess, onError);
  },
};

export default ContentoresEmbalagensService;
