import { colors } from '~/options/general';

const idle = {
  id: 'idle',
  perfectPast: 'alterado',
};

const insert = {
  id: 'insert',
  name: 'Incluir',
  color: colors.daybreakBlue,
  perfectPast: 'incluído',
  perfil: 'incluir',
  icon: 'plus',
};

const update = {
  id: 'update',
  name: 'Alterar',
  color: colors.calendulaGold,
  perfectPast: 'alterado',
  perfil: 'alterar',
  icon: 'edit',
};

const remove = {
  id: 'remove',
  name: 'Excluir',
  color: colors.dustRed,
  perfectPast: 'excluído',
  perfil: 'excluir',
  icon: 'delete',
};

const search = {
  id: 'search',
  name: 'Buscar',
  icon: 'search',
};

const open = {
  id: 'open',
  name: 'Abrir',
  perfectPast: 'aberto',
};

const view = {
  id: 'view',
  name: 'Visualizar',
};

export default { idle, insert, update, remove, search, open, view };
