import React from 'react';
import { Tooltip } from 'antd';
import classNames from 'classnames';
import './Legend.less';
import { BgColorsOutlined } from '@ant-design/icons';

const Legend = React.memo(({ colors, placement }) => (
  <Tooltip
    title={
      <>
        {colors.map(({ title, color }) => (
          <div key={color.id} className="legend-item">
            <div className={classNames('legend-item-block', color.className, 'color-table-legend')} />
            {title}
          </div>
        ))}
      </>
    }
    placement={placement}
  >
    <BgColorsOutlined />
  </Tooltip>
));

export default Legend;
