import menus from './menus';
import locales from './locales';
import colors from './colors';
import tabPanes from './tabPanes';
import actions from './actions';
import affirmatives from './affirmatives';
import rowColors from './rowColors';
import weekDays from './weekDays';
import uploadPaths from './uploadPaths';

export { menus, locales, colors, tabPanes, actions, affirmatives, rowColors, weekDays, uploadPaths };
