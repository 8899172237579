// Cadastro » Cliente
const cadClienteContrato = {
  id: 'cadClienteContrato',
  table: 'cad_cliente_contrato',
  path: 'cadastro/clientes/contratos/',
  fileSize: 10,
};

const cadCliente = {
  id: 'cadCliente',
  table: 'cad_cliente',
  path: 'cadastro/clientes/cliente/',
  fileSize: 10,
};

const cadClienteFidelizado = {
  id: 'cadClienteFidelizado',
  table: 'cad_fidelizado',
  path: 'cadastro/clientes/fidelizado/',
  fileSize: 10,
};

// Cadastro » Produto
const cadProduto = {
  id: 'cadProduto',
  table: 'cad_produto',
  path: 'cadastro/produtos/produto/',
  fileSize: 10,
};

// Cadastro » Veículo
const cadVeiculo = {
  id: 'cadVeiculo',
  table: 'cad_veiculo',
  path: 'cadastro/veiculos/veiculo/',
  fileSize: 10,
};

// Cadastro » Pedido
const cadClassificacao = {
  id: 'cadClassificacao',
  table: 'cad_classificacao',
  path: 'cadastro/pedido/classificacao/',
  fileSize: 5,
};

const cadPagamentoCondicao = {
  id: 'cadPagamentoCondicao',
  table: 'cad_forma_pagamento_pedido',
  path: 'cadastro/pedido/pagamento_condicao/',
  fileSize: 5,
};

const cadEtiqueta = {
  id: 'cadEtiqueta',
  table: 'cad_etiqueta',
  path: 'cadastro/pedido/etiqueta/',
  fileSize: 5,
};

const cadOperacao = {
  id: 'cadOperacao',
  table: 'cad_operacao',
  path: 'cadastro/pedido/operacao/',
  fileSize: 5,
};

const cadRegiao = {
  id: 'cadRegiao',
  table: 'cad_regiao',
  path: 'cadastro/pedido/regiao/',
  fileSize: 5,
};

const cadRota = {
  id: 'cadRota',
  table: 'cad_rota',
  path: 'cadastro/pedido/rota/',
  fileSize: 5,
};

const cadSupervisor = {
  id: 'cadSupervisor',
  table: 'cad_supervisor',
  path: 'cadastro/pedido/supervisor/',
  fileSize: 5,
};

const cadVendedor = {
  id: 'cadVendedor',
  table: 'cad_vendedor',
  path: 'cadastro/pedido/vendedor/',
  fileSize: 5,
};

// Cadastro » Geral

const cadEmpresa = {
  id: 'cadEmpresa',
  table: 'cad_empresa',
  path: 'cadastro/geral/empresa/',
  fileSize: 5,
};

const cadLocalEstoque = {
  id: 'cadLocalEstoque',
  table: 'cad_local_estoque',
  path: 'cadastro/geral/local_estoque/',
  fileSize: 5,
};

const cadTipoFaturamento = {
  id: 'cadTipoFaturamento',
  table: 'cad_tipo_faturamento',
  path: 'cadastro/geral/tipo_faturamento/',
  fileSize: 5,
};

const cadNegocio = {
  id: 'cadNegocio',
  table: 'cad_negocio',
  path: 'cadastro/geral/negocio/',
  fileSize: 5,
};

const cadOperadora = {
  id: 'cadOperadora',
  table: 'cad_operadora',
  path: 'cadastro/geral/operadora/',
  fileSize: 5,
};

const cadPagamentoLocal = {
  id: 'cadPagamentoLocal',
  table: 'cad_local_pagamento',
  path: 'cadastro/geral/local_pagamento/',
  fileSize: 5,
};

// Cadastro » Funcionário

const cadFuncionarioFuncao = {
  id: 'cadFuncionarioFuncao',
  table: 'cad_funcionario_funcao',
  path: 'cadastro/funcionario/funcao/',
  fileSize: 5,
};

const cadFuncionario = {
  id: 'cadFuncionario',
  table: 'cad_funcionario',
  path: 'cadastro/funcionario/funcionario/',
  fileSize: 5,
};

const cadFuncionarioJornada = {
  id: 'cadFuncionarioJornada',
  table: 'cad_horario_trabalho',
  path: 'cadastro/funcionario/jornada/',
  fileSize: 5,
};

// Cadastro » Fornecedores

const cadFornecedor = {
  id: 'cadFornecedor',
  table: 'cad_fornecedor',
  path: 'cadastro/fornecedores/fornecedor/',
  fileSize: 5,
};

const cadFornecedorGrupo = {
  id: 'cadFornecedorGrupo',
  table: 'cad_fornecedor_grupo',
  path: 'cadastro/fornecedores/grupo/',
  fileSize: 5,
};

// Cadastro » Fiscal

const cadBeneficioFiscal = {
  id: 'cadBeneficioFiscal',
  table: 'cad_ncm_beneficio',
  path: 'cadastro/fiscal/beneficio_fiscal/',
  fileSize: 5,
};

const cadCest = {
  id: 'cadCest',
  table: 'cad_cest',
  path: 'cadastro/fiscal/cest/',
  fileSize: 5,
};

const cadCfop = {
  id: 'cadCfop',
  table: 'cad_cfop',
  path: 'cadastro/fiscal/cfop/',
  fileSize: 5,
};

const cadCnae = {
  id: 'cadCnae',
  table: 'cad_cnae',
  path: 'cadastro/fiscal/cnae/',
  fileSize: 5,
};

const cadClassificacaoCofins = {
  id: 'cadClassificacaoCofins',
  table: 'cad_classificacao_cofins',
  path: 'cadastro/fiscal/classificacao_cofins/',
  fileSize: 5,
};

const cadClassificacaoIpi = {
  id: 'cadClassificacaoIpi',
  table: 'cad_classificacao_ipi',
  path: 'cadastro/fiscal/classificacao_ipi/',
  fileSize: 5,
};

const cadClassificacaoPis = {
  id: 'cadClassificacaoPis',
  table: 'cad_classificacao_pis',
  path: 'cadastro/fiscal/classificacao_pis/',
  fileSize: 5,
};

const cadImpostoTabela = {
  id: 'cadImpostoTabela',
  table: 'cad_produto_imposto',
  path: 'cadastro/fiscal/imposto_tabela/',
  fileSize: 5,
};

const cadObservacaoNotaFiscal = {
  id: 'cadObservacaoNotaFiscal',
  table: 'cad_observacao_nota_fiscal',
  path: 'cadastro/fiscal/observacao_nota_fiscal/',
  fileSize: 5,
};

const cadSerie = {
  id: 'cadSerie',
  table: 'cad_serie',
  path: 'cadastro/fiscal/serie/',
  fileSize: 5,
};

const cadUnidade = {
  id: 'cadUnidade',
  table: 'cad_unidade',
  path: 'cadastro/fiscal/unidade/',
  fileSize: 5,
};

const cadUnidadeConversao = {
  id: 'cadUnidadeConversao',
  table: 'cad_conversao_unidade',
  path: 'cadastro/fiscal/unidade_conversao/',
  fileSize: 5,
};

// Cadastro » Financeiro

const cadContaPagar = {
  id: 'cadContaPagar',
  table: 'cad_pagar',
  path: 'financeiro/contas_pagar/conta_pagar/',
  fileSize: 10,
};

const cadContaReceber = {
  id: 'cadContaReceber',
  table: 'cad_receber',
  path: 'financeiro/contas_receber/conta_receber/',
  fileSize: 10,
};

const cadCartaoAdministradora = {
  id: 'cadCartaoAdministradora',
  table: 'cad_cartao_administradora',
  path: 'cadastro/financeiro/cartao_administradora/',
  fileSize: 5,
};

const cadCartaoRede = {
  id: 'cadCartaoRede',
  table: 'cad_cartao_rede',
  path: 'cadastro/financeiro/cartao_rede/',
  fileSize: 5,
};

const cadCartao = {
  id: 'cadCartao',
  table: 'cad_cartao',
  path: 'cadastro/financeiro/cartao/',
  fileSize: 5,
};

const cadCentroCusto = {
  id: 'cadCentroCusto',
  table: 'cad_centro_custo',
  path: 'cadastro/financeiro/centro_custo/',
  fileSize: 5,
};

const cadCentroCustoGrupo = {
  id: 'cadCentroCustoGrupo',
  table: 'cad_centro_custo_grupo',
  path: 'cadastro/financeiro/centro_custo_grupo/',
  fileSize: 5,
};

const cadClasseConta = {
  id: 'cadClasseConta',
  table: 'cad_classe_conta',
  path: 'cadastro/financeiro/classe_conta/',
  fileSize: 5,
};

const cadContaBancaria = {
  id: 'cadContaBancaria',
  table: 'cad_banco',
  path: 'cadastro/financeiro/conta_bancaria/',
  fileSize: 5,
};

const cadDescontoAcrescimo = {
  id: 'cadDescontoAcrescimo',
  table: 'cad_tipo_desconto_acrescimo',
  path: 'cadastro/financeiro/desconto_acrescimo/',
  fileSize: 5,
};

const cadPagamentoForma = {
  id: 'cadPagamentoForma',
  table: 'cad_forma_pagamento',
  path: 'cadastro/financeiro/pagamento_forma/',
  fileSize: 5,
};

const cadPlanoContaGrupo = {
  id: 'cadPlanoContaGrupo',
  table: 'cad_plano_conta_grupo',
  path: 'cadastro/financeiro/plano_conta_grupo/',
  fileSize: 5,
};

const cadPlanoConta = {
  id: 'cadPlanoConta',
  table: 'cad_plano_conta',
  path: 'cadastro/financeiro/plano_conta/',
  fileSize: 5,
};

// Movimento » Compras
const cadNotaEntrada = {
  id: 'cadNotaEntrada',
  table: 'nota_entrada',
  path: 'movimentos/compras/nota_entrada/',
  fileSize: 10,
};

export {
  cadClienteContrato,
  cadCliente,
  cadClienteFidelizado,
  // cadastro/produto
  cadProduto,
  // cadastro/veiculo
  cadVeiculo,
  // cadastro/pedido
  cadClassificacao,
  cadPagamentoCondicao,
  cadEtiqueta,
  cadOperacao,
  cadRegiao,
  cadRota,
  cadSupervisor,
  cadVendedor,
  // cadastro/geral
  cadEmpresa,
  cadLocalEstoque,
  cadTipoFaturamento,
  cadNegocio,
  cadOperadora,
  cadPagamentoLocal,
  // cadastro/funcionario
  cadFuncionarioFuncao,
  cadFuncionario,
  cadFuncionarioJornada,
  // cadastro/fornecedores
  cadFornecedor,
  cadFornecedorGrupo,
  // cadastro/fiscal
  cadBeneficioFiscal,
  cadCest,
  cadCfop,
  cadCnae,
  cadClassificacaoCofins,
  cadClassificacaoIpi,
  cadClassificacaoPis,
  cadImpostoTabela,
  cadObservacaoNotaFiscal,
  cadSerie,
  cadUnidade,
  cadUnidadeConversao,
  // cadastro/financeiro
  cadContaPagar,
  cadContaReceber,
  cadCartaoAdministradora,
  cadCartaoRede,
  cadCartao,
  cadCentroCusto,
  cadCentroCustoGrupo,
  cadClasseConta,
  cadContaBancaria,
  cadDescontoAcrescimo,
  cadPagamentoForma,
  cadPlanoContaGrupo,
  cadPlanoConta,
  // movimento/compras
  cadNotaEntrada,
};
export default {
  cadClienteContrato,
  cadCliente,
  cadClienteFidelizado,
  // cadastro/produto
  cadProduto,
  // cadastro/veiculo
  cadVeiculo,
  // cadastro/pedido
  cadClassificacao,
  cadPagamentoCondicao,
  cadEtiqueta,
  cadOperacao,
  cadRegiao,
  cadRota,
  cadSupervisor,
  cadVendedor,
  // cadastro/geral
  cadEmpresa,
  cadLocalEstoque,
  cadTipoFaturamento,
  cadNegocio,
  cadOperadora,
  cadPagamentoLocal,
  // cadastro/funcionario
  cadFuncionarioFuncao,
  cadFuncionario,
  cadFuncionarioJornada,
  // cadastro/fornecedores
  cadFornecedor,
  cadFornecedorGrupo,
  // cadastro/fiscal
  cadBeneficioFiscal,
  cadCest,
  cadCfop,
  cadCnae,
  cadClassificacaoCofins,
  cadClassificacaoIpi,
  cadClassificacaoPis,
  cadImpostoTabela,
  cadObservacaoNotaFiscal,
  cadSerie,
  cadUnidade,
  cadUnidadeConversao,
  // cadastro/financeiro
  cadContaPagar,
  cadContaReceber,
  cadCartaoAdministradora,
  cadCartaoRede,
  cadCartao,
  cadCentroCusto,
  cadCentroCustoGrupo,
  cadClasseConta,
  cadContaBancaria,
  cadDescontoAcrescimo,
  cadPagamentoForma,
  cadPlanoContaGrupo,
  cadPlanoConta,
  // movimento/compras
  cadNotaEntrada,
};
