import { colors } from '~/options/general';

const search = {
  color: colors.geekBlue,
};

const header = {
  color: colors.polarGreen,
};

export default { header, search };
