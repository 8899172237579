import { createActions, createReducer } from 'reduxsauce';
import { getMenuComponent } from '~/services/general/MenuService';

// types
const { Types, Creators } = createActions({
  addTab: ['menu', 'params'],
  changeTab: ['id'],
  removeTab: ['id'],
  resetTabs: [],
});

const INITIAL_STATE = {
  data: [],
  activeTab: undefined,
};

// reducers
// Adicionr um novo item às tabs
const add = (state = INITIAL_STATE, { menu, params }) => {
  const { data, ...rest } = state;
  const id = new Date().toISOString();

  return {
    ...rest,
    activeTab: id,
    data: [
      ...data,
      {
        menuId: menu.idt,
        nome: menu.nome,
        id,
        params,
        component: getMenuComponent(menu.chave),
      },
    ],
  };
};

// Disparado quando a tab ativa é alterada
const change = (state = INITIAL_STATE, { id }) => ({ ...state, activeTab: id });

const remove = (state = INITIAL_STATE, { id }) => {
  let { data, activeTab } = state;
  data = data.filter(tab => tab.id !== id);

  // Caso a tab ativa for removida, procura pela próxima tab a ser ativada
  if (activeTab === id) {
    // Caso não houver mais tabs abertas, nenhuma será ativada
    if (data.length === 0) {
      activeTab = undefined;
    } else {
      let lastIndex;

      for (let index = 0; index < state.data.length; index += 1) {
        if (state.data[index].id === id) {
          lastIndex = index;
          break;
        }
      }

      // Caso houver tabs mais recente, ativará a próxima da lista
      if (data.length - 1 >= lastIndex) {
        activeTab = data[lastIndex].id;
      }
      // Caso a tab mais recente for removida, ativará a última da lista
      else {
        activeTab = data[lastIndex - 1].id;
      }
    }
  }

  return {
    ...state,
    data,
    activeTab,
  };
};

const reset = () => INITIAL_STATE;

// Hookup reducers to types
const Reducers = createReducer(INITIAL_STATE, {
  [Types.ADD_TAB]: add,
  [Types.CHANGE_TAB]: change,
  [Types.REMOVE_TAB]: remove,
  [Types.RESET_TABS]: reset,
});

export { Reducers, Types, Creators };
export default Reducers;
