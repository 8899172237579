import React from 'react';
import { Row, Col, Card, Spin } from 'antd';
import { Markdown, Page, Input } from '~/components';
import README from './README.md';
import './RecadoPage.less';
import { UserService } from '~/services';

class RecadoPage extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      itens: [],
    };
  }

  componentDidMount() {
    this.handleSearch();
  }

  handleSearch = () => {
    this.setState({ isLoading: true });
    UserService.getAllRecados(UserService.getUser().codigo, UserService.getUser().filial, this.onSearch, () => this.onSearch());
  };

  onSearch = (itens = []) => {
    this.setState({ itens, isLoading: false });
  };

  render = () => {
    const { tabId } = this.props;
    const { isLoading, itens } = this.state;

    return (
      <>
        <Page tabId={tabId} closable={false} helpContent={<Markdown source={README} />}>
          {isLoading ? (
            <div style={{ textAlign: 'center' }}>
              <Spin size="large" />
            </div>
          ) : null}
          <Row gutter={4}>
            {itens.map(item => (
              <Col key={item.idt} md={24}>
                <Card title={item.titulo} className="quadro-anotacao-usuario">
                  <Input.TextArea initialValue={item.recado} className="input-stick-note" rows={20} capsLock={false} />
                </Card>
              </Col>
            ))}
          </Row>
        </Page>
      </>
    );
  };
}

export default RecadoPage;
