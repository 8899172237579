import affirmatives, { yes, no } from '~/options/general/affirmatives';
import { findBy, momentValue } from '~/utils';

const date = {
  id: 'date',
  formater: (value, format) => momentValue(value).format(format),
};

const affirmative = {
  id: 'affirmative',
  formater: value => (value === no.data && '1') || (value === yes.data && '2'),
};

const time = {
  id: 'time',
  formater: value => value.format('HH:mm:ss'),
};

const hour = {
  id: 'hour',
  formater: value => value.format('HH:mm'),
};

const boolean = {
  id: 'boolean',
  formater: value => findBy(affirmatives, value, 'booleanData').data,
};

export default { date, affirmative, time, hour, boolean };
