import _ from 'lodash';
import { store } from '~/stores';
import { Creators } from '~/stores/ducks/general/tabs';
import * as pages from '~/pages';
import { menus } from '~/options/general';

const flatMenu = data => {
  let flatData = [];

  _.forEach(data, item => {
    if (item.submenus) {
      flatData = _.concat(flatData, flatMenu(item.submenus));
    } else {
      flatData.push(item);
    }
  });

  return flatData;
};

const getMenuComponent = chave => pages[chave] || pages.NotFoundPage;

const MenuService = {
  open: (menu, params) => {
    const { addTab } = Creators;

    if (menu && menu.chave) {
      store.dispatch(addTab(menu, params));
    }
  },
  getById: id => _.find(_.concat(flatMenu(require('../../layouts/general/BasicLayout/components/BaseMenu/menu.json')), menus), item => item.idt === id) || {},
  getByTitle: title => _.find(_.concat(flatMenu(store.getState().menus.data), menus), item => item.nome === title) || {},
};

export { flatMenu, getMenuComponent };
export default MenuService;
