import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';
import { Creators } from '~/stores/ducks/general/general';
import { RightContent } from '..';
import { Logo } from '../../..';
import './GlobalHeader.less';

class GlobalHeader extends React.PureComponent {
  triggerResizeEvent = _.debounce(() => {
    const event = document.createEvent('HTMLEvents');
    event.initEvent('resize', true, false);
    window.dispatchEvent(event);
  }, 600);

  componentWillUnmount() {
    this.triggerResizeEvent.cancel();
  }

  handleCollapse = () => {
    const { general, setGeneral } = this.props;

    setGeneral({ ...general, isCollapsed: !general.isCollapsed });
    this.triggerResizeEvent();
  };

  render() {
    const { general } = this.props;

    return (
      <div className="global-header-header">
        {general.isMobile && <Logo className="global-header-logo" showName={false} />}
        <span className="global-header-trigger" onClick={this.handleCollapse} onKeyPress={this.handleCollapse} role="button">
          {general.isCollapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        </span>
        <RightContent />
      </div>
    );
  }
}

const mapStateToProps = ({ general }) => ({ general });
const mapDispatchToProps = dispatch => bindActionCreators(Creators, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(GlobalHeader);
