// Brasil
const estados = [
  { data: 'AC', label: 'AC', nome: 'Acre', codigo: '12' },
  { data: 'AL', label: 'AL', nome: 'Alagoas', codigo: '27' },
  { data: 'AM', label: 'AM', nome: 'Amazonas', codigo: '13' },
  { data: 'AP', label: 'AP', nome: 'Amapá', codigo: '16' },
  { data: 'BA', label: 'BA', nome: 'Bahia', codigo: '29' },
  { data: 'CE', label: 'CE', nome: 'Ceará', codigo: '23' },
  { data: 'DF', label: 'DF', nome: 'Distrito Federal', codigo: '53' },
  { data: 'ES', label: 'ES', nome: 'Espírito Santo', codigo: '32' },
  { data: 'GO', label: 'GO', nome: 'Goiás', codigo: '52' },
  { data: 'MA', label: 'MA', nome: 'Maranhão', codigo: '21' },
  { data: 'MG', label: 'MG', nome: 'Minas Gerais', codigo: '31' },
  { data: 'MS', label: 'MS', nome: 'Mato Grosso do Sul', codigo: '50' },
  { data: 'MT', label: 'MT', nome: 'Mato Grosso', codigo: '51' },
  { data: 'PA', label: 'PA', nome: 'Pará', codigo: '15' },
  { data: 'PB', label: 'PB', nome: 'Paraíba', codigo: '25' },
  { data: 'PE', label: 'PE', nome: 'Pernambuco', codigo: '26' },
  { data: 'PI', label: 'PI', nome: 'Piauí', codigo: '22' },
  { data: 'PR', label: 'PR', nome: 'Paraná', codigo: '41' },
  { data: 'RJ', label: 'RJ', nome: 'Rio de Janeiro', codigo: '33' },
  { data: 'RN', label: 'RN', nome: 'Rio Grande do Norte', codigo: '43' },
  { data: 'RO', label: 'RO', nome: 'Rondônia', codigo: '11' },
  { data: 'RR', label: 'RR', nome: 'Roraima', codigo: '14' },
  { data: 'RS', label: 'RS', nome: 'Rio Grande do Sul', codigo: '43' },
  { data: 'SC', label: 'SC', nome: 'Santa Catarina', codigo: '42' },
  { data: 'SE', label: 'SE', nome: 'Sergipe', codigo: '28' },
  { data: 'SP', label: 'SP', nome: 'São Paulo', codigo: '35' },
  { data: 'TO', label: 'TO', nome: 'Tocantins', codigo: '17' },
  { data: 'EX', label: 'EX', nome: 'Exterior', codigo: '99' },
];

export default estados;
