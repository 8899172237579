import React from 'react';
import { CloseOutlined } from '@ant-design/icons';
import Button from '..';

const Cancel = React.memo(({ children, ...rest }) => (
  <Button icon={<CloseOutlined />} onMobile="icon" {...rest}>
    {children || 'Cancelar'}
  </Button>
));

export default Cancel;
