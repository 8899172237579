import { MenuService } from '~/services';
import { accessWarning } from '~/components';

const hasAccess = (menuId, action, showAlert = true) => {
  const { access } = MenuService.getById(menuId);

  if (!access || !access[action.perfil]) {
    if (showAlert) accessWarning({ title: action.name });
    return false;
  }

  return true;
};

export default hasAccess;
