import React from 'react';
import { Chart, Tooltip, Geom, Coord } from 'bizcharts';
import { DataView } from '@antv/data-set';
import { Divider } from 'antd';
import classNames from 'classnames';
import ReactFitText from 'react-fittext';

import './Pie.less';

class Pie extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      legendData: [],
      legendBlock: false,
    };
  }

  componentDidMount() {
    window.addEventListener(
      'resize',
      () => {
        this.animationFrame = requestAnimationFrame(() => this.resize());
      },
      { passive: true }
    );
  }

  componentDidUpdate(prevProps) {
    // eslint-disable-next-line
    const { data } = this.props;

    // eslint-disable-next-line
    if (data !== prevProps.data) {
      // because of charts data create when rendered
      // so there is a trick for get rendered time
      this.getLegendData();
    }
  }

  componentWillUnmount() {
    window.cancelAnimationFrame(this.animationFrame);
    window.removeEventListener('resize', this.resize);
  }

  ongetG2Instance = chart => {
    this.chart = chart;

    requestAnimationFrame(() => {
      this.getLegendData();
      this.resize();
    });
  };

  // for custom lengend view
  getLegendData = () => {
    if (!this.chart) {
      return;
    }

    // get all the graphics
    const geoms = this.chart.getAllGeoms()[0];

    if (!geoms) {
      return;
    }

    // get the corresponding graphics
    const items = geoms.get('dataArray') || [];
    const legendData = items.map(item => ({
      // eslint-disable-next-line
      ...item[0]._origin,
      color: item[0].color,
      checked: true,
    }));

    this.setState({ legendData });
  };

  handleLegendClick = (item, index) => {
    const { legendData } = this.state;
    legendData[index] = { ...item, checked: !item.checked };

    if (this.chart) {
      const filteredLegendData = legendData
        .filter(legend => legend.checked)
        .map(legend => legend.x);

      this.chart.filter('x', value => filteredLegendData.indexOf(value) > -1);
    }

    this.setState({ legendData });
  };

  dataView = () => {
    const { data } = this.props;

    return new DataView()
      .source(data)
      .transform({ type: 'percent', field: 'y', dimension: 'x', as: 'percent' });
  };

  // for window resize auto responsive legend
  resize() {
    const { hasLegend } = this.props;

    if (!hasLegend || !this.node) {
      window.removeEventListener('resize', this.resize);
      return;
    }

    const { legendBlock } = this.state;

    if (this.node.parentNode.clientWidth <= 380) {
      if (!legendBlock) {
        this.setState({ legendBlock: !legendBlock });
      }
    } else if (legendBlock) {
      this.setState({ legendBlock: !legendBlock });
    }
  }

  render() {
    const {
      valueFormat,
      subTitle,
      total,
      hasLegend = false,
      className,
      style,
      height,
      forceFit,
      inner,
      animate,
      lineWidth = 1,
      colors,
      selected = true,
      tooltip = true,
    } = this.props;
    const { legendData, legendBlock } = this.state;
    const pieClassName = classNames('pie', className, { hasLegend: !!hasLegend, legendBlock });
    const scale = { x: { type: 'cat', range: [0, 1] }, y: { min: 0 } };
    const tooltipFormat = ['x*percent', (x, p) => ({ name: x, value: `${(p * 100).toFixed(2)}%` })];

    return (
      // eslint-disable-next-line
      <div ref={ref => (this.node = ref)} className={pieClassName} style={style}>
        <ReactFitText maxFontSize={25}>
          <div className="chart">
            <Chart
              scale={scale}
              height={height}
              forceFit={forceFit || true}
              data={this.dataView()}
              padding={[12, 0, 12, 0]}
              animate={animate || true}
              onGetG2Instance={this.ongetG2Instance}
            >
              {!!tooltip && <Tooltip showTitle={false} />}
              <Coord type="theta" innerRadius={inner || 0.75} />
              <Geom
                style={{ lineWidth, stroke: '#fff' }}
                tooltip={tooltip && tooltipFormat}
                type="intervalStack"
                position="percent"
                color={['x', colors]}
                selected={selected}
              />
            </Chart>
            {(subTitle || total) && (
              <div className="total">
                {subTitle && <h4>{subTitle}</h4>}
                {total && <div>{typeof total === 'function' ? total() : total}</div>}
              </div>
            )}
          </div>
        </ReactFitText>
        {hasLegend && (
          <ul className="legend">
            {legendData.map((item, index) => (
              // eslint-disable-next-line
              <li key={item.x} onClick={() => this.handleLegendClick(item, index)}>
                <span
                  className="dot"
                  style={{ backgroundColor: !item.checked ? '#aaa' : item.color }}
                />
                <span className="legendTitle">{item.x}</span>
                <Divider type="vertical" />
                <span className="percent">
                  {`${(Number.isNaN(item.percent) ? 0 : item.percent * 100).toFixed()}%`}
                </span>
                <span className="value">{valueFormat ? valueFormat(item.y, 2) : item.y}</span>
              </li>
            ))}
          </ul>
        )}
      </div>
    );
  }
}

export default Pie;
