import { UserService, MenuService, StoreService, CustomerService, RememberService, FileService, WebRelatorioService } from './general';

import { PedidoService, MapaPedidoService } from './pedidos';

import { PesquisaPrecoService, ContentoresEmbalagensService } from './pesquisa';

export {
  // general
  UserService,
  MenuService,
  StoreService,
  CustomerService,
  RememberService,
  FileService,
  WebRelatorioService,
  PedidoService,
  MapaPedidoService,
  PesquisaPrecoService,
  ContentoresEmbalagensService,
};
