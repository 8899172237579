import moment from 'moment';
import { momentValue } from '~/utils';

// const dateSorter = (a, b) => momentValue(a) - momentValue(b);

const dateSorter = (a, b) => {
    const aDate = moment(a).isValid() === true ? a : moment("1970-01-01")
    const bDate = moment(b).isValid() === true ? b : moment("1970-01-01")

    return momentValue(aDate) - momentValue(bDate)

};

export default dateSorter;
