import React from 'react';
import { BackTop as AntdBackTop } from 'antd';

const BackTop = React.memo(({ style, ...rest }) => (
  <AntdBackTop
    visibilityHeight={300}
    style={{ bottom: '24px', right: '24px', ...style }}
    {...rest}
  />
));

export default BackTop;
