import React from 'react';
import moment from 'moment';
import KeyboardEventHandler from 'react-keyboard-event-handler';
import { DatePicker as AntdDatePicker } from 'antd';
import classNames from 'classnames';
import { momentValue, backValue } from '~/utils';
import './DatePickerCell.less';

class InputCell extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { type, onChange, onBlur, className, record, field, functionAction, defaultValue, value, ...rest } = this.props;

    let placeholder = moment().format('DD/MM/YYYY');
    if (rest.picker === 'month') placeholder = moment().format('MM/YYYY');
    else if (rest.picker === 'year') placeholder = moment().format('YYYY');

    let format = 'DD/MM/YYYY';
    if (rest.picker === 'month') format = 'MM/YYYY';
    else if (rest.picker === 'year') format = 'YYYY';

    return (
      <KeyboardEventHandler
        handleKeys={['enter']}
        onKeyEvent={(key, e) => {
          const elements = Array.from(e.target.form.elements);
          const moveNext = elements.findIndex(item => item === e.target);
          if (moveNext > -1 && elements[moveNext + 1]) elements[moveNext + 1].focus();
        }}
      >
        <AntdDatePicker
          placeholder={placeholder}
          value={momentValue(defaultValue) || moment().format('DD/MM/YYYY')}
          defaultValue={momentValue(defaultValue) || moment().format('DD/MM/YYYY')}
          className={classNames('date-picker', className)}
          dropdownClassName={classNames('date-picker-dropdown')}
          format={format}
          onChange={(date, dateString) => {
            record[field] = backValue(date, 'date');

            if (onChange) {
              onChange(date, dateString);
            }
          }}
          onBlur={event => {
            if (onBlur) {
              onBlur(event);
            }
          }}
          allowClear
          {...rest}
        />
      </KeyboardEventHandler>
    );
  }
}

export default InputCell;
