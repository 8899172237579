import React from 'react';
import { Collapse as AntdCollapse, Tooltip } from 'antd';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import { Panel } from './components';
import { types } from './options';
import './Collapse.less';

const Collapse = React.memo(({ className, color, type, ...rest }) => (
  <AntdCollapse
    bordered={false}
    expandIcon={({ isActive }) => (
      <Tooltip placement="bottom" title={isActive ? 'Contrair' : 'Expandir'}>
        {isActive ? <MinusOutlined /> : <PlusOutlined />}
      </Tooltip>
    )}
    expandIconPosition="right"
    className={classNames('collapse', (color && color.className) || (type && types[type].color.className), className)}
    {...rest}
  />
));

Collapse.Panel = Panel;

export { Panel };
export default Collapse;
