import { calculaDigitoBlocoBoleto } from '~/utils';

const faturaValue = text => {
  const isBoleto = text.substr(0, 1) !== '8';
  let retorno = text.length > 54 && isBoleto ? text.substr(0, 54) : text;
  const isLeitora = text.indexOf(' ') === -1 && text.indexOf('.') === -1 && (text.length === 44 || text.length === 48);

  if (isBoleto) {
    if (isLeitora) {
      retorno = text
        .split(' ')
        .join('')
        .split('.')
        .join('');

      retorno =
        text.substr(0, 4) +
        text.substr(19, 5) +
        calculaDigitoBlocoBoleto(text.substr(0, 4) + text.substr(19, 5)) +
        text.substr(24, 10) +
        calculaDigitoBlocoBoleto(text.substr(24, 10)) +
        text.substr(34, 10) +
        calculaDigitoBlocoBoleto(text.substr(34, 10)) +
        text.substr(4, 15);
      retorno = `${retorno.substr(0, 5)}.${retorno.substr(5, 5)} ${retorno.substr(10, 5)}.${retorno.substr(15, 6)} ${retorno.substr(21, 5)}.${retorno.substr(
        26,
        6
      )} ${retorno.substr(32, 1)} ${retorno.substr(33, 14)}`;
    } else if (!isLeitora) {
      if (text.length === 5 || text.length === 17 || text.length === 30) {
        retorno += '.';
      } else if (text.length === 11 || text.length === 24 || text.length === 37 || text.length === 39) {
        retorno += ' ';
      }
    }
  } else if (isLeitora) {
    retorno = `${retorno.substr(0, 11)} ${retorno.substr(11, 1)} ${retorno.substr(12, 11)} ${retorno.substr(23, 1)} ${retorno.substr(24, 11)} ${retorno.substr(
      35,
      1
    )} ${retorno.substr(36, 11)} ${retorno.substr(47, 1)}`;
  } else if (
    text.length === 11 ||
    text.length === 13 ||
    text.length === 25 ||
    text.length === 27 ||
    text.length === 39 ||
    text.length === 41 ||
    text.length === 53
  ) {
    retorno += ' ';
  }

  return retorno;
};

export default faturaValue;
