import { getLocale } from '~/utils';

const decimalPlace = (number, precision = 0) =>
  (parseFloat(number) ? parseFloat(number) : 0)
    .toFixed(precision)
    .replace('.', getLocale().formats.decimalSeparator)
    .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1.')
    .replace(precision > 2 ? '.' : '', '');

export default decimalPlace;
