import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { UserMenu, SyncIcon } from './components';
import './RightContent.less';
import '~/layouts/components/HelpMenu/HelpMenu.less';
import Settings from './components/UserMenu/components/Settings';
import { Logout } from './components/UserMenu/components';

const RightContent = React.memo(({ className, settings, general }) => {
  return (
    <div
      className={classNames(className, 'right-content', {
        'right-content-dark': settings.theme === 'dark' && settings.layout === 'topmenu' && !general.isMobile,
      })}
    >
      <SyncIcon className="right-content-action" />
      <Settings className="right-content-action" />
      <Logout className="right-content-action" />
      <UserMenu className="right-content-action" />
    </div>
  );
});

const mapStateToProps = ({ settings, general, remember }) => ({ settings, general, remember });

export default connect(mapStateToProps)(RightContent);
