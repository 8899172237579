import React from 'react';
import classNames from 'classnames';
import { Tooltip } from 'antd';
import { openUrl } from '~/utils/general';

const Link = React.memo(({ url, children, title, tooltipProps, style, align, label, onClick = () => url && openUrl(url), ...rest }) => (
  <Tooltip title={title || url} destroyTooltipOnHide {...tooltipProps}>
    <a
      className={classNames(rest.className || 'link-component')}
      onClick={onClick}
      onKeyPress={onClick}
      role="link"
      style={{ textAlign: align || 'left' }}
      {...rest}
    >
      {children || label}
    </a>
  </Tooltip>
));

export default Link;
