import React from 'react';
import { Row, Col, Divider, Modal } from 'antd';
import { connect } from 'react-redux';
import {
  FooterToolbar,
  Navigator,
  Markdown,
  Page,
  Form,
  ContentWrapper,
  Table,
  Input,
  Button,
  DatePicker,
  IconDelete,
  Status,
  InputNumber,
} from '~/components';
import { actions, tabPanes } from '~/options';
import README from './README.md';
import { notifySuccess } from '~/utils';
import { ContentoresEmbalagensService, UserService, WebRelatorioService } from '~/services';

class ContentoresEmbalagensPage extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      itens: [],
      selectedItem: {},
      action: actions.idle,
      activeTab: tabPanes.table.key,
      isLoadingItens: false,
      itensCaixas: [],
      produtos: [],
    };
  }

  componentDidMount() {
    this.searchApi.submitForm();
    setTimeout(() => this.searchInput.focus(), 300);
    ContentoresEmbalagensService.getAllProdutos(produtos => this.setState({ produtos, itensCaixas: produtos }));
  }

  handleSearch = values => {
    this.setState({ isLoading: true });
    ContentoresEmbalagensService.getAll({ ...values, cliente: UserService.getUser().codigo, filial: UserService.getUser().filial }, this.onSearch, () =>
      this.onSearch()
    );
  };

  onSearch = (itens = []) => {
    this.setState({ itens, isLoading: false, selectedItem: {} });
  };

  handleSelect = record => {
    const { produtos, isLoadingItens } = this.state;

    this.saveApi.reset();
    this.setState({ selectedItem: record, itensCaixas: produtos }, () => {
      if (record.idt) {
        this.saveApi.setValues(record);

        this.setState({ activeTab: tabPanes.record.key, isLoadingItens: true }, () => {
          if (!isLoadingItens) {
            ContentoresEmbalagensService.getAllItens(
              record.idt,
              itensCaixas => {
                this.setState({
                  itensCaixas: produtos.map(item => ({ ...item, ...itensCaixas.find(itemCaixa => itemCaixa.produto === item.produto) })),
                  isLoadingItens: false,
                });
              },
              () => this.setState({ itensCaixas: [], isLoadingItens: false })
            );
          }
        });
      }
    });
  };

  handleEdit = action => {
    const { produtos } = this.state;
    this.setState({ action, activeTab: tabPanes.record.key }, () => this.editInput.focus());

    if (action === actions.insert) {
      this.saveApi.reset();
      this.setState({ itensCaixas: produtos, selectedItem: {} });
    }
  };

  onEdit = (cancel = false) => {
    let { selectedItem, activeTab } = this.state;

    if (cancel) {
      this.handleSelect(selectedItem);

      if (!selectedItem.codigo) {
        activeTab = tabPanes.table.key;
      }
    } else {
      this.searchApi.submitForm();

      const { action } = this.state;

      if (action === actions.insert) {
        activeTab = tabPanes.table.key;
        selectedItem = {};
      }
    }

    this.setState({ action: actions.idle, selectedItem, activeTab });
  };

  handleSave = () => {
    const values = this.saveApi.getValues();
    const { action, itensCaixas } = this.state;
    let record = {};
    let saveHandler = ContentoresEmbalagensService.inserir;

    if (action === actions.update) {
      const { selectedItem } = this.state;
      record = selectedItem;
      saveHandler = ContentoresEmbalagensService.alterar;
    }

    this.setState({ isLoading: true });
    saveHandler(
      {
        ...record,
        ...values,
        cliente: UserService.getUser().codigo,
        filial: UserService.getUser().filial,
        usuario: UserService.getUser().nome,
        itens: itensCaixas.filter(item => item.entrada || item.saida || item.estoque),
      },
      this.onSave,
      () => this.onSave('', true)
    );
  };

  onSave = (codigo, error = false) => {
    this.setState({ isLoading: false, activeTab: error ? tabPanes.record.key : tabPanes.table.key });

    if (!error) {
      Modal.info({
        content: `Registro ${codigo} salvo com sucesso.`,
        title: 'Caixas Plásticas',
        centered: true,
      });

      this.onEdit();
    }
  };

  handleDelete = record => {
    this.setState({ isLoading: true });
    ContentoresEmbalagensService.excluir(
      record.codigo,
      () => this.onDelete(),
      () => this.onDelete(true)
    );
  };

  onDelete = (error = false) => {
    this.setState({ isLoading: false });

    if (!error) {
      const { name } = this.props;

      this.searchApi.submitForm();
      notifySuccess({ name, action: actions.remove });
    }
  };

  onEditItem = record => {
    const { itensCaixas } = this.state;
    this.setState({ itensCaixas: itensCaixas.map(item => (item.produto === record.produto ? record : item)) });
  };

  imprimir = () => {
    const valuesForm = this.searchApi.getValues();
    const values = [];

    values.push({ nome_campo: 'cliente', valor: UserService.getUser().codigo });
    values.push({ nome_campo: 'filial', valor: UserService.getUser().filial });
    values.push({ nome_campo: 'codigo', valor: valuesForm.codigo });
    values.push({ nome_campo: 'emissao_inicio', valor: valuesForm.inicio });
    values.push({ nome_campo: 'emissao_fim', valor: valuesForm.fim });
    values.push({ nome_campo: 'placa', valor: valuesForm.placa });
    values.push({ nome_campo: 'motorista', valor: valuesForm.nome_motorista });
    values.push({ nome_campo: 'usuario', valor: valuesForm.nome_usuario });
    values.push({ nome_campo: 'exporta_excel', valor: 'N' });
    values.push({ nome_campo: 'exporta_pdf', valor: 'S' });

    WebRelatorioService.getChamaRelatorio(values, 'ServletCaixasPlasticas', 'Caixa Plástica - Portal');
  };

  render = () => {
    const { menuId, tabId } = this.props;
    const { isLoading, itens, selectedItem, action, activeTab, itensCaixas, produtos, isLoadingItens } = this.state;
    const isEdit = action !== actions.idle && !isLoading;
    const isIdle = action === actions.idle && !isLoading;

    return (
      <>
        <Page
          tabbed
          tabId={tabId}
          closable={isIdle}
          helpContent={<Markdown source={README} />}
          activeKey={activeTab}
          onChange={activeKey => this.setState({ activeTab: activeKey })}
          footer={selectedItem.codigo ? <Status record={selectedItem} /> : null}
        >
          <Page.TabPane key={tabPanes.table.key} tab={tabPanes.table.tab} disabled={action !== actions.idle}>
            <Form getApi={api => (this.searchApi = api)} onSubmit={this.handleSearch}>
              <ContentWrapper type="search">
                <Row gutter={4}>
                  <Col md={3}>
                    <Input field="codigo" label="Código" type="integer" disabled={isLoading} forwardedRef={ref => (this.searchInput = ref)} />
                  </Col>
                  <Col md={3}>
                    <DatePicker field="inicio" label="Período" disabled={isLoading} required today />
                  </Col>
                  <Col md={3}>
                    <DatePicker field="fim" label="Período" disabled={isLoading} required today />
                  </Col>
                  <Col md={3}>
                    <Input field="placa" label="Placa" disabled={isLoading} type="placa" />
                  </Col>
                  <Col md={5}>
                    <Input field="nome_motorista" label="Motorista" disabled={isLoading} />
                  </Col>
                  <Col md={4}>
                    <Input field="nome_usuario" label="Usuário" disabled={isLoading} />
                  </Col>
                  <Col md={3}>
                    <Button.Search disabled={isLoading} />
                  </Col>
                </Row>
              </ContentWrapper>
            </Form>
            <Table rowKey="idt" loading={isLoading} record={selectedItem} onRecordChange={this.handleSelect} dataSource={itens}>
              <Table.Column width="10%" title="Código" type="integer" dataIndex="idt" onMobile="show" />
              <Table.Column width="10%" title="Data" dataIndex="data" type="date" onMobile="show" />
              <Table.Column width="10%" title="Placa" align="center" dataIndex="placa" />
              <Table.Column width="35%" title="Nome Motorista" dataIndex="nome_motorista" />
              <Table.Column width="30%" title="Nome Usuário" dataIndex="nome_usuario" />
              <Table.Column
                align="center"
                dataIndex="actions"
                width="5%"
                title="Ações"
                exporter={false}
                sorter={false}
                render={(text, record) => (
                  <IconDelete
                    validate={{ value: record.codigo, name: `código: ${record.codigo}`, prefix: 'o' }}
                    prefix=" o registro"
                    description={record.codigo}
                    onClick={() => this.handleDelete(record)}
                  />
                )}
              />
            </Table>
          </Page.TabPane>
          <Page.TabPane key={tabPanes.record.key} tab={tabPanes.record.tab}>
            <Form getApi={api => (this.saveApi = api)} onSubmit={this.handleSave}>
              <ContentWrapper type="header" color={action.color}>
                <Row gutter={4}>
                  <Col md={3}>
                    <Input field="idt" label="Código" type="integer" disabled />
                  </Col>
                  <Col md={3}>
                    <DatePicker field="data" label="Data" disabled={!isEdit} today forwardedRef={ref => (this.editInput = ref)} required />
                  </Col>
                  <Col md={3}>
                    <Input field="placa" label="Placa" type="placa" disabled={!isEdit} required />
                  </Col>
                  <Col md={4}>
                    <InputNumber field="motorista" label="Código Motorista" type="integer" required disabled={!isEdit} />
                  </Col>
                  <Col md={6}>
                    <Input field="nome_motorista" label="Nome Motorista" disabled={!isEdit} required />
                  </Col>
                  <Col md={5}>
                    <Input field="nome_usuario" label="Nome Usuário" disabled={!isEdit} required />
                  </Col>
                </Row>
              </ContentWrapper>
            </Form>
            <Divider>Caixas</Divider>
            <Form getApi={api => (this.tableProdutoApi = api)}>
              <Table dataSource={itensCaixas} rowKey="produto" loading={isLoadingItens}>
                <Table.Column width="10%" title="Caixa" dataIndex="produto" type="integer" />
                <Table.Column width="50%" title="Descrição" dataIndex="nome" onMobile="show" />
                <Table.Column
                  width="10%"
                  title="Entrada"
                  dataIndex="entrada"
                  type="decimal"
                  disabled={!isEdit}
                  editable
                  functionAction={this.onEditItem}
                  onMobile="show"
                />
                <Table.Column
                  width="10%"
                  title="Saída"
                  dataIndex="saida"
                  type="decimal"
                  disabled={!isEdit}
                  editable
                  functionAction={this.onEditItem}
                  onMobile="show"
                />
                <Table.Column
                  width="10%"
                  title="Estoque"
                  dataIndex="estoque"
                  type="decimal"
                  disabled={!isEdit}
                  editable
                  functionAction={this.onEditItem}
                  onMobile="show"
                />
              </Table>
            </Form>
          </Page.TabPane>
        </Page>
        <FooterToolbar
          left={
            <>
              <Button.Insert perfilAcess menuId={menuId} disabled={!isIdle} onClick={() => this.handleEdit(actions.insert)} />
              <Button.Update
                perfilAcess
                menuId={menuId}
                disabled={!selectedItem.idt || !isIdle || produtos.length === 0 || itensCaixas.length === 0}
                onClick={() => this.handleEdit(actions.update)}
              />
            </>
          }
          center={
            <>
              <Button.Print onPrint={this.imprimir} disabled={itens.length === 0} />
              <Navigator
                disabled={!isIdle}
                record={selectedItem}
                dataSource={itens}
                dataIndex="idt"
                onClick={record => this.setState({ selectedItem: record })}
              />
            </>
          }
          right={
            <>
              <Button.Cancel disabled={isIdle || isLoading} onClick={() => this.onEdit(true)} />
              <Button.Save disabled={isIdle || isLoading} loading={action !== actions.idle && isLoading} onClick={() => this.saveApi.submitForm()} />
            </>
          }
        />
      </>
    );
  };
}

const mapStateToProps = ({ produtosGrupos, produtosGrupoSeparacao }) => ({ produtosGrupos, produtosGrupoSeparacao });
export default connect(mapStateToProps)(ContentoresEmbalagensPage);
