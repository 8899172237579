import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import './GridContent.less';

const GridContent = ({ children, settings }) => (
  <div
    className={classNames('grid-content-main', {
      'grid-content-wide': settings.contentWidth === 'Fixed',
    })}
  >
    {children}
  </div>
);

const mapStateToProps = ({ settings }) => ({ settings });

export default connect(mapStateToProps)(GridContent);
