import { integerValue, isRepeated, isMinimum } from '~/utils';

const isCpf = value => {
    const newValue = integerValue(value);
    const errorMessage = 'CPF inválido!';

  // Elimina valores vazios, com quantidade de caracteres menor que 11 ou com todos os caracteres iguais
  if (!newValue || isMinimum(newValue, 11) || isRepeated(newValue)) {
    return newValue && errorMessage;
  }

function validaCpf(strCPF) {
    let Soma;
    let Resto;
    Soma = 0;
  if (strCPF === "00000000000") {
      return true;
  }
     
  for (let i=1; i<=9; i+=1) {
    Soma += parseInt(strCPF.substring(i-1, i),10) * (11 - i);
    }

  Resto = (Soma * 10) % 11;
   
    if ((Resto === 10) || (Resto === 11))  {
        Resto = 0;
    }
    if (Resto !== parseInt(strCPF.substring(9, 10),10) ) {
        return errorMessage;
    }
   
  Soma = 0;
    for (let i = 1; i <= 10; i+=1) {
        Soma +=parseInt(strCPF.substring(i-1, i),10) * (12 - i);
    }
    Resto = (Soma * 10) % 11;
   
    if ((Resto === 10) || (Resto === 11))  {
        Resto = 0;
    }
    if (Resto !== parseInt(strCPF.substring(10, 11),10) ) {
        return errorMessage;
    }
    return false;
}

return validaCpf(value)
}

export default isCpf;
