import { estados, cidades, paises } from './cadastros';
import { menus, locales, colors, tabPanes, actions, affirmatives, rowColors, weekDays, uploadPaths } from './general';

export {
  // cadastros
  estados,
  cidades,
  paises,
  // general
  menus,
  locales,
  colors,
  tabPanes,
  actions,
  affirmatives,
  rowColors,
  weekDays,
  uploadPaths,
};
