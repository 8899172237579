import { takeEvery, all, fork, put, call } from 'redux-saga/effects';
import { UserService } from '~/services';
import { Creators, Types } from '~/stores/ducks/cadastros/produtos/produtosGrupoSeparacao';

function* fetchProdutosGrupoSeparacao() {
  try {
    const response = yield call(
      () =>
        new Promise((resolve, reject) => {
          UserService.fetchGrupoSeparacao(resolve, reject);
        })
    );

    yield put(Creators.fetchProdutosGrupoSeparacaoSuccess(response));
  } catch (error) {
    yield put(Creators.fetchProdutosGrupoSeparacaoFailure(error));
  }
}

const watchProdutosGrupoSeparacao = () =>
  function* watch() {
    yield takeEvery(Types.FETCH_PRODUTOS_GRUPO_SEPARACAO, fetchProdutosGrupoSeparacao);
  };

export default function* produtosGrupoSeparacao() {
  yield all([fork(watchProdutosGrupoSeparacao())]);
}
