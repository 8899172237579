import React from 'react';
import { Row, Col } from 'antd';
import { Page, Form, ContentWrapper, Button, SelectCombo } from '~/components';
import { actions } from '~/options';

class PedidoVendaMapaGrupoSeparacaoView extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      grupoSeparacao: [],
    };
  }

  componentDidUpdate(prevProps) {
    const { action, record } = this.props;
    const actionChange = action !== prevProps.action;

    if (actionChange && action) {
      if (this.saveApi) {
        this.forceUpdate(() => {
          this.saveApi.reset();
          this.editInput.focus();
          this.saveApi.setValues(record);
        });
      }
    }
  }

  /* 
    init = () =>    {
    grupo_separacao.focus;
    this.addEventListener(KeyboardEvent.KEY_DOWN, proFechar);
  }
  
  */
  render = () => {
    const { action, onCancel } = this.props;
    const { isLoading, grupoSeparacao } = this.state;
    const isEdit = action !== actions.idle && !isLoading;

    return (
      <>
        <Page.Modal
          visible={action}
          title=""
          width={720}
          onCancel={onCancel}
          forceRender
          centered
          closable
          destroyOnClose
          footer={
            <div style={{ textAlign: 'right' }}>
              <Button key="back" onClick={onCancel}>
                Fechar
              </Button>
              <Button key="submit" type="primary" onClick={() => this.saveApi.submitForm()}>
                Salvar
              </Button>
            </div>
          }
        >
          <Form getApi={api => (this.saveApi = api)} onSubmit={this.handleSave}>
            <ContentWrapper type="header" color={actions.insert.color}>
              <Row gutter={4}>
                <Col md={8}>
                  <SelectCombo
                    field="grupo_separacao"
                    label="Grupo de Separação"
                    dataSource={grupoSeparacao}
                    forwardedRef={ref => (this.editInput = ref)}
                    disabled={!isEdit}
                  />
                </Col>
              </Row>
            </ContentWrapper>
          </Form>
        </Page.Modal>
      </>
    );
  };
}

export default PedidoVendaMapaGrupoSeparacaoView;
