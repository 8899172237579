import moment from 'moment';

const date = {
  id: 'date',
  formater: value => {
    let result;
    if (typeof value === 'string' && !moment(value).isValid()) {
      if (value.indexOf('/') > -1)
        result = moment(
          value
            .split('/')
            .reverse()
            .join('')
        );
    } else {
      result = moment(value);
    }
    return result;
  },
};

const time = {
  id: 'time',
  formater: value => {
    const values = value.split(':');

    return moment().set({ hour: values[0], minute: values[1], second: values[2] });
  },
};

const hour = {
  id: 'hour',
  formater: value => {
    const values = value.split(':');

    return moment().set({ hour: values[0], minute: values[1] });
  },
};

export default { date, time, hour };
