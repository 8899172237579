import React from 'react';
import classNames from 'classnames';
import { useField } from 'informed';
import { Checkbox as AntdCheckbox } from 'antd';
import { Form } from '~/components/forms';
import { booleanValue } from '~/utils/formaters';
import './Checkbox.less';

const Checkbox = React.memo(props => {
  const { fieldState, fieldApi, render, userProps } = useField(props);
  const { onChange, onBlur, label, className, initialValue, forwardedRef, block, ...rest } = userProps;
  const showValue = booleanValue(fieldState.value || initialValue);

  return render(
    <Form.Item error={fieldState.error} labelHorizontal>
      <AntdCheckbox
        className={classNames(block ? 'ant-checkbox-wrapper-block' : 'ant-checkbox-wrapper', className)}
        value={showValue}
        checked={showValue === 'S' || showValue === true}
        ref={forwardedRef}
        onChange={event => {
          fieldApi.setValue(typeof showValue === 'boolean' || showValue === undefined ? event.target.checked : event.target.checked ? 'S' : 'N');

          if (onChange) {
            onChange(event);
          }
        }}
        onBlur={event => {
          fieldApi.setTouched();

          if (onBlur) {
            onBlur(event);
          }
        }}
        {...rest}
      >
        {label}
      </AntdCheckbox>
    </Form.Item>
  );
});

export default Checkbox;
