import React from 'react';
import { CheckCircleTwoTone, ClockCircleTwoTone, StopTwoTone, ExclamationCircleTwoTone } from '@ant-design/icons';

import './IconOperacaoDestinada.less';
import { Tooltip } from 'antd';

const porpsOperacao = {
  A: (
    <Tooltip title="Nota sem reconhecimento">
      <ClockCircleTwoTone className="icon" twoToneColor="#0000ff" />
    </Tooltip>
  ),
  J: (
    <Tooltip title="Conhecida">
      <CheckCircleTwoTone className="icon" twoToneColor="#33cc33" />
    </Tooltip>
  ),
  E: (
    <Tooltip title="Cancelada">
      <StopTwoTone className="icon" twoToneColor="#cc0000" />
    </Tooltip>
  ),
  M: (
    <Tooltip title="Nota sem reconhecimento">
      <CheckCircleTwoTone className="icon" twoToneColor="#000000" />
    </Tooltip>
  ),
  X: (
    <Tooltip title="Erro ao altera dados da nota">
      <ExclamationCircleTwoTone className="icon" twoToneColor="#ff0000" />
    </Tooltip>
  ),
};

const IconOperacaoNota = React.memo(({ record }) => <>{porpsOperacao[record.operacao === '1' ? 'A' : record.operacao || 'A'] || record.operacao}</>);

export default IconOperacaoNota;
