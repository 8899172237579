const show = {
  id: 'show',
};

const hide = {
  id: 'hide',
};

const expanded = {
  id: 'expanded',
};

export default { show, hide, expanded };
