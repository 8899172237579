import { DownloadOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import React from 'react';
import { Page, Button, Form, ContentWrapper, Input, Select } from '~/components';

class ExportPDFView extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidUpdate(prevProps) {
    const { action } = this.props;
    const actionChange = action !== prevProps.action;

    if (actionChange && action) {
      setTimeout(() => {
        this.input.focus();
      }, 300);
    }
  }

  handleSave = values => {
    const { onOk, onCancel } = this.props;
    onOk(values);
    onCancel();
  };

  render = () => {
    const { onCancel, action, columns } = this.props;

    return (
      <>
        <Page.Modal
          visible={action}
          title="Titulo Relatório"
          width={400}
          onCancel={onCancel}
          forceRender
          centered
          keyboard={false}
          destroyOnClose
          footer={
            <div style={{ textAlign: 'right' }}>
              <Button key="back" onClick={onCancel}>
                Fechar
              </Button>
              <Button key="submit" type="primary" icon={<DownloadOutlined />} onClick={() => this.saveApi.submitForm()}>
                Download
              </Button>
            </div>
          }
        >
          <Form getApi={api => (this.saveApi = api)} onSubmit={this.handleSave}>
            <ContentWrapper type="search">
              <Row gutter={4}>
                <Col md={24}>
                  <Input field="titulo" label="Nome do Relatório" required capsLock={false} forwardedRef={ref => (this.input = ref)} />
                </Col>
                <Col md={12}>
                  <Select
                    field="size"
                    label="Tamanho"
                    required
                    initialValue="A4"
                    dataSource={[
                      { label: 'A1', data: 'A1' },
                      { label: 'A2', data: 'A2' },
                      { label: 'A3', data: 'A3' },
                      { label: 'A4', data: 'A4' },
                    ]}
                  />
                </Col>
                <Col md={12}>
                  <Select
                    field="orientacao"
                    label="Orientação"
                    required
                    initialValue="portrait"
                    dataSource={[
                      { label: 'RETRATO', data: 'portrait' },
                      { label: 'PAISAGEM', data: 'landscape' },
                    ]}
                  />
                </Col>
                <Col md={24}>
                  <Select field="agrupamento" label="Agrupamento" dataSource={columns.map(item => ({ label: item.title, data: item.dataIndex }))} />
                </Col>
              </Row>
            </ContentWrapper>
          </Form>
        </Page.Modal>
      </>
    );
  };
}

export default ExportPDFView;
