import { createActions, createReducer } from 'reduxsauce';

// types
const { Types, Creators } = createActions({
  addNotification: ['notification'],
  toggleNotification: ['id'],
  removeNotification: ['id'],
  clearNotifications: ['key'],
  resetNotifications: [],
});

const INITIAL_STATE = {
  loading: false,
  data: [],
};

// reducers
const add = (state = INITIAL_STATE, { notification }) => ({
  ...state,
  data: [
    {
      ...notification,
      type: notification.type || 'notification',
      read: notification.read || false,
      id: new Date().toISOString(),
      datetime: new Date(),
    },
    ...state.data,
  ],
});

const toggle = (state = INITIAL_STATE, { id }) => ({
  ...state,
  data: state.data.map(notification =>
    notification.id === id ? { ...notification, read: !notification.read } : notification
  ),
});

const remove = (state = INITIAL_STATE, { id }) => ({
  ...state,
  data: state.data.filter(notification => notification.id !== id),
});

const clear = (state = INITIAL_STATE, { key }) => ({
  ...state,
  data: state.data.filter(notification => notification.type !== key),
});

const reset = () => INITIAL_STATE;

// Hookup reducers to types
const Reducers = createReducer(INITIAL_STATE, {
  [Types.ADD_NOTIFICATION]: add,
  [Types.TOGGLE_NOTIFICATION]: toggle,
  [Types.REMOVE_NOTIFICATION]: remove,
  [Types.CLEAR_NOTIFICATIONS]: clear,
  [Types.RESET_NOTIFICATIONS]: reset,
});

export { Reducers, Types, Creators };
export default Reducers;
