import React from 'react';
import { Layout } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classNames from 'classnames';
import { Loading } from '~/components/general';
import { Creators } from '~/stores/ducks/general/general';
import { Logo } from '../../..';
import './Sider.less';

const BaseMenu = React.lazy(() => import('../../../BaseMenu'));

class Sider extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      firstMount: true,
    };
  }

  componentDidMount() {
    this.setState({ firstMount: false });
  }

  render() {
    const { firstMount } = this.state;
    const { general, settings, setGeneral } = this.props;

    return (
      <Layout.Sider
        trigger={null}
        collapsible
        breakpoint="lg"
        width={256}
        collapsed={general.isMobile ? false : general.isCollapsed}
        theme={settings.theme}
        className={classNames('sider', {
          'sider-fix-sider-bar': settings.fixSiderbar,
          'sider-light': settings.theme === 'light',
        })}
        onCollapse={() => {
          if (firstMount || !general.isMobile) {
            setGeneral({ ...general, isCollapsed: false });
          }
        }}
      >
        <div className="sider-logo">
          <Logo />
        </div>
        <React.Suspense fallback={<Loading style={{ paddingTop: '32px' }} size="default" />}>
          <BaseMenu mode="inline" style={{ padding: '16px 0', width: '100%' }} />
        </React.Suspense>
      </Layout.Sider>
    );
  }
}

const mapStateToProps = ({ general, settings }) => ({ general, settings });
const mapDispatchToProps = dispatch => bindActionCreators(Creators, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Sider);
