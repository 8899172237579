import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { UserService } from '~/services';

const PrivateRoute = React.memo(({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      UserService.isLogado() ? (
        <Component {...props} />
      ) : 
        <Redirect to={{ pathname: `/${process.env.REACT_APP_VERSION}/login`, state: { from: props.location } }} />}
  />
));

export default PrivateRoute;
