import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'informed';
import { InputNumber as AntdInputNumber, Tooltip } from 'antd';
import classNames from 'classnames';
import { Form } from '~/components/forms';
import { isRequired, getLocale } from '~/utils';
import { types } from './options';
import './InputNumber.less';

const InputNumber = React.memo(({ required, validate, validateOnChange, spinner, ...props }) => {
  const { fieldState, fieldApi, render, userProps } = useField({
    validate: validate || (required && isRequired),
    validateOnChange: validateOnChange || required || validate,
    ...props,
  });
  const {
    onChange,
    onBlur,
    label,
    initialValue,
    forwardedRef,
    className,
    block,
    type = types.number.id,
    formItemProps,
    disabledReason,
    tooltipProps,
    ...rest
  } = userProps;

  return render(
    <Form.Item label={label} error={fieldState.error} required={required} {...formItemProps}>
      <Tooltip title={rest.disabled && disabledReason} placement="bottom" {...tooltipProps}>
        <AntdInputNumber
          ref={forwardedRef}
          value={fieldState.value || initialValue}
          precision={types[type].precision}
          decimalSeparator={types[type].id !== types.currency.id ? getLocale().formats.decimalSeparator : undefined} // REVIEW não funciona para tipo currency
          formatter={types[type].formatter}
          parser={types[type].parser}
          className={classNames('input-number', { block: true }, className, types[type].className, !spinner ? 'input-number-nospinner' : null)}
          onChange={changeValue => {
            if (types[type].restrict && !types[type].restrict(changeValue)) {
              return;
            }

            fieldApi.setValue(changeValue);

            if (onChange) {
              onChange(changeValue);
            }
          }}
          onBlur={event => {
            fieldApi.setTouched();

            if (onBlur) {
              onBlur(event);
            }
          }}
          {...rest}
        />
      </Tooltip>
    </Form.Item>
  );
});

InputNumber.defaultProps = {
  type: 'integer',
};

InputNumber.propTypes = {
  type: PropTypes.oneOf(['integer', 'number', 'currency', 'decimal', 'percentage']),
};

export default InputNumber;
