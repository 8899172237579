import React from 'react';
import { Button } from 'antd';
import { Page, Table } from '~/components';

class SaldoContentoresView extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { action, itens, onCancel } = this.props;

    return (
      <>
        <Page.Modal
          visible={action}
          title={`Contentores`}
          forceRender
          centered
          closable
          bodyStyle={{ maxHeight: '400px', overflow: 'auto' }}
          destroyOnClose
          footer={
            <div style={{ textAlign: 'center' }}>
              <Button key="submit" type="primary" onClick={() => onCancel()}>
                Fechar
              </Button>
            </div>
          }
        >
          <Table
            size="small"
            pagination={{ hideOnSinglePage: true, pageSize: itens.length }}
            footer={null}
            dataSource={itens}
            rowKey="nome"
            className="table-quadro"
          >
            <Table.Column title="Descrição" dataIndex="nome" onMobile="show" width="70%" />
            <Table.Column title="Saldo" dataIndex="saldo" onMobile="show" width="30%" type="decimal" />
          </Table>
        </Page.Modal>
      </>
    );
  }
}

export default SaldoContentoresView;
