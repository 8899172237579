import { store } from '~/stores';
import { actions } from '~/stores/ducks';

const RememberService = {
  setRemember: remember => store.dispatch(actions.setRemember(remember)),
  changeRemember: remember => store.dispatch(actions.changeRemember(remember)),
  getRemember: () => store.getState().remember,
  resetRemember: () => store.dispatch(actions.resetRemember()),
  setRememberFied: (menuId, values) =>
    RememberService.setRemember({ ...RememberService.getRemember(), [menuId]: { ...RememberService.getRemember()[menuId], ...values } }),
  getRememberFied: (menuId, field) => RememberService.getRemember()[menuId] && RememberService.getRemember()[menuId][field],
};

export default RememberService;
