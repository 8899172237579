import wordCounter from './wordCounter';
import firstLast from './firstLast';
import decimalPlace from './decimalPlace';
import booleanValue from './booleanValue';
import momentValue from './momentValue';
import backValue from './backValue';
import postalValue from './postalValue';
import integerValue from './integerValue';
import dateValue from './dateValue';
import affirmativeValue from './affirmativeValue';
import decimalValue from './decimalValue';
import ncmValue from './ncmValue';
import cestValue from './cestValue';
import cnpjValue from './cnpjValue';
import phoneValue from './phoneValue';
import cpfCnpjValue from './cpfCnpjValue';
import removeAccents from './removeAccents';
import faturaValue from './faturaValue';
import placaValue from './placaValue';

export {
  wordCounter,
  firstLast,
  decimalPlace,
  booleanValue,
  momentValue,
  backValue,
  postalValue,
  integerValue,
  dateValue,
  affirmativeValue,
  decimalValue,
  ncmValue,
  cestValue,
  cnpjValue,
  phoneValue,
  cpfCnpjValue,
  removeAccents,
  faturaValue,
  placaValue,
};
