import React from 'react';
import { Page, Button, Table } from '~/components';
import { PedidoService, UserService } from '~/services';

class PedidoVendaMapaDetalheItensView extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      itens: [],
    };
  }

  componentDidUpdate(prevProps) {
    const { record } = this.props;
    const actionChange = record.codigo !== prevProps.record.codigo;

    if (actionChange && record.codigo) {
      PedidoService.getAllItens(
        record.codigo,
        itens => this.setState({ itens, isLoading: false }),
        () => this.setState({ itens: [], isLoading: false })
      );
    }
  }

  render = () => {
    const { onCancel, record } = this.props;
    const { isLoading, itens } = this.state;

    const MOSTRA_CUSTO = UserService.getUser().mostra_custos_site === '1';

    return (
      <>
        <Page.Modal
          visible={record.codigo}
          title={`Pedidos: ${record.codigo}`}
          width={1000}
          onCancel={onCancel}
          forceRender
          centered
          closable
          destroyOnClose
          footer={
            <div style={{ textAlign: 'center' }}>
              <Button key="back" onClick={onCancel}>
                Fechar
              </Button>
            </div>
          }
        >
          <Table rowKey="idt_tabela" dataSource={itens} loading={isLoading}>
            <Table.Column width="10%" title="Código" align="right" dataIndex="codigo" />
            <Table.Column width="10%" title="Cliente" align="right" dataIndex="codigo_cliente" />
            <Table.Column width="55%" title="Descrição" align="left" dataIndex="descricao_cliente" />
            <Table.Column width="5%" title="UN" align="center" dataIndex="unidade" />
            <Table.Column width="10%" title="Qtde" align="right" dataIndex="quantidade" type="decimal" />
            <Table.Column width="10%" title="R$" align="right" dataIndex="preco_venda" type="currency" hide={!MOSTRA_CUSTO} />
          </Table>
        </Page.Modal>
      </>
    );
  };
}

export default PedidoVendaMapaDetalheItensView;
