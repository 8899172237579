import React from 'react';
import { connect } from 'react-redux';
import { Drawer } from 'antd';
import { bindActionCreators } from 'redux';
import { Creators } from '~/stores/ducks/general/general';
import { Sider } from './components';
import './SliderMenu.less';

const SiderMenu = React.memo(({ general, setGeneral }) =>
  general.isMobile ? (
    <Drawer
      placement="left"
      visible={!general.isCollapsed}
      style={{ padding: 0, height: '100vh' }}
      onClose={() => setGeneral({ ...general, isCollapsed: true })}
    >
      <Sider />
    </Drawer>
  ) : (
    <Sider />
  )
);

const mapStateToProps = ({ general }) => ({ general });
const mapDispatchToProps = dispatch => bindActionCreators(Creators, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SiderMenu);
