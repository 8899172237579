import React from 'react';
import { Layout, Modal } from 'antd';
import { connect } from 'react-redux';
import { Footer } from '~/layouts/components';
import { Header, SiderMenu } from './components';
import './BasicLayout.less';
import { CustomerService, UserService } from '~/services';
import SaldoContentoresView from './SaldoContentoresView';

// eslint-disable-next-line import/no-unresolved
const SettingsDrawer = React.lazy(() => import('~/layouts/components/SettingsDrawer'));
// eslint-disable-next-line import/no-unresolved
const BackTop = React.lazy(() => import('~/layouts/components/BackTop'));

let confirmar = false;

class BasicLayout extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      showEstoqueTerceiros: false,
      itensEstoquesTerceiro: [],
    };
  }

  componentDidMount() {
    this.interval = setInterval(this.verificarVersao, 1000 * 60 * 5);

    UserService.fetchContentores(UserService.getUser().codigo, UserService.getUser().filial, itensEstoquesTerceiro => {
      this.setState({ itensEstoquesTerceiro, showEstoqueTerceiros: itensEstoquesTerceiro.length > 0 });
    });
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  verificarVersao = () => {
    if (!confirmar && document.URL.indexOf('localhost') === -1) {
      UserService.verifica_versao(response => {
        if (response.versao === 'N') {
          confirmar = true;
          Modal.confirm({
            content: 'Foi encontrada uma nova versão do sistema, Deseja Atualizar?',
            title: 'MySoft - Mysaas',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk: () => window.location.replace(`${process.env.REACT_APP_URL}`),
            onCancel: () => (confirmar = false),
          });
        }
      });
    }
  };

  getLayoutStyle = () => {
    const { general, settings } = this.props;

    if (settings.fixSiderbar && settings.layout !== 'topmenu' && !general.isMobile) {
      return { paddingLeft: general.isCollapsed ? '80px' : '256px' };
    }

    return null;
  };

  render() {
    const { children, general, settings } = this.props;
    const { showEstoqueTerceiros, itensEstoquesTerceiro } = this.state;

    const customer = CustomerService.getCustomer() || {};

    const estilos = {
      backgroundColor: customer.cor_background || '#ebedf0',
    };

    return (
      <>
        <SaldoContentoresView action={showEstoqueTerceiros} itens={itensEstoquesTerceiro} onCancel={() => this.setState({ showEstoqueTerceiros: false })} />
        <Layout style={{ minHeight: '100vh' }}>
          {settings.layout === 'topmenu' && !general.isMobile ? null : <SiderMenu />}
          <Layout style={{ minHeight: '100vh', ...this.getLayoutStyle() }}>
            <Header />
            <Layout.Content className="basic-layout-content" style={{ ...estilos, ...(!settings.fixedHeader ? { paddingTop: 0 } : undefined) }}>
              {children}
            </Layout.Content>
            <Footer />
          </Layout>
        </Layout>
        <React.Suspense fallback={null}>
          <BackTop style={{ bottom: '80px' }} />
        </React.Suspense>
        <React.Suspense fallback={null}>
          <SettingsDrawer />
        </React.Suspense>
      </>
    );
  }
}

const mapStateToProps = ({ general, settings }) => ({ general, settings });

export default connect(mapStateToProps)(BasicLayout);
