import { integerValue } from '~/utils';

const cpfCnpjValue = value => {
  let cpfCnpj = integerValue(value);

  if (cpfCnpj && cpfCnpj.length === 14) {
    cpfCnpj = cpfCnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/g, '$1.$2.$3/$4-$5');
  } else if (cpfCnpj && cpfCnpj.length === 11) {
    cpfCnpj = cpfCnpj.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/g, '$1.$2.$3-$4');
  }
  return cpfCnpj;
};

export default cpfCnpjValue;
