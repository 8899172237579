import React from 'react';
import classNames from 'classnames';
import { Form } from 'antd';
import './Item.less';

const Item = React.memo(({ error, style, className, labelHorizontal, ...rest }) => (
  <Form.Item
    validateStatus={error ? 'error' : undefined}
    help={error}
    style={{ marginBottom: '8px', ...style }}
    className={classNames(className, 'form-general-item', labelHorizontal ? 'ant-form-item-horizontal' : '')}
    {...rest}
  />
));

export default Item;
