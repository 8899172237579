import { integerValue } from '~/utils';
import {isCnpj, isCpf } from '.';

const isDocument = (value = "") => {
    const newValue = value === null ? "" : integerValue(value)
    const cpf = isCpf(newValue)
    const cnpj = isCnpj(newValue)

    return (newValue.length < 12 && cpf) || (newValue.length > 11 && cnpj)

}

export default isDocument;
