import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from '~/stores';
import { Router, ConfigProvider, Media, Theme } from './components';

const App = React.memo(() => (
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <ConfigProvider>
        <Theme />
        <Media />
        <Router />
      </ConfigProvider>
    </PersistGate>
  </Provider>
));

export default App;
