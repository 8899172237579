const getRowColor = (record, colors) => {
  for (let i = 0; i < colors.length; i += 1) {
    if (colors[i].predicate(record)) {
      return colors[i].color;
    }
  }

  return {};
};

export default getRowColor;
