import React from 'react';
import { Row, Col } from 'antd';
import { connect } from 'react-redux';
import { Page, Form, ContentWrapper, Button, SelectCombo } from '~/components';
import { actions } from '~/options';

class PedidoVendaMapaGrupoView extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      itens: [],
    };
  }

  componentDidUpdate(prevProps) {
    const { action, record } = this.props;
    const actionChange = action !== prevProps.action;

    if (actionChange && action) {
      if (this.saveApi) {
        this.forceUpdate(() => {
          this.saveApi.reset();
          this.editInput.focus();
          this.saveApi.setValues(record);
        });
      }
    }
  }

  render = () => {
    const { action, onCancel, produtosGrupos } = this.props;
    const { isLoading } = this.state;
    const isEdit = action !== actions.idle && !isLoading;

    return (
      <>
        <Page.Modal
          visible={action}
          title=""
          width={720}
          onCancel={onCancel}
          forceRender
          centered
          closable
          destroyOnClose
          footer={
            <div style={{ textAlign: 'right' }}>
              <Button key="back" onClick={onCancel}>
                Fechar
              </Button>
              <Button key="submit" type="primary" onClick={() => this.saveApi.submitForm()}>
                Salvar
              </Button>
            </div>
          }
        >
          <Form getApi={api => (this.saveApi = api)} onSubmit={this.handleSave}>
            <ContentWrapper type="header" color={actions.insert.color}>
              <Row gutter={4}>
                <Col md={8}>
                  <SelectCombo field="grupo" label="Grupo" dataSource={produtosGrupos.data} forwardedRef={ref => (this.editInput = ref)} disabled={!isEdit} />
                </Col>
              </Row>
            </ContentWrapper>
          </Form>
        </Page.Modal>
      </>
    );
  };
}

const mapStateToProps = ({ produtosGrupos }) => ({ produtosGrupos });
export default connect(mapStateToProps)(PedidoVendaMapaGrupoView);
