import { onlyInteger, onlyCurrency } from '~/utils';

const number = {
  id: 'number',
};

const integer = {
  id: 'integer',
  restrict: value => onlyInteger(value),
  className: 'text-center',
  precision: 0,
  formatter: value => {
    return parseInt(value, 10) ? value : '';
  },
};

const currency = {
  id: 'currency',
  restrict: value => onlyCurrency(value),
  className: 'text-right',
  precision: 2,
  formatter: value =>
    value
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, '*')
      .replace(/\./g, ',')
      .replace(/\*/g, '.'),
  parser: value => ((value || '').length > 4 ? value.replace('.', '').replace(',', '.') : value.replace(',', '.')),
};

const decimal = {
  id: 'decimal',
  restrict: value => onlyCurrency(value),
  className: 'text-right',
  precision: 3,
  formatter: value => {
    return value.toString().replace('.', ',');
  },
  parser: value => {
    return value.toString().replace(/,/g, '.');
  },
};

const percentage = {
  id: 'percentage',
  restrict: value => onlyCurrency(value),
  className: 'text-center',
  precision: 2,
  formatter: value => (value.toString().length > 0 ? `${value}` : ''),
  parser: value => value,
};

export default { integer, number, currency, decimal, percentage };
