const calculaDigitoMOD11 = bloco => {
  let npeso = 1;
  let soma = 0;

  for (let i = bloco.length; i > 0; i -= 1) {
    npeso = npeso === 1 ? 2 : 1;
    let nvalor = parseInt(bloco.substr(i - 1, 1), 10) * npeso;
    if (nvalor > 9) nvalor = parseInt(nvalor.toString().substr(0, 1), 10) + parseInt(nvalor.toString().substr(1, 1), 10);
    soma += nvalor;
  }
  const digito = 10 - (soma % 10);
  return digito === 10 ? '0' : digito.toString();
};

export default calculaDigitoMOD11;
