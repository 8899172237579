import React from 'react';
import { Upload as AntdUpload, message, Dropdown, Menu, Card, Avatar, Row, Col } from 'antd';
import { UpSquareOutlined, DeleteOutlined, LoadingOutlined, InboxOutlined } from '@ant-design/icons';
import { FileService } from '~/services';
import { uploadPaths } from '~/options';
import './UploadAnexo.less';
import store from '~/stores';

class UploadAnexo extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      fileList: [],
    };
  }

  componentDidMount() {
    const { record } = this.props;
    if (record) {
      this.handleSearch();
    }
  }

  componentDidUpdate(prevProps) {
    const { record, action } = this.props;
    const recordChange = record !== prevProps.record;
    const actionChange = action !== prevProps.action;

    if (recordChange || actionChange) {
      this.handleSearch();
    }
  }

  handleSearch = () => {
    const { table, record } = this.props;
    if (record) {
      this.setState({ isLoading: true });

      FileService.search({ tabela: uploadPaths[table].table, idt_registro: record, origem: store.getState().empresas.selected.idt }, this.onSearch, () =>
        this.onSearch()
      );
    }
  };

  onSearch = (fileList = []) => {
    this.setState({ isLoading: false, fileList });
  };

  onUploadAnexo = ({ file }) => {
    const { fileSize = 10, table, record } = this.props;
    const sizeLimit = uploadPaths[table].fileSize || fileSize;

    if (file.size > sizeLimit * 1024 * 1024) {
      return message.error(
        <span>
          Arquivo 
{' '}
<b>{file.name}</b>
          {' '}
          maior que o permitido.
</span>,
        5
      );
    }

    const formData = new FormData();
    formData.append('file', file);

    FileService.insertAttachment(file.name, table, formData, ({ data }) =>
      FileService.insert(
        {
          idt_registro: record,
          origem: store.getState().empresas.selected.idt,
          tabela: uploadPaths[table].table,
          nome_arquivo: file.name,
          url: `http://erp.mysaas.com.br/arquivo/${data.url}`,
        },
        (message.success(
          <span>
            Arquivo 
{' '}
<b>{file.name}</b>
            {' '}
            enviado com sucesso.
</span>,
          3
        ),
        this.handleSearch),
        () => {
          this.onSearch();
        }
      )
    );
  };

  handleDelete = file => {
    FileService.delete(
      file.idt,
      (message.success(
        <span>
          Arquivo 
{' '}
<b>{file.nome_arquivo}</b>
          {' '}
          removido com sucesso.
</span>,
        3
      ),
      this.handleSearch)
    );
  };

  openAnexo = ({ url }) => {
    window.open(url, '_blank');
  };

  fetchTitle = name => {
    const nameList = name.split('.');
    return nameList[nameList.length - 1];
  };

  renderUploads = () => {
    const { fileList } = this.state;

    return (
      <Row gutter={4}>
        {fileList.map(file => (
          <Col md={8} key={file.uid}>
            <Dropdown
              overlay={
                // eslint-disable-next-line react/jsx-wrap-multilines
                <Menu>
                  <Menu.Item onClick={() => this.handleDelete(file)}>
                    <DeleteOutlined style={{ color: 'red' }} />
                    Excluir
                  </Menu.Item>
                </Menu>
              }
              trigger={['contextMenu']}
            >
              <Card hoverable size="small" onClick={() => this.openAnexo(file)}>
                <Card.Meta
                  className="card-meta-upload-anexo"
                  description={file.nome_arquivo}
                  title={`.${this.fetchTitle(file.nome_arquivo)}`}
                  avatar={<Avatar icon={<UpSquareOutlined />} src={file.url} />}
                />
              </Card>
            </Dropdown>
          </Col>
        ))}
      </Row>
    );
  };

  render() {
    const { isLoading } = this.state;
    const { loading, disabled, record, fileSize = 10, table, ...rest } = this.props;
    const sizeLimit = uploadPaths[table].fileSize || fileSize;
    const hidden = !record;

    return (
      <div hidden={hidden}>
        <Row gutter={4}>
          <Col md={24}>
            <AntdUpload.Dragger
              name="name"
              multiple
              fileList={[]}
              showUploadList
              className="upload"
              disabled={disabled || isLoading || loading}
              customRequest={this.onUploadAnexo}
              {...rest}
            >
              <p className="ant-upload-hint">
                {isLoading || loading ? <LoadingOutlined style={{ marginRight: '8px' }} /> : <InboxOutlined style={{ marginRight: '8px' }} />}
                {`Clique ou arraste o arquivo para fazer upload. Tamanho máximo: ${sizeLimit}MB.`}
              </p>
            </AntdUpload.Dragger>
          </Col>
        </Row>
        {this.renderUploads()}
      </div>
    );
  }
}

export default UploadAnexo;
