import React from 'react';
import { Tooltip } from 'antd';
import './BlockCheckbox.less';
import { CheckOutlined } from '@ant-design/icons';

const BlockCheckbox = ({ value, onChange, list }) => (
  <div className="block-checkbox">
    {list.map(item => {
      const handler = () => onChange(item.key);

      return (
        <Tooltip title={item.title} key={item.key}>
          <div className="block-checkbox-item" onClick={handler} onKeyPress={handler} role="button">
            <img src={item.url} alt={item.key} />
            <div className="block-checkbox-select-icon" style={{ display: value === item.key ? 'block' : 'none' }}>
              <CheckOutlined />
            </div>
          </div>
        </Tooltip>
      );
    })}
  </div>
);

export default BlockCheckbox;
