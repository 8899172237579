import React from 'react';
import { Layout } from 'antd';
import { connect } from 'react-redux';
import Animate from 'rc-animate';
import { TopNavigator, GlobalHeader } from './components';
import './Header.less';
import { MenuService, UserService } from '~/services';

let pageDev = false;
let pageRecados = false;

class Header extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isVisible: true,
      itensRecados: [],
    };
  }

  static getDerivedStateFromProps({ settings }, { isVisible }) {
    if (!settings.autoHideHeader && !isVisible) {
      return { isVisible: true };
    }

    return null;
  }

  componentDidMount() {
    document.addEventListener('scroll', this.handleScroll, { passive: true });

    UserService.getAllRecados(
      UserService.getUser().codigo,
      UserService.getUser().filial,
      itensRecados => this.setState({ itensRecados }),
      () => {}
    );
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    const { settings } = this.props;

    if (!settings.autoHideHeader) {
      return;
    }

    const { isVisible, isTicking, oldScrollTop } = this.state;
    const scrollTop = document.body.scrollTop + document.documentElement.scrollTop;

    if (!isTicking) {
      this.setState({ isTicking: true });

      requestAnimationFrame(() => {
        if (oldScrollTop > scrollTop) {
          this.setState({ isVisible: true });
        } else if (scrollTop > 300 && isVisible) {
          this.setState({ isVisible: false });
        } else if (scrollTop < 300 && !isVisible) {
          this.setState({ isVisible: true });
        }

        this.setState({ isTicking: false, oldScrollTop: scrollTop });
      });
    }
  };

  getHeaderWidth = () => {
    const { settings, general } = this.props;

    if (general.isMobile || !settings.fixedHeader || settings.layout === 'topmenu') {
      return '100%';
    }

    return general.isCollapsed ? 'calc(100% - 80px)' : 'calc(100% - 256px)';
  };

  render() {
    const { settings, general } = this.props;
    const { isVisible, itensRecados } = this.state;

    setTimeout(() => {
      // OPEN PAGE - Recados
      const menuRecado = '7';
      if (!pageRecados && MenuService.getById(menuRecado) && itensRecados.length > 0) {
        pageRecados = true;
        MenuService.open(MenuService.getById(menuRecado), {});
      }

      // OPEN PAGE - Development
      const menuId = '2';
      if (!pageDev && MenuService.getById(menuId) && process.env.NODE_ENV === 'development') {
        pageDev = true;
        // MenuService.open(MenuService.getById(menuId), {});
      }
    }, 500);

    return (
      <Animate component="" transitionName="fade">
        {isVisible ? (
          <Layout.Header style={{ padding: 0, zIndex: 2, width: this.getHeaderWidth() }} className={settings.fixedHeader ? 'header-fixed-header' : ''}>
            {settings.layout === 'topmenu' && !general.isMobile ? <TopNavigator /> : <GlobalHeader />}
          </Layout.Header>
        ) : null}
      </Animate>
    );
  }
}

const mapStateToProps = ({ settings, general, menus }) => ({ settings, general, menus });

export default connect(mapStateToProps)(Header);
