import { createActions, createReducer } from 'reduxsauce';

// types
const { Types, Creators } = createActions({
  setGeneral: ['general'],
  changeGeneral: ['general'],
  resetGeneral: [],
});

const INITIAL_STATE = {
  isMobile: false,
  isCollapsed: true,
};

// reducers
const set = (state, { general }) => general;

const change = (state, { general }) => ({ ...state, ...general });

const reset = () => INITIAL_STATE;

// Hookup reducers to types
const Reducers = createReducer(INITIAL_STATE, {
  [Types.SET_GENERAL]: set,
  [Types.CHANGE_GENERAL]: change,
  [Types.RESET_GENERAL]: reset,
});

export { Reducers, Types, Creators };
export default Reducers;
