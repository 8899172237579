import React from 'react';
import classNames from 'classnames';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { HeaderDropdown } from '~/components';
import './HelpMenu.less';

class HelpMenu extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isVisible: false,
    };
  }

  onVisibleChange = visible => {
    this.setState({ isVisible: visible });
  };

  render() {
    const { className } = this.props;
    const { isVisible } = this.state;

    return (
      <HeaderDropdown placement="bottomRight" visible={isVisible} onVisibleChange={this.onVisibleChange}>
        <span className={classNames('help-menu-dropdown', className)}>
          <QuestionCircleOutlined title="Ajuda" />
        </span>
      </HeaderDropdown>
    );
  }
}

export default HelpMenu;
