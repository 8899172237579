import { notification, Modal } from 'antd';
import httpStatus from 'http-status-codes';

const errors = {
  [httpStatus.BAD_REQUEST]: 'O servidor rejeitou a solicitação!',
  [httpStatus.NOT_FOUND]: 'O endereço solicitado não existe!',
  [httpStatus.INTERNAL_SERVER_ERROR]: 'O servidor está reportando um erro!',
};

const notifyError = (error, showNotification = true) => {
  if (error.response) {
    const mensagem = error.response.str.replace('Uncaught Exception: ', '');
    let mensagemErro;
    if (mensagem.includes('Ocorreu um erro ao efetuar operação no banco de dados:') || (mensagem.includes('Erro: ') && mensagem.includes('QUERY'))) {
      mensagemErro = mensagem;
    } else {
      // eslint-disable-next-line prefer-destructuring
      if (mensagem.indexOf(': ') > -1 && mensagem.indexOf(' in ') > -1) mensagemErro = mensagem.split(' in ')[0];
      else mensagemErro = mensagem;
    }

    Modal.warning({
      title: 'Atenção',
      content: mensagemErro,
      centered: true,
      width: '720px',
    });
  } else if (showNotification) {
    notification.error({
      message: 'Erro de solicitação',
      description: errors[error.status] || errors[httpStatus.NOT_FOUND],
    });
  }
};

export { errors };
export default notifyError;
