import React from 'react';
import { Avatar, List } from 'antd';
import moment from 'moment';
import classNames from 'classnames';
import './NoticeList.less';

const NotFound = ({ emptyImage, emptyText }) => (
  <div className="notice-list-not-found">
    {emptyImage ? <img src={emptyImage} alt="not found" /> : null}
    <div>{emptyText}</div>
  </div>
);

const MetaDescription = ({ description, datetime }) => (
  <div>
    <div className="notice-list-description" title={description}>
      {description}
    </div>
    <div className="notice-list-datetime">{moment(datetime).fromNow()}</div>
  </div>
);

const MetaTitle = ({ title, extra }) => (
  <div className="notice-list-title">
    {title}
    <div className="notice-list-extra">{extra}</div>
  </div>
);

const MetaAvatar = ({ avatar }) => {
  if (!avatar) {
    return null;
  }

  return typeof avatar === 'string' ? (
    <Avatar src={avatar} className="notice-list-avatar" />
  ) : (
    <span className="notice-list-icon-element">{avatar}</span>
  );
};

const NoticeList = React.memo(
  ({
    onClick,
    onClear,
    data = [],
    emptyImage,
    emptyText = 'Não há notificações.',
    showClear = true,
    showViewMore = false,
    onViewMore = null,
    title,
  }) =>
    data.length === 0 ? (
      <NotFound emptyImage={emptyImage} emptyText={emptyText} />
    ) : (
      <>
        <List className="notice-list">
          {data.map((item, index) => (
            <List.Item
              className={classNames('notice-list-item', { 'notice-list-read': item.read })}
              key={item.id || index}
              onClick={() => onClick(item)}
            >
              <List.Item.Meta
                className="notice-list-meta"
                avatar={<MetaAvatar avatar={item.avatar} />}
                title={<MetaTitle title={item.title} extra={item.extra} />}
                description={
                  <MetaDescription description={item.description} datetime={item.datetime} />
                }
              />
            </List.Item>
          ))}
        </List>
        <div className="notice-list-bottom-bar">
          {showClear ? (
            <div onClick={onClear} onKeyPress={onClear} role="button">
              {`Limpar ${title}`}
            </div>
          ) : null}
          {showViewMore ? (
            <div onClick={onViewMore} onKeyPress={onViewMore} role="button">
              Veja mais
            </div>
          ) : null}
        </div>
      </>
    )
);

export default NoticeList;
