import { isEmpty } from 'lodash';
import { HttpService } from '~/helpers';
import { store } from '~/stores';
import { actions } from '~/stores/ducks';
import { Strings } from '~/helpers/general';
import { RememberService, CustomerService } from '~/services/general';

const endpoint = 'sistema/login';

const UserService = {
  login: ({ usuario, senha, lembrar, empresa }, onSuccess, onError) => {
    const remember = RememberService.getRemember();

    HttpService.call(
      endpoint,
      'valida_login',
      [usuario, senha, empresa],
      response => {
        const { retorno, dados_empresa: customer } = response;

        if (customer && customer.idt > 0) {
          CustomerService.setCustomer(customer);
        }

        if (retorno && retorno.length === 1) {
          UserService.setUser(retorno[0]);

          if (lembrar) {
            RememberService.setRemember({
              ...(usuario === remember.usuario && remember.customer === customer.nome && remember),
              usuario,
              senha,
              customer: customer.nome,
              lembrar: true,
            });
          } else {
            RememberService.resetRemember();
          }

          if (onSuccess) {
            onSuccess(response);
          }
        } else if (onError) {
          onError(response);
        }
      },
      onError
    );
  },
  isLogado: () => !isEmpty(UserService.getUser()) && !isEmpty(CustomerService.getCustomer()),
  setUser: user => store.dispatch(actions.setUser(user)),
  getUser: () => store.getState().user,
  logout: onSuccess => {
    store.dispatch({ type: Strings.REDUX_RESET_KEY });

    if (onSuccess) {
      onSuccess();
    }
  },
  verifica_versao: onSuccess => {
    HttpService.call('sistema/verifica_versao', 'verificar', [document.URL], onSuccess);
  },
  verifica_horario: (codigo, onSuccess, onError) => {
    HttpService.call(endpoint, 'verifica_horario', [codigo], onSuccess, onError);
  },
  esqueci_minha_senha: (email, onSuccess, onError) => {
    HttpService.call(endpoint, 'esqueci_minha_senha', [email], onSuccess, onError);
  },
  buscaParametros: (codigo, filial, dataPedido, onSuccess, onError) => {
    HttpService.call(endpoint, 'busca_parametros', [codigo, filial, dataPedido], onSuccess, onError);
  },
  fetchGrupoProduto: (onSuccess, onError) => {
    HttpService.call(endpoint, 'combo_grupo_produto', [], onSuccess, onError);
  },
  fetchGrupoSeparacao: (onSuccess, onError) => {
    HttpService.call(endpoint, 'combo_grupo_separacao', [], onSuccess, onError);
  },
  fetchContentores: (cliente, filial, onSuccess, onError) => {
    HttpService.call(endpoint, 'saldo_contentores', [cliente, filial], onSuccess, onError);
  },
  getAllRecados: (cliente, filial, onSuccess, onError) => {
    HttpService.call(endpoint, 'getAllRecados', [cliente, filial], onSuccess, onError);
  },
};

export default UserService;
