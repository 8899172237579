import React from 'react';
import { Button as AntdButton } from 'antd';
import { createSelector } from 'reselect';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { LoadingOutlined } from '@ant-design/icons';
import { Search, Insert, Cancel, Save, Update, Default, Print } from './components';
import { mobile } from './options';
import './Button.less';

const { Group } = AntdButton;

const Button = React.memo(({ children, onClick, dispatch, onMobile = mobile.show.id, loading, isMobile, validate, className, icon, ...rest }) => {
  const notMobile = onMobile === mobile.show.id || !isMobile;

  return (
    <AntdButton
      onClick={event => {
        if (!validate || validate()) {
          if (onClick) {
            onClick(event);
          }
        }
      }}
      icon={notMobile ? icon : undefined}
      loading={notMobile && loading}
      className={classNames('button', { 'button-loading': !notMobile && loading }, className)}
      {...rest}
    >
      {notMobile ? children : (icon || loading) && (loading ? <LoadingOutlined /> : icon)}
    </AntdButton>
  );
});

Button.Search = Search;
Button.Insert = Insert;
Button.Cancel = Cancel;
Button.Save = Save;
Button.Update = Update;
Button.Group = Group;
Button.Default = Default;
Button.Print = Print;

const isMobileSelector = createSelector(
  general => general,
  ({ isMobile }) => isMobile
);

const mapStateToProps = ({ general }) => ({ isMobile: isMobileSelector(general) });

export { Search, Insert, Cancel, Save, Update, Group, Default };
export default connect(mapStateToProps)(Button);
