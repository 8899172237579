import React from 'react';
import { withRouter } from 'react-router-dom';
import { Modal, Tooltip } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';
import { UserService } from '~/services/general';
import { Strings } from '~/helpers/general';

class Logout extends React.PureComponent {
  onClick = () => {
    const { onClick } = this.props;

    Modal.confirm({
      icon: <LogoutOutlined />,
      title: 'Sair',
      centered: true,
      onOk: this.onOk,
      maskClosable: true,
      destroyOnClose: true,
      okText: 'Sair',
      content: (
        <>
          {'Tem certeza de que deseja sair do '}
          <b>{Strings.APP_NAME}</b>
          {'?'}
        </>
      ),
    });

    if (onClick) {
      onClick();
    }
  };

  onOk = () => {
    const { history } = this.props;

    UserService.logout(() => history.push(`/${process.env.REACT_APP_VERSION}/login`));
  };

  render() {
    const { className } = this.props;

    return (
      <Tooltip title="Logout">
        <div className={className} onClick={this.onClick} onKeyPress={this.onClick} role="button">
          <LogoutOutlined style={{ fontSize: '16px', color: '#ff0000' }} />
        </div>
      </Tooltip>
    );
  }
}

export default withRouter(Logout);
