import 'moment/locale/pt-br';
/* eslint-disable camelcase */
import pt_BR from 'antd/lib/locale-provider/pt_BR';

const ptBR = {
  id: 'pt-BR',
  nome: 'Português',
  icone: '🇧🇷',
  antd: pt_BR,
  formats: {
    date: 'DD/MM/YYYY',
    time: 'HH:mm:ss',
    decimalSeparator: ',',
  },
};

export { ptBR };
export default [ptBR];
