import React from 'react';
import classNames from 'classnames';
import { types } from './options';
import './ContentWrapper.less';

const ContentWrapper = React.memo(({ className, type, color, ...rest }) => (
  <div
    style={{ border: color, backgroundColor: color }}
    className={classNames(
      type === 'search' ? 'content-wrapper' : 'content-wrapper-header',
      (color && color.className) || (color && color.className) || (type && types[type].color.className),
      'color-content-wrapper',
      className
    )}
    {...rest}
  />
));

export default ContentWrapper;
