import { Strings } from './general';
import { HttpService, BiometriaService, CEPService, SEFAZService } from './services';

export {
  // general
  Strings,
  // services
  HttpService,
  BiometriaService,
  CEPService,
  SEFAZService,
};
