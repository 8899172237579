import { integerValue, isRepeated, isMinimum } from '~/utils';

const isCnpj = value => {
  const newValue = integerValue(value);
  const errorMessage = 'CNPJ inválido!';

  // Elimina valores vazios, com quantidade de caracteres menor que 14 ou com todos os caracteres iguais
  if (!newValue || isMinimum(newValue, 14) || isRepeated(newValue)) {
    return newValue && errorMessage;
  }

  // Cálculo dos digitos verificadores
  const verifyingDigit = verifyingLength => {
    const numbers = newValue.substring(0, verifyingLength);
    let rest = verifyingLength - 7;
    let sum = 0;
    let result = 0;

    for (let index = verifyingLength; index >= 1; index -= 1) {
      sum += numbers.charAt(verifyingLength - index) * rest;
      rest -= 1;

      if (rest < 2) {
        rest = 9;
      }
    }

    result = 11 - (sum % 11);

    return result > 9 ? 0 : result;
  };

  // Validação dos dígitos verificadores
  const length = newValue.length - 2;
  const digits = newValue.substring(length);
  const firstVerifyingDigit = parseInt(digits.charAt(0), 10);
  const secondVerifyingDigit = parseInt(digits.charAt(1), 10);

  const validaDigitos = verifyingDigit(length) !== firstVerifyingDigit || verifyingDigit(length + 1) !== secondVerifyingDigit;

  return validaDigitos ? errorMessage : undefined;
};

export default isCnpj;
