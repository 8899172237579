import React from 'react';
import { Modal } from 'antd';
import { WarningTwoTone } from '@ant-design/icons';
import { colors } from '~/options/general';

const accessWarning = ({ title, onCancel, onOk, ...rest }) => {
  Modal.warning({
    title: title || 'Acesso Negado',
    icon: <WarningTwoTone twoToneColor={colors.calendulaGold.color} />,
    centered: true,
    maskClosable: true,
    destroyOnClose: true,
    onCancel: () => {
      if (onCancel) {
        onCancel();
      }
    },
    onOk: () => {
      if (onCancel) {
        onCancel();
      }
    },
    content: (
      <>
        {'Você não tem permissão para '}
        {title ? <b>{` ${title.toLowerCase()}`}</b> : ' esta funcionalidade'}
        {'. Se necessário contate o responsável por '}
        <b>Perfis de Acesso</b>.
      </>
    ),
    ...rest,
  });
};

export default accessWarning;
