import React from 'react';
import { useField } from 'informed';
import { Input, Select, Tooltip } from 'antd';
import { Form } from '~/components/forms';
import { isRequired } from '~/utils';

const { Option } = Select;

const SelectCombo = React.memo(({ required, validate, validateOnChange, ...props }) => {
  const { fieldState, fieldApi, render, userProps } = useField({
    validate: validate || (required && isRequired),
    validateOnChange: validateOnChange || required || validate,
    ...props,
  });

  const {
    onBlur,
    onChange,
    label,
    initialValue,
    forwardedRef,
    allowClear,
    style = {},
    formItemProps,
    dataSource,
    loading,
    dataIndex = 'data',
    labelIndex = 'label',
    renderIndex = item => item[labelIndex],
    dataSize = '22%',
    labelSize = '78%',
    dataPlaceholder,
    labelPlaceholder,
    mode,
    searchIndex,
    disabledReason,
    tooltipProps,
    ...rest
  } = userProps;

  function handleOnChange(changeValue) {
    fieldApi.setValue(changeValue);

    if (onChange) {
      const objValue = dataSource.find(item => item[dataIndex] === changeValue);
      onChange(changeValue, objValue);
    }
  }

  function handleOnBlur() {
    fieldApi.setTouched();
    const value = fieldApi.getValue();
    const objValue = dataSource.find(item => item[dataIndex] === value);

    if (onBlur) {
      onBlur(value, objValue);
    } else if (onChange) {
      if (objValue && objValue[dataIndex]) {
        fieldApi.setValue(value);
        if (onChange && !onBlur) {
          onChange(value, objValue);
        }
      }
    }
  }

  function onDataChange(event) {
    fieldApi.setValue(event.target.value);
  }

  return render(
    <Form.Item label={label} error={fieldState.error} required={required} {...formItemProps}>
      <Tooltip title={rest.disabled && disabledReason} placement="bottom" {...tooltipProps}>
        <Input.Group compact>
          <Input
            style={{ textAlign: 'center', width: dataSize, ...style }}
            ref={forwardedRef}
            placeholder={dataPlaceholder}
            onChange={onDataChange}
            value={fieldState.value && fieldState.value !== '0' ? fieldState.value : undefined || initialValue}
            onBlur={handleOnBlur}
            {...rest}
          />
          <Select
            style={{ width: labelSize, ...style }}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            placeholder={labelPlaceholder}
            value={fieldState.value && fieldState.value !== '0' ? fieldState.value : '' || initialValue}
            loading={loading}
            allowClear={!required && !rest.disabled && allowClear}
            showSearch
            mode="default"
            optionFilterProp={'children'}
            {...rest}
          >
            {dataSource &&
              dataSource.map(item => (
                <Option value={item[dataIndex]} key={item[dataIndex]}>
                  {renderIndex(item)}
                </Option>
              ))}
          </Select>
        </Input.Group>
      </Tooltip>
    </Form.Item>
  );
});

Select.Option = Option;

export { Option };
export default SelectCombo;
