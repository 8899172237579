import React from 'react';
import { Layout } from 'antd';
import { CopyrightOutlined } from '@ant-design/icons';
import { GlobalFooter, Link } from '~/components';
import { Strings } from '~/helpers/general';

const copyright = (
  <>
    {'Direitos reservados '}
    <CopyrightOutlined type="copyright" />
    {` ${new Date().getFullYear()} `}
    <Link align="center" url={Strings.COMPANY_URL}>
      {Strings.COMPANY_NAME}
    </Link>
  </>
);

const Footer = React.memo(({ style, ...rest }) => (
  <Layout.Footer style={{ padding: 0, ...style }} {...rest}>
    <GlobalFooter copyright={copyright} />
  </Layout.Footer>
));

export { copyright };
export default Footer;
