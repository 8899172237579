import { createActions, createReducer } from 'reduxsauce';
import { ptBR } from '~/options/general/locales';

// types
const { Types, Creators } = createActions({
  setSettings: ['settings'],
  changeSettings: ['settings'],
  resetSettings: [],
});

const INITIAL_STATE = {
  theme: 'dark',
  layout: 'topmenu',
  fixedHeader: false,
  autoHideHeader: false,
  contentWidth: 'Fixed',
  fixSiderbar: false,
  locale: ptBR.id,
  colorWeak: false,
};

// reducers
const set = (state, { settings }) => settings;

const change = (state, { settings }) => ({ ...state, ...settings });

const reset = () => INITIAL_STATE;

// Hookup reducers to types
const Reducers = createReducer(INITIAL_STATE, {
  [Types.SET_SETTINGS]: set,
  [Types.CHANGE_SETTINGS]: change,
  [Types.RESET_SETTINGS]: reset,
});

export { Reducers, Types, Creators };
export default Reducers;
