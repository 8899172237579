import React from 'react';
import { Card, Tabs, Divider } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classNames from 'classnames';
import { CloseOutlined } from '@ant-design/icons';
import { Creators } from '~/stores/ducks/general/tabs';
import { TabPane, Modal, Help } from './components';
import './Page.less';

class Page extends React.PureComponent {
  renderExtra = () => {
    const { tabbed, tabId, removeTab, closable = true, helpContent } = this.props;

    return (
      <div className={classNames({ 'page-extra-tabbed': tabbed === true })}>
        {helpContent && (
          <>
            <Help content={helpContent} />
            <Divider type="vertical" style={{ margin: '0 12px' }} />
          </>
        )}
        <CloseOutlined title="Fechar" disabled={!closable} onClick={() => removeTab(tabId)} />
      </div>
    );
  };

  render() {
    const { tabbed, footer, children, showExtra = true, onChange, activeKey } = this.props;

    if (!tabbed) {
      return (
        <Card extra={showExtra && this.renderExtra()} className="card-header-page">
          {children}
          {footer && <div className="page-footer">{footer}</div>}
        </Card>
      );
    }

    return (
      <Page bodyStyle={{ padding: 0 }} footer={footer} showExtra={false}>
        <Tabs
          className="page-tabs"
          renderTabBar={({ className, ...tabBarProps }, DefaultTabBar) => (
            <DefaultTabBar className={classNames(className, 'page-tabs-tab-bar')} {...tabBarProps} />
          )}
          tabBarExtraContent={this.renderExtra()}
          onChange={onChange}
          activeKey={activeKey}
        >
          {children}
        </Tabs>
      </Page>
    );
  }
}

Page.TabPane = TabPane;
Page.Modal = Modal;

const mapDispatchToProps = dispatch => bindActionCreators(Creators, dispatch);

export default connect(undefined, mapDispatchToProps)(Page);
