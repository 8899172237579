import {
  firstLast,
  wordCounter,
  decimalPlace,
  booleanValue,
  momentValue,
  backValue,
  postalValue,
  integerValue,
  dateValue,
  affirmativeValue,
  decimalValue,
  cestValue,
  ncmValue,
  cnpjValue,
  phoneValue,
  cpfCnpjValue,
  removeAccents,
  faturaValue,
  placaValue,
} from './formaters';
import { findBy, openUrl, openWindowWithPost, getLocale, complexValidator, generateUuid, upsert, tabAfterSave } from './general';
import { notifyError, notifySuccess, buscaCep } from './services';
import { stringSorter, integerSorter, currencySorter, dateSorter } from './sorters';
import {
  generateInstallmentInvoice,
  replaceObjectKey,
  limitRollupArray,
  calculaDigitoBlocoBoleto,
  calculaDigitoMOD11,
  parseIndexes,
  parseFloatValues,
  ajustaValorArray,
} from './functions';

import {
  isRequired,
  hasAccess,
  hasAccessTitle,
  isDuplicated,
  isCnpj,
  isCpf,
  isRepeated,
  isPhone,
  isMinimum,
  isMaximum,
  isEmail,
  isDocument,
  isFatura,
} from './validators';
import {
  onlyInteger,
  onlyPostal,
  onlyCurrency,
  onlyCnpj,
  onlyCpf,
  onlyCpfCnpj,
  onlyPhone,
  onlyEmail,
  onlyNumberDot,
  onlyLicencePlate,
  onlyNumberDotDash,
  onlyFatura,
  onlyPlaca,
} from './restrictors';

export {
  // functions
  generateInstallmentInvoice,
  replaceObjectKey,
  limitRollupArray,
  calculaDigitoBlocoBoleto,
  calculaDigitoMOD11,
  parseIndexes,
  parseFloatValues,
  ajustaValorArray,
  // formaters
  firstLast,
  wordCounter,
  decimalPlace,
  booleanValue,
  momentValue,
  backValue,
  postalValue,
  integerValue,
  dateValue,
  affirmativeValue,
  decimalValue,
  cestValue,
  ncmValue,
  cnpjValue,
  phoneValue,
  cpfCnpjValue,
  removeAccents,
  faturaValue,
  placaValue,
  upsert,
  tabAfterSave,
  // general
  findBy,
  openUrl,
  openWindowWithPost,
  getLocale,
  complexValidator,
  generateUuid,
  // services
  notifyError,
  notifySuccess,
  buscaCep,
  // sorters
  stringSorter,
  integerSorter,
  currencySorter,
  dateSorter,
  // validators
  isRequired,
  hasAccess,
  hasAccessTitle,
  isDuplicated,
  isCnpj,
  isCpf,
  isDocument,
  isRepeated,
  isPhone,
  isMinimum,
  isMaximum,
  isEmail,
  isFatura,
  // restrictors
  onlyInteger,
  onlyPostal,
  onlyCurrency,
  onlyNumberDot,
  onlyCnpj,
  onlyCpf,
  onlyCpfCnpj,
  onlyPhone,
  onlyEmail,
  onlyLicencePlate,
  onlyNumberDotDash,
  onlyFatura,
  onlyPlaca,
};
