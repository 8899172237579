import axios from 'axios';
import { notification } from 'antd';
import { HttpService } from '~/helpers';
import { CustomerService, UserService } from '~/services';

const endpoint = 'sistema/anexo';

const formatFilters = filters =>
  filters
    ? [
        { nome_campo: 'a.idt_registro', valor: filters.idt_registro, tipo: 'int' },
        { nome_campo: 'a.tabela', valor: filters.tabela, tipo: 'texto' },
        { nome_campo: 'a.origem', valor: filters.origem, tipo: 'int' },
      ]
    : [];

const formatValues = values => ({ ...values, usuario: UserService.getUser().login });

const FileService = {
  insertAttachment: (name, diretorio, file, onSuccess, onError) => {
    axios({
      method: 'post',
      baseURL: `${process.env.REACT_APP_URL}/arquivo/`,
      url: `upload_react.php?nome_imagem=${name}&diretorio=${diretorio}&cliente=${CustomerService.getCustomer().nome.toLowerCase()}`,
      responseType: 'json',
      responseEncoding: 'utf8',
      data: file,
    })
      .then(resp => {
        if (onSuccess) {
          onSuccess(resp);
        }
      })
      .catch(error => {
        notification.error({
          message: `Erro de solicitação`,
          description: error.statusText || 'Ocorreu um erro.',
        });

        if (onError) {
          onError(error);
        }
      });
  },

  search: (filters, onSuccess, onError) => {
    HttpService.call(endpoint, 'getAll', [formatFilters(filters)], onSuccess, onError);
  },
  insert: (values, onSuccess, onError) => {
    HttpService.call(endpoint, 'inserir', [formatValues(values)], onSuccess, onError);
  },
  delete: (id, onSuccess, onError) => {
    HttpService.call(endpoint, 'excluir', [id, UserService.getUser().login], onSuccess, onError);
  },
};

export default FileService;
