import React from 'react';
import { useField } from 'informed';
import { Select as AntdSelect, Tooltip } from 'antd';

import { Form } from '~/components/forms';
import { isRequired } from '~/utils';
import { Link } from '../general';

const { Option } = AntdSelect;

const Select = React.memo(({ required, validate, validateOnChange, ...props }) => {
  const { fieldState, fieldApi, render, userProps } = useField({
    validate: validate || (required && isRequired),
    validateOnChange: validateOnChange || required || validate,
    ...props,
  });

  const {
    onChange,
    onBlur,
    label,
    initialValue,
    forwardedRef,
    dataSource,
    dataIndex = 'data',
    labelIndex = 'label',
    renderIndex = item => item[labelIndex],
    allowClear,
    disabledReason,
    formItemProps,
    tooltipProps,
    forceZero,
    mode,
    ...rest
  } = userProps;

  function handleOnChange(changeValue, changeOption) {
    fieldApi.setValue(changeValue || '');

    if (onChange) {
      onChange(changeValue, changeOption);
    }
  }

  function handleOnBlur(event) {
    fieldApi.setTouched();

    if (onBlur) {
      onBlur(event);
    }
  }

  function selecionarTodos() {
    const newValue = dataSource.map(item => item[dataIndex]);
    fieldApi.setValue(newValue || '');

    if (onChange) {
      onChange(newValue, newValue);
    }
  }

  return render(
    <Form.Item
      label={
        mode === 'multiple' ? (
          <div style={{ display: 'flex' }}>
            {label}
            <Link label="(+)" onClick={selecionarTodos} />
          </div>
        ) : (
          label
        )
      }
      error={fieldState.error}
      required={required}
      {...formItemProps}
    >
      <Tooltip title={rest.disabled && disabledReason} placement="bottom" {...tooltipProps}>
        <AntdSelect
          autoComplete="off"
          value={(fieldState.value === '0' && !forceZero ? '' : fieldState.value) || initialValue}
          ref={forwardedRef}
          onChange={handleOnChange}
          onBlur={handleOnBlur}
          showSearch
          mode={mode}
          allowClear={!required && allowClear !== false}
          optionFilterProp="children"
          filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          {...rest}
        >
          {dataSource &&
            dataSource.map(item => (
              <Option value={item[dataIndex]} key={item[dataIndex]}>
                {renderIndex(item)}
              </Option>
            ))}
        </AntdSelect>
      </Tooltip>
    </Form.Item>
  );
});

Select.Option = Option;

export { Option };
export default Select;
