import React from 'react';
import classNames from 'classnames';
import { Tooltip } from 'antd';
import { useField } from 'informed';
import { Form } from '~/components/forms';
import { isRequired, complexValidator } from '~/utils';
import { Password, Validate, TextArea } from './components';
import { components, types } from './options';
import './Input.less';

const Input = React.memo(({ required, validate, validateOnChange, type = types.string.id, labelHorizontal, ...props }) => {
  const { fieldState, fieldApi, render, userProps } = useField({
    validate: validate || complexValidator(required && isRequired, types[type].validate),
    validateOnChange: validateOnChange || required || validate || types[type].validate,
    ...props,
  });
  const {
    onChange,
    onBlur,
    label,
    initialValue,
    forwardedRef,
    allowClear,
    style,
    component = components.input.id,
    formItemProps,
    disabledReason,
    tooltipProps,
    capsLock = true,
    ...rest
  } = userProps;
  const { Component } = components[component];

  return render(
    <Form.Item label={label} error={!rest.disabled && fieldState.error} required={required} {...formItemProps} labelHorizontal={labelHorizontal}>
      <Tooltip title={rest.disabled && disabledReason} placement="bottom" {...tooltipProps}>
        <Component
          autoComplete="none"
          ref={forwardedRef}
          value={fieldState.value || initialValue}
          onChange={event => {
            const newValue =
              types[type].parser && event.target.value.length > (fieldState.value || '').length ? types[type].parser(event.target.value) : event.target.value;

            const value = capsLock && type !== 'email' ? newValue.toUpperCase() : newValue;

            if (type !== 'email' && types[type].restrict && !types[type].restrict(value)) {
              return;
            }
            if (type === 'email') {
              if (!types[type].validate(value)) fieldState.error = 'E-mail inválido';
              else fieldState.error = '';
            }

            fieldApi.setValue(value);

            if (onChange) {
              onChange(event);
            }
          }}
          onBlur={event => {
            fieldApi.setTouched();

            if (onBlur) {
              onBlur(event);
            }
          }}
          suffix={rest.suffix || null}
          prefix={rest.prefix || null}
          allowClear={!rest.disabled && !required && allowClear}
          maxLength={types[type].length ? types[type].length : undefined}
          className={classNames((type !== 'string' && type !== 'email') || component === 'password' ? 'input-align-center' : '')}
          {...rest}
        />
      </Tooltip>
    </Form.Item>
  );
});

Input.Password = Password;
Input.Validate = Validate;
Input.TextArea = TextArea;

export { Password, Validate, TextArea };
export default Input;
