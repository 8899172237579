import React from 'react';
import Input from '..';
import { types } from '../options';

class Validate extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      status: undefined,
      value: undefined,
    };
  }

  componentDidUpdate(prevProps) {
    const { disabled } = this.props;

    if (disabled !== prevProps.disabled) {
      this.onDisabledChange();
    }
  }

  onDisabledChange = () => {
    const { disabled } = this.props;

    if (disabled) {
      this.setState({ status: undefined, value: undefined });
    }
  };

  // REVIEW - fazer para validar quando for campo obrigatório também.

  handleValidate = ({ value }) => {
    const { validate, value: propsValue, type = types.string.id } = this.props;

    const input = types[type].parser ? types[type].parser(value) : value;

    if (validate && types[type].length && input.length === types[type].length) {
      if (validate) {
        this.setState({ status: 'validating', value });

        if (validate && value && value !== propsValue) {
          validate(value, this.onValidate, () => this.onValidate());
        } else {
          this.onValidate();
        }
      }
    } else if (validate && !types[type].length) {
      this.setState({ status: 'validating', value });

      if (validate && value && value !== propsValue) {
        validate(value, this.onValidate, () => this.onValidate());
      } else {
        this.onValidate();
      }
    } else {
      this.onValidate(false);
    }
  };

  // handleValidate = ({ value }) => {
  //   const { validate, value: propsValue } = this.props;

  //   if (validate) {
  //     this.setState({ status: 'validating', value });

  //     if (validate && value && value !== propsValue) {
  //       validate(value, this.onValidate, () => this.onValidate());
  //     } else {
  //       this.onValidate();
  //     }
  //   }
  // };

  onValidate = response => {
    if (response === undefined) {
      this.setState({ status: undefined });
    } else if (response === true) {
      this.setState({ status: 'success' });
    } else if (response === false) {
      this.setState({ status: 'error' });
    }
  };

  render() {
    const { status, value: stateValue } = this.state;
    const { validate, value: propsValue, field, validator, allowClear, required = false, ...rest } = this.props;

    return (
      <Input
        formItemProps={{
          hasFeedback: true,
          validateStatus: status,
          help: status === 'error' && validator(stateValue),
        }}
        validate={value => (status === 'error' ? validator(value) : undefined)}
        onBlur={({ target }) => this.handleValidate(target)}
        field={field}
        allowClear={!status && allowClear}
        required={required}
        {...rest}
      />
    );
  }
}

export default Validate;
