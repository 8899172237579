import { createActions, createReducer } from 'reduxsauce';

// types
const { Types, Creators } = createActions({
  setRemember: ['remember'],
  changeRemember: ['remember'],
  resetRemember: [],
});

const INITIAL_STATE = {
  usuario: undefined,
  senha: undefined,
  lembrar: false,
  customer: undefined,
  empresa: undefined,
};

// reducers
const set = (state, { remember }) => remember;

const change = (state, { remember }) => ({ ...state, ...remember });

const reset = () => INITIAL_STATE;

// Hookup reducers to types
const Reducers = createReducer(INITIAL_STATE, {
  [Types.SET_REMEMBER]: set,
  [Types.CHANGE_REMEMBER]: change,
  [Types.RESET_REMEMBER]: reset,
});

export { Reducers, Types, Creators };
export default Reducers;
