import { Input } from 'antd';

const input = {
  id: 'input',
  Component: Input,
};

const password = {
  id: 'password',
  Component: Input.Password,
};

const textArea = {
  id: 'textArea',
  Component: Input.TextArea,
};

export default { input, password, textArea };
