import React from 'react';
import classNames from 'classnames';
import ReactMarkdown from 'react-markdown/with-html';
import './Markdown.less';

class Markdown extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      source: undefined,
    };
  }

  componentDidMount() {
    const { source } = this.props;

    fetch(source)
      .then(response => response.text())
      .then(text => this.setState({ source: text }));
  }

  render() {
    const { className, ...rest } = this.props;
    const { source } = this.state;

    return (
      <div className={classNames('markdown', className)} {...rest}>
        <ReactMarkdown source={source} escapeHtml={false} />
      </div>
    );
  }
}

export default Markdown;
