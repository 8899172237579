import React from 'react';
import { connect } from 'react-redux';
import { Spin } from 'antd';
import classNames from 'classnames';
import './UserMenu.less';

class UserMenu extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { user, className } = this.props;

    return user && user.nome ? (
      <span className={classNames(className, 'account')}>
        <span className="account-name">{user.nome !== user.fantasia ? `${user.nome} - (${user.fantasia})` : user.nome}</span>
      </span>
    ) : (
      <Spin size="small" style={{ marginLeft: 8, marginRight: 8 }} />
    );
  }
}

const mapStateToProps = ({ user }) => ({ user });

export default connect(mapStateToProps)(UserMenu);
