import React from 'react';
import moment from 'moment';
import { Row, Col, Modal, Alert, message, Divider } from 'antd';
import { InfoCircleTwoTone } from '@ant-design/icons';
import { FooterToolbar, Page, Form, ContentWrapper, Table, Input, Button, DatePicker, Select, IconDelete, Status, Statistic } from '~/components';
import { actions, colors, tabPanes } from '~/options';
import { CustomerService, MapaPedidoService, PedidoService, UserService, WebRelatorioService } from '~/services';
import PedidoVendaMapaGrupoView from './PedidoVendaMapaGrupoView';
import PedidoVendaMapaGrupoSeparacaoView from './PedidoVendaMapaGrupoSeparacaoView';
import PedidoVendaMapaDetalheView from './PedidoVendaMapaDetalheView';

const rowColor = [{ title: 'Adicionado', color: colors.sunriseYellow, predicate: item => parseFloat(item.quantidade) > 0 }];

class MapaPedidoPage extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      isLoadingFiliais: false,
      isLoadingItens: false,
      itens: [],
      itensProdutos: [],
      selectedItem: {},
      itemEdit: {},
      action: actions.idle,
      activeTab: tabPanes.table.key,
      filiais: [],
      showGrupo: false,
      showGrupoSeparacao: false,
      recordMapa: {},
    };
  }

  componentDidMount() {
    const cliente = UserService.getUser();

    this.setState({ isLoadingFiliais: true, isLoadingItens: true });
    MapaPedidoService.fetchFiliaisRede(
      cliente.rede,
      filiais => this.setState({ filiais, isLoadingFiliais: false }),
      () => this.setState({ filiais: [], isLoadingFiliais: false })
    );

    this.searchApi.submitForm();
  }

  handleSearch = values => {
    this.setState({ isLoading: true, itens: [] });
    MapaPedidoService.getAll(
      { ...values, rede: UserService.getUser().rede, origem: UserService.getUser().origem_venda },
      itens => this.setState({ itens, isLoading: false }, () => setTimeout(() => this.searchInput.focus(), 300)),
      () => this.setState({ isLoading: false })
    );
  };

  handleSelect = record => {
    this.saveApi.reset();
    this.setState({ selectedItem: record }, () => {
      this.onBlurCliente();
    });
  };

  handleRecordView = () => {
    const { selectedItem } = this.state;

    if (this.saveApi && selectedItem.filiais) this.saveApi.setValues({ ...selectedItem, filiais: selectedItem.filiais.split(',') });
  };

  handleEdit = action => {
    const { itensProdutos, filiais } = this.state;

    if (parseFloat(UserService.getUser().rede) === 0) {
      Modal.error({
        title: 'Mapa de Pedido',
        content: 'Cliente sem rede cadastrada!',
        centered: true,
        onOk: () => this.onEdit(true),
      });
      return;
    }

    if (!(UserService.getUser().tabela_preco || UserService.getUser().tabela_referencial)) {
      Modal.error({
        title: 'Mapa de Pedido',
        content: 'Cliente sem tabela de preço e empresa de venda sem tabela referencial!',
        centered: true,
        onOk: () => this.onEdit(true),
      });
      return;
    }

    this.setState({ action, activeTab: tabPanes.record.key, itensProdutos: action === actions.insert ? [] : itensProdutos }, () => {
      setTimeout(() => this.editInput.focus(), 300);

      if (action === actions.insert) {
        this.saveApi.reset();
        this.onChangeData();
        if (filiais.filter(item => item.gera_mapa === 'S').length > 0)
          this.saveApi.setValue(
            'filiais',
            filiais.filter(item => item.gera_mapa === 'S').map(item => item.data)
          );
      }
    });
  };

  onEdit = (cancel = false) => {
    let { selectedItem, activeTab } = this.state;

    if (cancel) {
      this.handleSelect(selectedItem);
      this.handleRecordView();

      if (!selectedItem.idt) {
        activeTab = tabPanes.table.key;
      }
    } else {
      this.searchApi.submitForm();

      const { action } = this.state;

      if (action === actions.insert) {
        activeTab = tabPanes.table.key;
        selectedItem = {};
      }
    }

    this.setState({ action: actions.idle, selectedItem, activeTab });
  };

  handleSave = values => {
    const { action, itensProdutos, filiais } = this.state;
    let record = {};

    if (action === actions.update) {
      const { selectedItem } = this.state;
      record = selectedItem;
    }

    this.setState({ isLoading: true });
    MapaPedidoService.salvar(
      {
        ...record,
        ...values,
        cliente: UserService.getUser().rede,
        origem: UserService.getUser().origem_venda,
        itens: itensProdutos.filter(item => parseFloat(item.quantidade) > 0),
        filiais: values.filiais
          .map(item => filiais.find(itemFind => itemFind.data === item))
          .filter(item => itensProdutos.filter(itemProduto => parseFloat(itemProduto[`filial_${item.data}`]) > 0).length > 0),
      },
      response => this.onSave(false, response),
      () => this.onSave(true)
    );
  };

  onSave = (error = false, response = {}) => {
    this.setState({ isLoading: false });

    if (!error) {
      if (response.alterados) {
        message.success(`${response.alterados} alterados com sucesso`);
      }
      if (response.nao_alterados) {
        message.error(`${response.nao_alterados} não foi possivel alterar`);
      }
      this.onEdit();
    }
  };

  onBlurCliente = () => {
    const { selectedItem, action } = this.state;
    const values = this.saveApi.getValues();
    const codigoCliente = UserService.getUser().codigo;
    const cliente = UserService.getUser();

    if (codigoCliente) {
      const tabelaCliente = cliente.tabela_preco || cliente.tabela_referencial;
      PedidoService.getAllProdutos(
        {
          data: values.data,
          tabela: tabelaCliente,
          filtra_precos: UserService.getUser().filtra_precos_site,
          codigo: UserService.getUser().codigo,
          filial: UserService.getUser().filial,
          estado: CustomerService.getCustomer().database,
        },
        '',
        'N',
        response => {
          this.setState(
            {
              itensProdutos: response.dados.map(item => ({ ...item, quantidade: '' })),
              isLoadingItens: false,
            },
            () => {
              if (action !== actions.insert && selectedItem.mapa) {
                this.setState({ isLoadingItens: true });
                MapaPedidoService.getAllItens(
                  selectedItem.mapa,
                  itens => {
                    const { itensProdutos } = this.state;
                    let newItensProdutos = itensProdutos;
                    itens.forEach(item => {
                      newItensProdutos = newItensProdutos.map(itemProduto =>
                        itemProduto.idt_produto === item.idt_produto
                          ? { ...itemProduto, ...item, quantidade: this.totalizaQuantidades({ ...itemProduto, ...item }) }
                          : itemProduto
                      );
                    });
                    this.setState({
                      itensProdutos: newItensProdutos,
                      isLoadingItens: false,
                    });
                  },
                  () => this.setState({ isLoadingItens: false })
                );
              }
            }
          );
        }
      );
    }
  };

  onEditItem = record => {
    const { itensProdutos } = this.state;
    const quantidadeTotal = this.totalizaQuantidades(record);
    this.setState({
      itemEdit: record,
      itensProdutos: itensProdutos.map(item =>
        item.idt_produto === record.idt_produto
          ? { ...record, quantidade: quantidadeTotal, valor_total: quantidadeTotal * parseFloat(record.preco_venda) }
          : item
      ),
    });
  };

  totalizaQuantidades = item => {
    let total = 0;
    Object.keys(item).forEach(key => {
      if (key.indexOf('filial_') > -1) {
        total += parseFloat(item[key] || 0);
      }
    });

    return total > 0 ? total : '';
  };

  onChangeFiliais = () => {
    this.forceUpdate();
  };

  handleDelete = (record, justificativa) => {
    Modal.confirm({
      content: 'Serão excluidos todos os Pedidos de Venda, deseja confirmar?',
      title: 'Excluir Mapa',
      okText: 'Sim',
      cancelText: 'Não',
      centered: true,
      onOk: () => {
        this.setState({ isLoading: true });
        MapaPedidoService.excluir(record, justificativa, () => {
          this.searchApi.submitForm();
        });
      },
    });
  };

  onBlurData = () => {
    const { itensProdutos, isLoadingItens } = this.state;

    if (itensProdutos.length === 0 && !isLoadingItens) {
      this.onChangeData();
    }
  };

  onChangeData = () => {
    const values = this.saveApi.getValues();

    this.setState({ isLoading: true });
    UserService.buscaParametros(UserService.getUser().codigo, UserService.getUser().filial, values.data, this.onBuscaParametros, () =>
      this.onBuscaParametros()
    );
  };

  onBuscaParametros = (response = []) => {
    this.setState({ isLoading: false, itensProdutos: [] });
    const values = this.saveApi.getValues();

    if (response.length > 0) {
      if (!response[0].tabela_precos) {
        Modal.error({
          content: 'Cliente sem tabela de preços cadastrada, não é possivel efetuar pedido.',
          title: 'Pedidos de Compra',
          centered: true,
          onOk: () => this.editInput.focus(),
        });
      } else if (!response[0].data) {
        Modal.error({
          content: 'Cliente sem tabela de preços preenchida, não é possivel efetuar pedido.',
          title: 'Pedidos de Compra',
          centered: true,
          onOk: () => this.editInput.focus(),
        });
      } else if (
        CustomerService.getCustomer().nome === 'Victos' &&
        response[0].data.replace('-', '').replace('-', '') !== values.data.replace('-', '').replace('-', '')
      ) {
        Modal.info({
          content: 'Não hà mix para a data selecionada! Escolha outra data!',
          title: 'Pedidos de Compra',
          centered: true,
          onOk: () => this.editInput.focus(),
        });
      } else {
        this.buscarDados(response[0].tabela_precos, response[0].data);
      }
    } else {
      Modal.error({ content: 'Cliente ou Tabela de preços não encontrada, não é possivel efetuar pedido.', title: 'Pedidos de Compra', centered: true });
    }
  };

  buscarDados = (tabelaPreco, dataEntrega) => {
    const values = this.saveApi.getValues();

    this.setState({ itensProdutos: [], isLoadingItens: true });
    PedidoService.getAllProdutos(
      {
        data: dataEntrega || values.data_pedido,
        tabela: tabelaPreco || UserService.getUser().tabela,
        filtra_precos: UserService.getUser().filtra_precos_site,
        codigo: UserService.getUser().codigo,
        filial: UserService.getUser().filial,
        estado: CustomerService.getCustomer().database,
      },
      '',
      'N',
      this.onGetAllProdutos,
      () => this.onGetAllProdutos()
    );
  };

  onGetAllProdutos = (response = []) => {
    this.setState({ itensProdutos: response.dados || [], isLoadingItens: false });
  };

  imprimir = () => {
    const { selectedItem } = this.state;
    const values = [];

    values.push({ nome_campo: 'mostra_preco_pedidos', valor: UserService.getUser().mostra_custos_site === '1' ? 'S' : 'N' });
    values.push({ nome_campo: 'pedido', valor: selectedItem.pedidos });
    values.push({ nome_campo: 'exporta_excel', valor: 'N' });
    values.push({ nome_campo: 'exporta_csv', valor: 'N' });
    values.push({ nome_campo: 'exporta_pdf', valor: 'S' });

    WebRelatorioService.getChamaRelatorio(values, 'ServletPedidos', 'Resumo do Pedido');
  };

  disabledDate = current => {
    if (UserService.getUser().data_retroativa_portal === 'N') return current && current < moment().add(1, 'day');
    return true;
  };

  render = () => {
    const { menuId, tabId } = this.props;
    const {
      isLoading,
      isLoadingItens,
      itens,
      selectedItem,
      action,
      activeTab,
      filiais,
      itensProdutos,
      showGrupo,
      showGrupoSeparacao,
      isLoadingFiliais,
      itemEdit,
      recordMapa,
    } = this.state;
    const isEdit = action !== actions.idle && !isLoading;
    const isIdle = action === actions.idle && !isLoading;

    const MOSTRA_CUSTO = UserService.getUser().mostra_custos_site === '1';

    const filiaisSelecteds = (this.saveApi && filiais.length > 0 ? this.saveApi.getValue('filiais') || [] : [])
      .map(item => filiais.find(itemFind => itemFind.data === item))
      .filter(item => item);

    const quantidadeTotal = itensProdutos.reduce((previous, current) => parseFloat(current.quantidade || 0) + previous, 0);

    return (
      <>
        <Page
          tabbed
          tabId={tabId}
          closable={isIdle}
          activeKey={activeTab}
          onChange={activeKey => this.setState({ activeTab: activeKey })}
          footer={action !== actions.insert && selectedItem.idt && <Status record={selectedItem} />}
        >
          <Page.TabPane key={tabPanes.table.key} tab={tabPanes.table.tab} disabled={action === actions.idle && !selectedItem.idt}>
            <PedidoVendaMapaGrupoView action={showGrupo} onCancel={this.onCancel} onOk={this.onOk} record={selectedItem} />
            <PedidoVendaMapaGrupoSeparacaoView action={showGrupoSeparacao} onCancel={this.onCancel} onOk={this.onOk} record={selectedItem} />
            <PedidoVendaMapaDetalheView onCancel={() => this.setState({ recordMapa: {} })} record={recordMapa} />
            <Form getApi={api => (this.searchApi = api)} onSubmit={this.handleSearch}>
              <ContentWrapper type="search">
                <Row gutter={4}>
                  <Col md={3}>
                    <DatePicker label="Período Inicial" field="inicio_emissao" disabled={isLoading} today required />
                  </Col>
                  <Col md={3}>
                    <DatePicker label="Período Final" field="fim_emissao" disabled={isLoading} today required />
                  </Col>
                  <Col md={3}>
                    <Input label="Mapa" field="mapa" disabled={isLoading} allowClear forwardedRef={ref => (this.searchInput = ref)} />
                  </Col>
                  <Col md={3}>
                    <Button.Search loading={isLoading} />
                  </Col>
                </Row>
              </ContentWrapper>
            </Form>
            <Table
              dataSource={itens}
              rowKey="mapa"
              loading={isLoading}
              record={selectedItem}
              onRecordChange={this.handleSelect}
              onRow={{
                onDoubleClick: () =>
                  this.setState({ activeTab: tabPanes.record.key }, () => {
                    this.handleRecordView();
                  }),
              }}
            >
              <Table.Column title="Mapa" dataIndex="mapa" type="integer" width="10%" />
              <Table.Column title="Data" dataIndex="data" type="date" width="10%" />
              <Table.Column title="Cliente" dataIndex="nome_cliente" width="40%" />
              <Table.Column title="Pedidos" dataIndex="pedidos" width="20%" />
              <Table.Column title="Valor" dataIndex="valor_total" type="currency" width="10%" hide={!MOSTRA_CUSTO} />
              <Table.Column
                title="Ações"
                dataIndex="actions"
                width="10%"
                exporter={false}
                align="center"
                sorter={false}
                render={(text, record) => (
                  <>
                    <InfoCircleTwoTone
                      className={record.pedidos ? '' : 'icon-disabled'}
                      onClick={() => this.setState({ recordMapa: record })}
                      theme="twoTone"
                      title="Detalhes Mapa"
                      twoToneColor="#0000ff"
                    />
                    <Divider type="vertical" />
                    <IconDelete
                      validate={{ value: record.mapa, name: `código: ${record.mapa}`, prefix: 'o' }}
                      prefix="o mapa"
                      description={record.nome_cliente}
                      disabled={record.notas}
                      onClick={justificativa => this.handleDelete(record, justificativa)}
                    />
                  </>
                )}
              />
            </Table>
          </Page.TabPane>
          <Page.TabPane key={tabPanes.record.key} tab={tabPanes.record.tab} disabled={action === actions.idle && !selectedItem.idt}>
            <Form getApi={api => (this.saveApi = api)} onSubmit={this.handleSave}>
              <ContentWrapper type="header" color={action.color}>
                <Row gutter={4}>
                  <Col md={3}>
                    <DatePicker
                      field="data"
                      label="Data"
                      disabled={!isEdit}
                      today
                      required
                      forwardedRef={ref => (this.editInput = ref)}
                      onBlur={this.onBlurData}
                      onChange={this.onChangeData}
                      disabledDate={this.disabledDate}
                      initialValue={moment()
                        .add(1, 'day')
                        .format('YYYYMMDD')}
                    />
                  </Col>
                  <Col md={8}>
                    <Select
                      field="filiais"
                      label="Filiais"
                      mode="multiple"
                      maxTagCount={1}
                      dataSource={filiais}
                      disabled={!isEdit || isLoadingFiliais}
                      loading={isLoadingFiliais}
                      onChange={this.onChangeFiliais}
                      required
                    />
                  </Col>
                </Row>
              </ContentWrapper>
            </Form>
            <Alert style={{ textAlign: 'center' }} message={itemEdit.descricao || 'NENHUM PRODUTO SELECIONADO'} type="error" />
            <Form getApi={api => (this.tableApi = api)}>
              <Table
                rowKey="idt_produto"
                dataSource={itensProdutos}
                onRecordChange={newItemEdit => this.setState({ itemEdit: newItemEdit })}
                scroll={{ scrollToFirstRowOnChange: true, x: `${1000 + filiaisSelecteds ? filiaisSelecteds.length * 75 : 0}px` }}
                rowColor={rowColor}
                loading={isLoadingItens}
              >
                <Table.Column title="Código" dataIndex="idt_produto" width="75px" fixed="left" />
                <Table.Column title="Cód.Cliente" dataIndex="codigo_cliente" width="120px" fixed="left" />
                <Table.Column title="Descrição" dataIndex="descricao" width="400px" fixed="left" />
                <Table.Column title="Grupo" dataIndex="nome_grupo" width="150px" />
                <Table.Column title="Qtde." dataIndex="quantidade" type="decimal" width="80px" />
                <Table.Column title="Unitário" dataIndex="preco_venda" type="currency" width="90px" hide={!MOSTRA_CUSTO} />
                <Table.Column title="R$ KG" dataIndex="preco_venda_kg" type="currency" width="90px" hide={!MOSTRA_CUSTO} />
                <Table.Column title="Total" dataIndex="valor_total" type="currency" width="90px" hide={!MOSTRA_CUSTO} />
                {filiaisSelecteds && filiaisSelecteds.length > 0 ? (
                  filiaisSelecteds.map(item => (
                    <Table.Column
                      title={item.label}
                      editable={isEdit}
                      width="100px"
                      functionAction={this.onEditItem}
                      dataIndex={`filial_${item.data}`}
                      key={`filial_${item.data}`}
                      type="decimal"
                      sorter={false}
                      ellipsis
                    />
                  ))
                ) : (
                  <Table.Column dataIndex="x" />
                )}
              </Table>
            </Form>
            <ContentWrapper type="header" color={action.color}>
              <Row gutter={4}>
                <Col md={5} />
                <Col md={6}>
                  <Statistic
                    title="Valor Total"
                    value={itensProdutos.reduce((previous, current) => parseFloat(current.valor_total || 0) + previous, 0)}
                    color="#0000ff"
                  />
                </Col>
                <Col md={6}>
                  <Statistic title="Quantidade Total" prefix="" value={quantidadeTotal} color="#3f8600" />
                </Col>
                <Col md={7}>
                  <Statistic title="Quantidade Produtos" prefix="" value={itensProdutos.filter(item => item.quantidade).length} color="#3f8600" />
                </Col>
              </Row>
            </ContentWrapper>
          </Page.TabPane>
        </Page>
        <FooterToolbar
          left={
            <>
              <Button.Insert perfilAcess menuId={menuId} disabled={!isIdle} onClick={() => this.handleEdit(actions.insert)} />
              <Button.Update perfilAcess menuId={menuId} disabled={!selectedItem.idt || !isIdle} onClick={() => this.handleEdit(actions.update)} />
            </>
          }
          center={
            <>
              <Button.Print onClick={this.imprimir} disabled={!selectedItem.pedidos} />
            </>
          }
          right={
            <>
              <Button.Cancel disabled={isIdle || isLoading} onClick={() => this.onEdit(true)} />
              <Button.Save
                disabled={isIdle || isLoading || quantidadeTotal === 0 || (filiaisSelecteds && filiaisSelecteds.length === 0)}
                loading={action !== actions.idle && isLoading}
                onClick={() => this.saveApi.submitForm()}
              />
            </>
          }
        />
      </>
    );
  };
}

export default MapaPedidoPage;
