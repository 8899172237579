import React from 'react';
import KeyboardEventHandler from 'react-keyboard-event-handler';
import { InputNumber as AntdInputNumber } from 'antd';
import classNames from 'classnames';
import { types } from './options';
import './InputNumberCell.less';
import { decimalPlace } from '~/utils';

class InputNumberCell extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { type, onChange, onBlur, className, record, field, functionAction, value, defaultValue, skipColumn, ...rest } = this.props;

    return (
      <KeyboardEventHandler
        handleKeys={['enter']}
        onKeyEvent={(key, e) => {
          const elements = Array.from(e.target.form.elements).filter(item => item.type === 'text');
          const moveNext = elements.findIndex(item => item === e.target);
          if (moveNext > -1 && elements[moveNext + skipColumn]) {
            elements[moveNext + skipColumn].focus();
            elements[moveNext + skipColumn].select();
          }
        }}
      >
        <AntdInputNumber
          size="small"
          value={defaultValue || value}
          defaultValue={defaultValue}
          precision={types[type].precision}
          formatter={types[type].formatter}
          parser={types[type].parser}
          className={classNames('input-number', { block: true }, className, types[type].className)}
          onChange={changeValue => {
            if (types[type].restrict && !types[type].restrict(changeValue)) {
              return;
            }

            record[field] = changeValue.toString().length > 3 && types[type].parser ? types[type].parser(types[type].formatter(changeValue)) : changeValue;

            if (onChange) {
              onChange(changeValue);
            }
          }}
          onBlur={event => {
            if (record[field] !== defaultValue || value) {
              record[field] =
                record[field] && types[type].parser ? types[type].parser(decimalPlace(record[field].toString().replace(/,/g, '.'), 3)) : record[field];
              if (functionAction) functionAction(record, field);
            }

            if (onBlur) {
              onBlur(event);
            }
          }}
          {...rest}
        />
      </KeyboardEventHandler>
    );
  }
}

export default InputNumberCell;
