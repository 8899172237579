import React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import classNames from 'classnames';
import './Theme.less';

class Theme extends React.PureComponent {
  componentDidMount() {
    this.changeColorWeak();
  }

  componentDidUpdate(prevProps) {
    const { colorWeak } = this.props;

    if (colorWeak !== prevProps.colorWeak) {
      this.changeColorWeak();
    }
  }

  changeColorWeak = () => {
    const { colorWeak } = this.props;
    document.body.className = classNames({ 'color-weak': colorWeak });
  };

  render() {
    return null;
  }
}

const colorWeakSelector = createSelector(
  settings => settings,
  ({ colorWeak }) => colorWeak
);

const mapStateToProps = ({ settings }) => ({ colorWeak: colorWeakSelector(settings) });

export default connect(mapStateToProps)(Theme);
