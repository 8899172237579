import React from 'react';
import { SaveOutlined } from '@ant-design/icons';
import Button from '../..';
import './Save.less';

const Save = React.memo(({ children, ...rest }) => (
  <Button icon={<SaveOutlined />} type="primary" className="save-button" onMobile="icon" {...rest}>
    {children || 'Salvar'}
  </Button>
));

export default Save;
