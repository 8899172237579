import { createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import createEncryptor from 'redux-persist-transform-encrypt';
import createSagaMiddleware from 'redux-saga';
import storage from 'redux-persist/lib/storage';
import { Strings } from '~/helpers/general';

// reducers and sagas
import rootReducer from './ducks';
import rootSaga from './sagas';

// criptography
const encryptor = createEncryptor({ secretKey: Strings.REDUX_SECRET_KEY });

// persistor
const persistedReducer = persistReducer(
  {
    key: Strings.REDUX_PERSIST_KEY,
    storage,
    whitelist: ['remember', 'settings'],
    transforms: [encryptor],
  },
  rootReducer
);

const sagaMiddleware = createSagaMiddleware();
const store = createStore(persistedReducer, applyMiddleware(sagaMiddleware));
const persistor = persistStore(store);

// start/run saga middleware
rootSaga(sagaMiddleware);

export { store, persistor };
export default store;
