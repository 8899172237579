import { calculaDigitoBlocoBoleto, calculaDigitoMOD11 } from '~/utils';

const isFatura = value => {
  let errorMessage = false;

  if (value) {
    const isBoleto = value.substr(0, 1) !== '8';
    const strFatura = value
      .split(' ')
      .join('')
      .split('.')
      .join('');

    if (value.length < 54 && value) errorMessage = 'Complete a linha digitável!';

    if (isBoleto) {
      if (strFatura.length > 9 && calculaDigitoBlocoBoleto(strFatura.substr(0, 9)) !== strFatura.substr(9, 1)) errorMessage = 'Bloco 1 inválido!';
      if (strFatura.length > 20 && calculaDigitoBlocoBoleto(strFatura.substr(10, 10)) !== strFatura.substr(20, 1)) errorMessage = 'Bloco 2 inválido!';
      if (strFatura.length > 31 && calculaDigitoBlocoBoleto(strFatura.substr(21, 10)) !== strFatura.substr(31, 1)) errorMessage = 'Bloco 3 inválido!';
      if (
        strFatura.length === 47 &&
        calculaDigitoMOD11(strFatura.substr(0, 4) + strFatura.substr(33, 14) + strFatura.substr(4, 5) + strFatura.substr(10, 10) + strFatura.substr(21, 10)) !==
          strFatura.substr(32, 1)
      )
        errorMessage = 'Boleto inválido!';
    }
  }
  return errorMessage || undefined;
};

export default isFatura;
