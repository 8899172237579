import React from 'react';
import classNames from 'classnames';
import { Form as InformedForm } from 'informed';
import { Item } from './components';
import './Form.less';

const Form = React.memo(({ className, ...rest }) => (
  // antd/lib/form/style/index.less
  <InformedForm className={classNames('ant-form', 'ant-form-vertical', 'form-general', className)} allowEmptyStrings {...rest} />
));

Form.Item = Item;

export default Form;
