import React from 'react';
import { Dropdown } from 'antd';
import classNames from 'classnames';
import './HeaderDropdown.less';

class HeaderDropdown extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { overlayClassName, ...rest } = this.props;

    return <Dropdown overlayClassName={classNames('header-dropdown-container', overlayClassName)} {...rest} />;
  }
}

export default HeaderDropdown;
