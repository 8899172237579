import React from 'react';
import { Divider as AntdDivider } from 'antd';
import classNames from 'classnames';
import { types } from './options';
import './Divider.less';

const Divider = React.memo(({ className, color, type, label, children, ...rest }) => (
  <AntdDivider
    className={classNames(
      (color && color.className) || (type && types[type].color.className),
      'color-divider',
      className
    )}
    {...rest}
  >
    { label || children}
  </AntdDivider>
));

export default Divider;
