import React from 'react';
import { Modal } from 'antd';
import { DeleteTwoTone } from '@ant-design/icons';
import { Input } from '~/components';
import { actions } from '~/options/general';
import { hasAccess } from '~/utils/validators';
import { accessWarning } from '~/components/utils';

const Delete = React.memo(({ menuId, onClick: propsOnClick, onCancel, prefix, description, validate = {}, ...rest }) => (
  <DeleteTwoTone
    title={actions.remove.name}
    twoToneColor={actions.remove.color.color}
    onCancel={onCancel}
    onClick={() => {
      if (hasAccess(menuId, actions.remove, false) || !menuId) {
        const modal = Modal.confirm({
          title: actions.remove.name,
          icon: <DeleteTwoTone twoToneColor={actions.remove.color.color} />,
          centered: true,
          onOk: propsOnClick,
          okText: actions.remove.name,
          okType: 'danger',
          maskClosable: true,
          destroyOnClose: true,
          okButtonProps: { disabled: validate.value !== undefined },
          onCancel,
          content: (
            <>
              {`Tem certeza de que deseja ${actions.remove.name.toLowerCase()}${prefix && ` ${prefix}`}`}
              {description ? <b>{` ${description}`}</b> : ' o registro'}
              {`? Esta operação não poderá ser desfeita.`}
              {validate.value && (
                <>
                  <div style={{ margin: '16px 0 8px' }}>
                    {`Digite ${validate.prefix && `${validate.prefix} `}`}
                    {validate.name ? <b>{` ${validate.name}`}</b> : ' a validação'}
                    {' para confirmar.'}
                  </div>
                  <Input
                    autoFocus
                    formItemProps={{ style: { marginBottom: 0 }, colon: false }}
                    type={validate.type || 'id'}
                    placeholder={validate.value}
                    validate={() => undefined}
                    onChange={({ target }) => {
                      modal.update({
                        okButtonProps: { disabled: target.value !== validate.value },
                      });
                    }}
                  />
                </>
              )}
            </>
          ),
        });
      } else accessWarning({ title: 'Excluir' });
    }}
    {...rest}
  />
));

export default Delete;
