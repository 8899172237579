import React from 'react';
import { CheckCircleTwoTone, ClockCircleTwoTone, StopTwoTone, ExclamationCircleTwoTone } from '@ant-design/icons';

import './IconOperacaoNota.less';
import { Tooltip } from 'antd';

const porpsOperacao = {
  A: <ClockCircleTwoTone className="icon" twoToneColor="#0000ff" />,
  B: <ClockCircleTwoTone className="icon" twoToneColor="#ff9900" />,
  D: <CheckCircleTwoTone className="icon" twoToneColor="#336600" />,
  G: <CheckCircleTwoTone className="icon" twoToneColor="#cccc00" />,
  J: <CheckCircleTwoTone className="icon" twoToneColor="#33cc33" />,
  K: <StopTwoTone className="icon" twoToneColor="#cc0000" />,
  L: <CheckCircleTwoTone className="icon" twoToneColor="#a6a6a6" />,
  M: <CheckCircleTwoTone className="icon" twoToneColor="#000000" />,
  Q: <ClockCircleTwoTone className="icon" twoToneColor="#ff3300" />,
  S: <CheckCircleTwoTone className="icon" twoToneColor="#d9d9d9" />,
  T: <CheckCircleTwoTone className="icon" twoToneColor="#99ff33" />,
  V: <ClockCircleTwoTone className="icon" twoToneColor="#3399ff" />,
  X: <ExclamationCircleTwoTone className="icon" twoToneColor="#ff0000" />,
  Y: <ClockCircleTwoTone className="icon" twoToneColor="#e68a00" />,
  Z: <ClockCircleTwoTone className="icon" twoToneColor="#bf8040" />,
};

const IconOperacaoNota = React.memo(({ record, tooltip }) => (
  <Tooltip title={tooltip}>{porpsOperacao[record.operacao] ? porpsOperacao[record.operacao] : ''}</Tooltip>
));

export default IconOperacaoNota;
