import React from 'react';
import { Title } from '~/components';
import { BasicLayout } from '~/layouts';
import { Tabs } from './components';

const HomePage = () => {
  return (
    <>
      <Title title="Portal de Pedidos" />
      <BasicLayout>
        <Tabs />
      </BasicLayout>
    </>
  );
};

export default HomePage;
