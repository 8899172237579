import { colors } from '~/options/general';

const form = {
  color: colors.polarGreen,
};

const search = {
  color: colors.geekBlue,
};

export default { form, search };
