import React from 'react';
import Button from '../..';
import { actions } from '~/options/general';
import { hasAccess } from '~/utils/validators';
import './Update.less';
import { EditOutlined } from '@ant-design/icons';

const Update = React.memo(({ children, menuId, perfilAcess, ...rest }) => (
  <Button
    icon={<EditOutlined />}
    type="primary"
    className="update-button"
    validate={() => (perfilAcess === true ? true : hasAccess(menuId, perfilAcess ? { perfil: perfilAcess } : actions.update))}
    onMobile="icon"
    {...rest}
  >
    {children || actions.update.name}
  </Button>
));

export default Update;
