const stringSorter = (a, b) => {
    let result;
    if (!a) {
        result = -1;
     } else if (!b) {
        result = +1;
     } else {
        result = a.localeCompare(b);
     }
     return result;
}

export default stringSorter;
