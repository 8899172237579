import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { PrivateRoute } from '~/components/general';
import { LoginPage, HomePage } from '~/pages/general';

const Router = React.memo(() => (
  <BrowserRouter>
    <Switch>
      <Route path={`/${process.env.REACT_APP_VERSION}/login`} component={LoginPage} />
      <PrivateRoute path={`/${process.env.REACT_APP_VERSION}/home`} component={HomePage} />
      <Redirect path="/" to={`/${process.env.REACT_APP_VERSION}/home`} />
    </Switch>
  </BrowserRouter>
));

export default Router;
