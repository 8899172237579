import React from 'react';
import { useField } from 'informed';
import { DatePicker as AntdDatePicker, Tooltip } from 'antd';
import classNames from 'classnames';
import { SizeMe } from 'react-sizeme';
import moment from 'moment';
import { isRequired, momentValue, backValue } from '~/utils';
import { Form } from '~/components/forms';
import './DatePicker.less';

const DatePicker = React.memo(({ required, validate, validateOnChange, today, ...props }) => {
  props.initialValue = today ? moment().format('YYYYMMDD') : props.initialValue;

  const { fieldState, fieldApi, render, userProps } = useField({
    validate: validate || (required && isRequired),
    validateOnChange: validateOnChange || required || validate,
    ...props,
  });
  const {
    onChange,
    onBlur,
    label,
    initialValue,
    forwardedRef,
    allowClear,
    formItemProps,
    className,
    block,
    dropdownClassName,
    popupStyle,
    locale,
    disabledReason,
    tooltipProps,
    ...rest
  } = userProps;

  let formato = 'DD/MM/YYYY';
  let formatoBack = 'YYYYMMDD';
  if (props.picker === 'month') {
    formato = 'MM/YYYY';
    formatoBack = 'YYYYMM';
  } else if (props.picker === 'year') {
    formato = 'YYYY';
    formatoBack = 'YYYY';
  }

  return render(
    <Form.Item label={label} error={fieldState.error} required={required} {...formItemProps}>
      <Tooltip title={rest.disabled && disabledReason} placement="bottom" {...tooltipProps}>
        <SizeMe>
          {({ size }) => (
            <AntdDatePicker
              placeholder={moment().format(formato)}
              value={momentValue(fieldState.value || initialValue)}
              ref={forwardedRef}
              className={classNames('date-picker', { block: block === true }, className)}
              dropdownClassName={classNames('date-picker-dropdown', { block: block === true }, dropdownClassName)}
              format={formato}
              onChange={(date, dateString) => {
                fieldApi.setValue(backValue(date, 'date', formatoBack));

                if (onChange) {
                  onChange(date, dateString);
                }
              }}
              onBlur={event => {
                fieldApi.setTouched();

                if (onBlur) {
                  onBlur(event);
                }
              }}
              allowClear={!required && !rest.disabled}
              popupStyle={{
                ...(block && size.width > 280 && { width: size.width }),
                ...popupStyle,
              }}
              {...rest}
            />
          )}
        </SizeMe>
      </Tooltip>
    </Form.Item>
  );
});

export default DatePicker;
