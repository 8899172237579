import React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import Button from '..';

const Default = React.memo(({ label, children, footer, icon, block, isMobile, ...rest }) => (
  <Button type="default" icon={icon} className={[footer ? '' : 'button-form', block ? 'button-block' : '']} {...rest}>
    {isMobile ? true : label || children || ''}
  </Button>
));

const isMobileSelector = createSelector(
  general => general,
  ({ isMobile }) => isMobile
);

const mapStateToProps = ({ general }) => ({ isMobile: isMobileSelector(general) });

export default connect(mapStateToProps)(Default);
