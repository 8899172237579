import { createActions, createReducer } from 'reduxsauce';

// types
const { Types, Creators } = createActions({
  fetchPerfisLegacy: [],
  fetchPerfisLegacySuccess: ['data'],
  fetchPerfisLegacyFailure: [],
  resetPerfisLegacy: [],
});

const INITIAL_STATE = {
  loading: false,
  data: [],
};

// reducers
const fetch = (state = INITIAL_STATE) => ({ ...state, loading: true });

const fetchSuccess = (state, { data }) => ({ data, loading: false });

const fetchFailure = (state = INITIAL_STATE) => ({ ...state, loading: false });

const reset = () => INITIAL_STATE;

// Hookup reducers to types
const Reducers = createReducer(INITIAL_STATE, {
  [Types.FETCH_PERFIS_LEGACY]: fetch,
  [Types.FETCH_PERFIS_LEGACY_SUCCESS]: fetchSuccess,
  [Types.FETCH_PERFIS_LEGACY_FAILURE]: fetchFailure,
  [Types.RESET_PERFIS_LEGACY]: reset,
});

export { Reducers, Types, Creators };
export default Reducers;
