import _ from 'lodash';

const firstLast = phrase => {
  const startPhrase = _.words(_.startCase(_.lowerCase(phrase)));

  return `${startPhrase.length > 0 && startPhrase[0]}${
    startPhrase.length > 1 ? ` ${startPhrase[startPhrase.length - 1]}` : ''
  }`;
};

export default firstLast;
