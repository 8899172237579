import React from 'react';
import ReactMedia from 'react-media';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions } from '~/stores/ducks';

const Media = React.memo(({ changeGeneral, changeSettings }) => (
  // @screen-lg: 992px;
  <ReactMedia
    query="(max-width: 991px)"
    onChange={isMobile => {
      changeGeneral({ isMobile, isCollapsed: isMobile });
      changeSettings(isMobile && { contentWidth: 'Fluid', layout: 'sidemenu' });
    }}
  />
));

const mapDispatchToProps = dispatch => bindActionCreators(actions, dispatch);

export default connect(
  undefined,
  mapDispatchToProps
)(Media);
