import isRequired from './isRequired';
import hasAccess from './hasAccess';
import hasAccessTitle from './hasAccessTitle';
import isDuplicated from './isDuplicated';
import isCnpj from './isCnpj';
import isCpf from './isCpf';
import isDocument from './isDocument';
import isRepeated from './isRepeated';
import isPhone from './isPhone';
import isMinimum from './isMinimum';
import isMaximum from './isMaximum';
import isEmail from './isEmail';
import isFatura from './isFatura';

export { isRequired, hasAccess, hasAccessTitle, isDuplicated, isCnpj, isCpf, isDocument, isRepeated, isPhone, isMinimum, isMaximum, isEmail, isFatura };
