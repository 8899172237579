import { integerValue } from '~/utils';

const phoneValue = value => {
  const newValue = integerValue(value);
  let regex = /(\d{4})(\d{4})/g;

  if (newValue.length === 11) {
    regex = /(\d{2})(\d{5})(\d{4})/g;
  } else if (newValue.length === 10) {
    regex = /(\d{2})(\d{4})(\d{4})/g;
  } else if (newValue.length === 9) {
    regex = /(\d{5})(\d{4})/g;
  }

  return newValue.replace(regex, newValue.length >= 10 ? '($1) $2-$3' : '$1-$2');
};

export default phoneValue;
