import React from 'react';
import ReactDOM from 'react-dom';
import { Badge, Tabs, Spin, message } from 'antd';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createSelector } from 'reselect';
import { Creators } from '~/stores/ducks/general/notifications';
import { HeaderDropdown } from '~/components';
import { NoticeList } from './components';
import { event as emptyEvent, message as emptyMessage, notification as emptyNotification } from '~/layouts/general/BasicLayout/assets';
import './NoticeIcon.less';
import { BellOutlined } from '@ant-design/icons';

const tabs = [
  {
    key: 'notification',
    title: 'Notificação',
    emptyImage: emptyNotification,
    emptyText: 'Você visualizou todas as notificações.',
    clearedText: 'Notificações limpas.',
  },
  {
    key: 'message',
    title: 'Mensagem',
    emptyImage: emptyMessage,
    emptyText: 'Você visualizou todas as mensagens.',
    clearedText: 'Mensagens limpas.',
  },
  {
    key: 'event',
    title: 'Evento',
    emptyImage: emptyEvent,
    emptyText: 'Você visualizou todos os eventos.',
    clearedText: 'Eventos limpos.',
  },
];

class NoticeIcon extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isVisible: false,
    };
  }

  onItemClick = item => {
    const { toggleNotification, clickClose = false } = this.props;

    toggleNotification(item.id);

    if (clickClose) {
      this.popover.click();
    }
  };

  onClear = type => {
    const { clearNotifications, clearClose = false } = this.props;

    clearNotifications(type.key);
    message.success(type.clearedText);

    if (clearClose) {
      this.popover.click();
    }
  };

  renderOverlay = () => {
    const { notifications } = this.props;

    return (
      <>
        <Spin delay={0} spinning={notifications.loading}>
          <Tabs className="notice-icon-tabs">
            {tabs.map(type => {
              const data = notifications.data.filter(item => item.type === type.key);
              const counter = data.filter(item => !item.read).length;

              return (
                <Tabs.TabPane key={type.key} tab={`${type.title}${counter > 0 ? ` (${counter})` : ''}`}>
                  <NoticeList
                    title={type.title}
                    data={data}
                    emptyImage={type.emptyImage}
                    emptyText={type.emptyText}
                    onClick={item => this.onItemClick(item)}
                    onClear={() => this.onClear(type)}
                  />
                </Tabs.TabPane>
              );
            })}
          </Tabs>
        </Spin>
      </>
    );
  };

  render() {
    const { className, counter } = this.props;
    const { isVisible } = this.state;

    return (
      <HeaderDropdown
        trigger={['click']}
        visible={isVisible}
        placement="bottomRight"
        // eslint-disable-next-line react/no-find-dom-node
        forwardedRef={node => (this.popover = ReactDOM.findDOMNode(node))}
        onVisibleChange={value => this.setState({ isVisible: value })}
        overlay={this.renderOverlay()}
        overlayClassName="notice-icon-popover"
      >
        <span className={classNames(className, 'notice-icon-button')}>
          <Badge style={{ boxShadow: 'none' }} className="notice-icon-badge" count={counter}>
            <BellOutlined className="notice-icon-icon" />
          </Badge>
        </span>
      </HeaderDropdown>
    );
  }
}

const counterSelector = createSelector(
  notifications => notifications,
  ({ data }) => data.filter(item => !item.read).length
);

const mapStateToProps = ({ notifications }) => ({
  notifications,
  counter: counterSelector(notifications),
});

const mapDispatchToProps = dispatch => bindActionCreators(Creators, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(NoticeIcon);
