import React from 'react';
import { Spin } from 'antd';

const Loading = React.memo(({ style, size = 'large' }) => (
  <div style={{ textAlign: 'center', ...style }}>
    <Spin size={size} />
  </div>
));

export default Loading;
