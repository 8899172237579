import { HttpService } from '~/helpers';
import { UserService } from '~/services';

const endpoint = 'sistema/pesquisa_preco';

const formatFilters = filters =>
  filters
    ? [
        { nome_campo: 'a.cliente', valor: filters.cliente, tipo: 'int' },
        { nome_campo: 'a.filial', valor: filters.filial, tipo: 'int' },
        { nome_campo: 'a.data', valor: `${filters.inicio} AND ${filters.fim}`, tipo: 'datas' },
        { nome_campo: 'a.codigo', valor: filters.codigo, tipo: 'int' },
      ]
    : [];

const formatValues = values => ({
  ...values,
  usuario: UserService.getUser().login,
});

const PesquisaPrecoService = {
  getAll: (values, onSuccess, onError) => {
    HttpService.call(endpoint, 'getAll', [formatFilters(values)], onSuccess, onError);
  },
  getAllProdutos: (values, onSuccess, onError) => {
    HttpService.call(endpoint, 'getAllProdutos', [formatValues(values)], onSuccess, onError);
  },
  getAllItens: (codigo, onSuccess, onError) => {
    HttpService.call(endpoint, 'getAllItens', [codigo], onSuccess, onError);
  },
  inserir: (values, onSuccess, onError) => {
    HttpService.call(endpoint, 'inserir', [formatValues(values)], onSuccess, onError);
  },
  alterar: (values, onSuccess, onError) => {
    HttpService.call(endpoint, 'alterar', [formatValues(values)], onSuccess, onError);
  },
};

export default PesquisaPrecoService;
