import { createActions, createReducer } from 'reduxsauce';

// types
const { Types, Creators } = createActions({
  setUser: ['user'],
  resetUser: [],
});

const INITIAL_STATE = {};

// reducers
const set = (state, { user }) => user;

const reset = () => INITIAL_STATE;

// Hookup reducers to types
const Reducers = createReducer(INITIAL_STATE, {
  [Types.SET_USER]: set,
  [Types.RESET_USER]: reset,
});

export { Reducers, Types, Creators };
export default Reducers;
