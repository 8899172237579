import axios from 'axios';
import { UserService, CustomerService } from '~/services';
import { notifyError } from '~/utils/services';

const getVars = idtmp => {
  if (!UserService.isLogado()) {
    return {};
  }

  const user = UserService.getUser();
  const customer = CustomerService.getCustomer();

  return {
    usuario: user.nome || '',
    perfil: user.perfil || -1,
    idtmp: idtmp || customer.idtmp || -1,
    cliente: customer.nome || '',
    sistema: customer.sistema || '',
    estrutura: customer.estrutura || '',
    origem: customer.origem || -1,
    host: customer.endereco_banco || '',
    banco: customer.database || '',
    estado: customer.estado || '',
    posto_mysaas: customer.posto,
  };
};

const HttpService = {
  call: (file, method, params = {}, onSuccess, onError, config = {}, idtmp = false) => {
    const options = {
      notifyError: true,
      showNotification: true,
      baseURL: process.env.REACT_APP_URL,
      url: 'link.php',
      ...config,
    };

    axios({
      method: 'post',
      baseURL: options.baseURL,
      url: options.url,
      responseType: 'json',
      responseEncoding: 'utf8',
      params: options.params,
      data: {
        file,
        method,
        params,
        react: 'S',
        ...getVars(idtmp),
      },
    })
      .then(({ data }) => {
        if (onSuccess) {
          onSuccess(data);
        }
      })
      .catch(({ response = { config: {} } }) => {
        const error = {
          payload: {
            url: response.config.url,
            data: response.config.data && JSON.parse(response.config.data),
          },
          response: response.data,
          status: response.status,
          statusText: response.statusText,
        };

        if (options.notifyError && error.status) {
          notifyError(error, options.showNotification);
        }

        if (onError) {
          onError(error);
        }
      });
  },
};

export { getVars };
export default HttpService;
