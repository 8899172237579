import React from 'react';
import { Collapse, Badge } from 'antd';
import classNames from 'classnames';
import './Panel.less';

const Panel = React.memo(({ className, color, header, forceRender = true, hidden = false, badge = undefined, ...rest }) => (
  <Collapse.Panel
    className={classNames(
        'collapse-panel',
        (color && color.className),
        className,
    )}
    header={
      <span>
        » 
        {' '} 
        {header}
        {' '} 
        {badge ? <Badge dot /> : undefined }
      </span>
      }
    forceRender={forceRender}
    style={{ display: hidden ? 'none' : 'block'}}
    {...rest}
  />
));

export default Panel;
