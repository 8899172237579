import React from 'react';
import { Row } from 'antd';
import { connect } from 'react-redux';
import { Item } from './components';

const Status = React.memo(({ record, user }) => (
  <Row gutter={4}>
    {!record.data_inc && !record.data_alt && !record.data_exc ? (
      <Item title="Seleção" user={user.login} onlyChild />
    ) : (
      <>
        {record.data_inc && (
          <Item title="Inclusão" date={record.data_inc} time={record.hora_inc} user={record.user_inc} onlyChild={!record.data_alt && !record.data_exc} />
        )}
        {(record.data_exc || record.data_alt) && (
          <Item
            title={record.data_exc ? 'Exclusão' : 'Alteração'}
            date={record.data_exc || record.data_alt}
            time={record.data_exc ? record.hora_exc : record.hora_alt}
            user={record.data_exc ? record.user_exc : record.user_alt}
            onlyChild={!record.data_inc}
          />
        )}
      </>
    )}
  </Row>
));

Status.Item = Item;

const mapStateToProps = ({ user }) => ({ user });

export { Item };
export default connect(mapStateToProps)(Status);
