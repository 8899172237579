import React from 'react';
import { Row, Col, Divider, Modal } from 'antd';
import { connect } from 'react-redux';
import { FooterToolbar, Navigator, Markdown, Page, Form, ContentWrapper, Table, Input, Button, DatePicker, Status, Statistic, Select } from '~/components';
import { actions, colors, tabPanes } from '~/options';
import README from './README.md';
import { CustomerService, PesquisaPrecoService, UserService } from '~/services';

const rowColor = [{ title: 'Alterado', color: colors.sunriseYellow, predicate: item => item.alterado }];

let timeout;

class PesquisaPrecoPage extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      itens: [],
      selectedItem: {},
      action: actions.idle,
      activeTab: tabPanes.table.key,
      isLoadingProdutos: false,
      itensProdutos: [],
      itensResumo: [],
    };
  }

  componentDidMount() {
    this.searchApi.submitForm();
    setTimeout(() => this.searchInput.focus(), 300);
  }

  handleSearch = values => {
    this.setState({ isLoading: true });
    PesquisaPrecoService.getAll({ ...values, cliente: UserService.getUser().codigo, filial: UserService.getUser().filial }, this.onSearch, () =>
      this.onSearch()
    );
  };

  onSearch = (itens = []) => {
    this.setState({ itens, isLoading: false });
  };

  handleSelect = record => {
    this.setState({ selectedItem: record, itensResumo: [], itensProdutos: [] });
    this.saveApi.reset();
    this.observacaoApi.reset();
  };

  handleSelectShow = () => {
    const { selectedItem } = this.state;

    this.saveApi.reset();
    this.observacaoApi.reset();

    if (selectedItem.codigo) {
      this.saveApi.setValues(selectedItem);
      this.observacaoApi.setValues(selectedItem);

      this.setState({ itensResumo: [], activeTab: tabPanes.record.key, isLoadingProdutos: true }, () => {
        this.onChangeData();
      });

      PesquisaPrecoService.getAllItens(
        selectedItem.codigo,
        itensResumo => {
          this.setState({ itensResumo });
        },
        () => this.setState({ itensResumo: [], isLoadingProdutos: false })
      );
    }
  };

  handleEdit = action => {
    const { itensResumo } = this.state;
    this.setState({ action, activeTab: tabPanes.record.key, itensResumo: action === actions.insert ? [] : itensResumo });
    setTimeout(() => this.editInput.focus(), 300);

    if (action === actions.insert) {
      this.saveApi.reset();
    }
  };

  onEdit = (cancel = false) => {
    let { selectedItem, activeTab } = this.state;

    if (cancel) {
      this.handleSelect(selectedItem);

      if (!selectedItem.codigo) {
        activeTab = tabPanes.table.key;
      }
    } else {
      this.searchApi.submitForm();

      const { action } = this.state;

      if (action === actions.insert) {
        activeTab = tabPanes.table.key;
        selectedItem = {};
      }
    }

    this.setState({ action: actions.idle, selectedItem, activeTab });
  };

  handleSave = values => {
    const valuesObs = this.observacaoApi.getValues();
    const { action, itensResumo } = this.state;
    let record = {};
    let saveHandler = PesquisaPrecoService.inserir;

    if (action === actions.update) {
      const { selectedItem } = this.state;
      record = selectedItem;
      saveHandler = PesquisaPrecoService.alterar;
    }

    this.setState({ isLoading: true });
    saveHandler(
      {
        ...record,
        ...values,
        ...valuesObs,
        cliente: UserService.getUser().codigo,
        filial: UserService.getUser().filial,
        nome_cliente: UserService.getUser().nome,
        vendedor: UserService.getUser().nome,
        usuario: UserService.getUser().nome,
        origem: UserService.getUser().origem_venda,
        total_pedido: itensResumo.reduce((previous, current) => parseFloat(parseFloat(current.preco_venda) * parseFloat(current.quantidade)) + previous, 0),
        itens: itensResumo,
      },
      this.onSave,
      () => this.onSave('', true)
    );
  };

  onSave = (pesquisa, error = false) => {
    this.setState({ isLoading: false, activeTab: tabPanes.table.key });

    if (!error) {
      const { itensResumo } = this.state;

      Modal.info({
        content: (
          <>
            {`Pesquisa ${pesquisa} salva com sucesso.`}
            <ul>
              <li>{`Produtos: ${itensResumo.length}`}</li>
            </ul>
          </>
        ),
        title: 'Pesquisa de Preço',
        centered: true,
      });

      this.onEdit();
    }
  };

  onBlurData = () => {
    const { itensProdutos, isLoadingProdutos } = this.state;

    if (itensProdutos.length === 0 && !isLoadingProdutos) {
      this.onChangeData();
    }
  };

  onChangeData = () => {
    const values = this.saveApi.getValues();

    this.setState({ isLoading: true });
    UserService.buscaParametros(UserService.getUser().codigo, UserService.getUser().filial, values.data, this.onBuscaParametros, () =>
      this.onBuscaParametros()
    );
  };

  onBuscaParametros = (response = []) => {
    this.setState({ isLoading: false });
    const values = this.saveApi.getValues();

    if (response.length > 0) {
      if (!response[0].tabela_precos) {
        Modal.error({ content: 'Cliente sem tabela de preços cadastrada, não é possivel efetuar pedido.', title: 'Pesquisa de Preço', centered: true });
      } else if (!response[0].data) {
        Modal.error({ content: 'Cliente sem tabela de preços preenchida, não é possivel efetuar pedido.', title: 'Pesquisa de Preço', centered: true });
      } else if (CustomerService.getCustomer().estado === 'RS' && CustomerService.getCustomer().database !== 'erp_logal' && response[0].data !== values.data) {
        Modal.info({
          content: 'Não hà mix para a data selecionada! Escolha outra data!',
          title: 'Pesquisa de Preço',
          centered: true,
          onOk: () => this.editInput.focus(),
        });
      } else {
        this.buscarDados(response[0].tabela_precos, response[0].data);
      }
    } else {
      Modal.error({ content: 'Cliente não encontrado, não é possivel efetuar pedido.', title: 'Pesquisa de Preço', centered: true });
    }
  };

  buscarDados = (tabelaPreco, dataEntrega) => {
    const values = this.saveApi.getValues();

    this.setState({ itensProdutos: [], isLoadingProdutos: true });
    PesquisaPrecoService.getAllProdutos(
      {
        data: dataEntrega || values.data,
        tabela: tabelaPreco || UserService.getUser().tabela,
        filtra_precos: UserService.getUser().filtra_precos_site,
        codigo: UserService.getUser().codigo,
        filial: CustomerService.getCustomer().estrutura === 'BENASSI' ? UserService.getUser().filial : '',
        estado: CustomerService.getCustomer().database,
      },
      this.onGetAllProdutos,
      () => this.onGetAllProdutos()
    );
  };

  onGetAllProdutos = (response = []) => {
    const { action } = this.state;

    this.setState({ itensProdutos: response.dados || [], isLoadingProdutos: false }, () => {
      if (action === actions.idle) {
        this.atualizarListaProdutos();
      }
    });
  };

  atualizarListaProdutos = () => {
    const { itensProdutos, itensResumo } = this.state;
    this.setState({
      itensProdutos: itensProdutos.map(item => {
        const itemResumo = itensResumo.find(itemFindResumo => itemFindResumo.idt_tabela === item.idt_tabela) || {};
        return {
          ...item,
          preco_loja: itemResumo.preco_loja || '',
          preco1: itemResumo.preco1 || '',
          preco2: itemResumo.preco2 || '',
          preco3: itemResumo.preco3 || '',
          alterado: itemResumo.preco1 || itemResumo.preco2 || itemResumo.preco3,
        };
      }),
    });
  };

  onEditItem = record => {
    const { itensProdutos } = this.state;
    const newItensProdutos = itensProdutos.map(item => (item.idt_tabela === record.idt_tabela ? { ...record, alterado: true } : item));

    this.setState({
      itensProdutos: newItensProdutos,
      itensResumo: newItensProdutos.filter(
        item => parseFloat(item.preco_loja) || parseFloat(item.preco1) || parseFloat(item.preco2) || parseFloat(item.preco3)
      ),
    });
  };

  onFiltraProdutos = () => {
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }
    timeout = setTimeout(() => this.forceUpdate(), 300);
  };

  render = () => {
    const { menuId, tabId, produtosGrupos, produtosGrupoSeparacao } = this.props;
    const { isLoading, itens, selectedItem, action, activeTab, itensProdutos, itensResumo, isLoadingProdutos } = this.state;
    const isEdit = action !== actions.idle && !isLoading;
    const isIdle = action === actions.idle && !isLoading;

    const valuesFilter = this.filterApi ? this.filterApi.getValues() : {};

    const itensProdutosFilter = itensProdutos.filter(
      item =>
        (valuesFilter.filtro ? item.descricao_cliente.indexOf(valuesFilter.filtro) > -1 : true) &&
        (valuesFilter.grupo && valuesFilter.grupo.length > 0 ? valuesFilter.grupo.find(itemGrupo => itemGrupo === item.grupo) : true) &&
        (valuesFilter.grupo_separacao && valuesFilter.grupo_separacao.length > 0
          ? valuesFilter.grupo_separacao.find(itemGrupo => itemGrupo === item.grupo_separacao)
          : true)
    );

    return (
      <>
        <Page
          tabbed
          tabId={tabId}
          closable={isIdle}
          helpContent={<Markdown source={README} />}
          activeKey={activeTab}
          onChange={activeKey => this.setState({ activeTab: activeKey })}
          footer={action !== actions.insert && selectedItem.codigo && <Status record={selectedItem} />}
        >
          <Page.TabPane key={tabPanes.table.key} tab={tabPanes.table.tab} disabled={action !== actions.idle}>
            <Form getApi={api => (this.searchApi = api)} onSubmit={this.handleSearch}>
              <ContentWrapper type="search">
                <Row gutter={4}>
                  <Col md={3}>
                    <Input field="codigo" label="Código" type="integer" disabled={isLoading} forwardedRef={ref => (this.searchInput = ref)} />
                  </Col>
                  <Col md={3}>
                    <DatePicker field="inicio" label="Período" disabled={isLoading} required today />
                  </Col>
                  <Col md={3}>
                    <DatePicker field="fim" label="Período" disabled={isLoading} required today />
                  </Col>
                  <Col md={3}>
                    <Button.Search disabled={isLoading} />
                  </Col>
                </Row>
              </ContentWrapper>
            </Form>
            <Table
              rowKey="codigo"
              loading={isLoading}
              record={selectedItem}
              onRecordChange={this.handleSelect}
              dataSource={itens}
              onRow={{ onDoubleClick: () => this.handleSelectShow() }}
            >
              <Table.Column width="10%" title="Código" align="right" dataIndex="codigo" />
              <Table.Column width="10%" title="Data" align="center" dataIndex="data" type="date" />
              <Table.Column width="35%" title="Status" align="center" dataIndex="status" />
              <Table.Column width="10%" title="Pesquisa 1" dataIndex="pesquisa1" />
              <Table.Column width="10%" title="Pesquisa 2" dataIndex="pesquisa2" />
              <Table.Column width="10%" title="Pesquisa 3" dataIndex="pesquisa3" />
              <Table.Column width="10%" title="Data Inclusão" align="center" dataIndex="data_inc" type="date" />
              <Table.Column title="Observação" dataIndex="observacao" onDesktop="hide" />
            </Table>
          </Page.TabPane>
          <Page.TabPane key={tabPanes.record.key} tab={tabPanes.record.tab} disabled={action === actions.idle && !selectedItem.codigo}>
            <Form getApi={api => (this.saveApi = api)} onSubmit={this.handleSave}>
              <ContentWrapper type="header" color={action.color}>
                <Row gutter={4}>
                  <Col md={3}>
                    <DatePicker
                      field="data"
                      label="Data da Pesquisa"
                      disabled={action !== actions.insert || isLoadingProdutos || isLoading}
                      onBlur={this.onBlurData}
                      onChange={this.onChangeData}
                      disabledDate={this.disabledDate}
                      forwardedRef={ref => (this.editInput = ref)}
                      today
                      required
                    />
                  </Col>
                </Row>
              </ContentWrapper>
            </Form>
            <Form getApi={api => (this.filterApi = api)} onSubmit={this.onFiltraProdutos}>
              <ContentWrapper type="search">
                <Row gutter={4}>
                  <Col md={6}>
                    <Input field="filtro" label="Filtro" type="string" disabled={!isEdit} onChange={() => this.filterApi.submitForm()} allowClear />
                  </Col>
                  <Col md={9}>
                    <Select
                      field="grupo"
                      label="Grupo"
                      mode="multiple"
                      onChange={() => this.filterApi.submitForm()}
                      dataSource={produtosGrupos.data}
                      disabled={!isEdit}
                    />
                  </Col>
                  <Col md={9}>
                    <Select
                      field="grupo_separacao"
                      label="Grupo de Separação"
                      mode="multiple"
                      onChange={() => this.filterApi.submitForm()}
                      dataSource={produtosGrupoSeparacao.data}
                      disabled={!isEdit}
                    />
                  </Col>
                </Row>
              </ContentWrapper>
            </Form>
            <Divider>Produtos</Divider>
            <Form getApi={api => (this.tableProdutoApi = api)}>
              <Table dataSource={itensProdutosFilter} rowKey="idt_tabela" rowColor={rowColor} loading={isLoadingProdutos} skipColumn={4}>
                <Table.Column width="10%" title="Cliente" align="right" dataIndex="codigo_cliente" />
                <Table.Column width="36%" title="Descrição" align="left" dataIndex="descricao_cliente" />
                <Table.Column width="8%" title="Peso" align="right" dataIndex="peso" />
                <Table.Column width="6%" title="UN" align="center" dataIndex="unidade" />
                <Table.Column
                  width="10%"
                  title="P.Loja"
                  align="center"
                  type="currency"
                  dataIndex="preco_loja"
                  disabled={!isEdit}
                  editable
                  functionAction={this.onEditItem}
                  ellipsis
                />
                <Table.Column
                  width="10%"
                  title="Pesquisa 1"
                  dataIndex="preco1"
                  type="currency"
                  disabled={!isEdit}
                  editable
                  functionAction={this.onEditItem}
                  ellipsis
                />
                <Table.Column
                  width="10%"
                  title="Pesquisa 2"
                  dataIndex="preco2"
                  type="currency"
                  disabled={!isEdit}
                  editable
                  functionAction={this.onEditItem}
                  ellipsis
                />
                <Table.Column
                  width="10%"
                  title="Pesquisa 3"
                  dataIndex="preco3"
                  type="currency"
                  disabled={!isEdit}
                  editable
                  functionAction={this.onEditItem}
                  ellipsis
                />
              </Table>
            </Form>
            <ContentWrapper type="search">
              <Row>
                <Col md={11} />
                <Col md={13}>
                  <Statistic title="Total de Itens" value={itensResumo.length} prefix="" precision={0} color="#cf1322" />
                </Col>
              </Row>
            </ContentWrapper>
            <Divider>Observações</Divider>
            <Form getApi={api => (this.observacaoApi = api)}>
              <ContentWrapper type="header" color={action.color}>
                <Row gutter={4}>
                  <Col md={24}>
                    <Input.TextArea field="observacao" label="Observações" type="string" disabled={!isEdit} autoSize={{ minRows: 5, maxRows: 5 }} />
                  </Col>
                  <Col md={8}>
                    <Input field="pesquisa1" label="Nome Pesquisa 1" disabled={!isEdit} type="string" />
                  </Col>
                  <Col md={8}>
                    <Input field="pesquisa2" label="Nome Pesquisa 2" disabled={!isEdit} type="string" />
                  </Col>
                  <Col md={8}>
                    <Input field="pesquisa3" label="Nome Pesquisa 3" disabled={!isEdit} type="string" />
                  </Col>
                </Row>
              </ContentWrapper>
            </Form>
            <Divider>Resumo</Divider>
            <Form getApi={api => (this.tableResumoApi = api)}>
              <Table dataSource={itensResumo} rowKey="idt_tabela">
                <Table.Column width="12%" title="Cliente" align="right" dataIndex="codigo_cliente" />
                <Table.Column width="35%" title="Descrição" align="left" dataIndex="descricao_cliente" />
                <Table.Column width="8%" title="Peso" align="right" dataIndex="peso" type="decimal" />
                <Table.Column width="8%" title="UN" align="center" dataIndex="unidade" />
                <Table.Column width="10%" title="P.Loja" align="center" dataIndex="preco_loja" type="decimal" />
                <Table.Column width="10%" title="Pesquisa 1" dataIndex="preco1" type="currency" ellipsis />
                <Table.Column width="10%" title="Pesquisa 2" dataIndex="preco2" type="currency" ellipsis />
                <Table.Column width="10%" title="Pesquisa 3" dataIndex="preco3" type="currency" ellipsis />
              </Table>
            </Form>
          </Page.TabPane>
        </Page>
        <FooterToolbar
          left={
            <>
              <Button.Insert perfilAcess menuId={menuId} disabled={!isIdle} onClick={() => this.handleEdit(actions.insert)} />
              <Button.Update perfilAcess menuId={menuId} disabled={!selectedItem.codigo || !isIdle} onClick={() => this.handleEdit(actions.update)} />
            </>
          }
          center={
            <>
              <Navigator
                disabled={!isIdle}
                record={selectedItem}
                dataSource={itens}
                dataIndex="codigo"
                onClick={record => this.setState({ selectedItem: record })}
              />
            </>
          }
          right={
            <>
              <Button.Cancel disabled={isIdle || isLoading} onClick={() => this.onEdit(true)} />
              <Button.Save disabled={isIdle || isLoading} loading={action !== actions.idle && isLoading} onClick={() => this.saveApi.submitForm()} />
            </>
          }
        />
      </>
    );
  };
}

const mapStateToProps = ({ produtosGrupos, produtosGrupoSeparacao }) => ({ produtosGrupos, produtosGrupoSeparacao });
export default connect(mapStateToProps)(PesquisaPrecoPage);
