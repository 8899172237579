import React from 'react';
import { Modal } from 'antd';
import { QuestionOutlined } from '@ant-design/icons';

class HelpIcon extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isVisible: false,
    };
  }

  render() {
    const { content } = this.props;
    const { isVisible } = this.state;

    return (
      <>
        <Modal
          visible={isVisible}
          centered
          onCancel={() => this.setState({ isVisible: false })}
          width={800}
          bodyStyle={{ maxHeight: '455px', overflow: 'auto' }}
          title="Ajuda"
          footer={null}
          destroyOnClose
        >
          {content}
        </Modal>
        <QuestionOutlined title="Ajuda" onClick={() => this.setState({ isVisible: true })} />
      </>
    );
  }
}

export default HelpIcon;
