import { HttpService } from '~/helpers';
import { UserService } from '~/services';

const endpoint = 'sistema/mapa_pedido';

const formatFilters = filters =>
  filters.codigo
    ? [{ nome_campo: 'a.mapa', valor: filters.mapa, tipo: 'int' }]
    : [
        { nome_campo: 'a.idt_cliente', valor: filters.cliente, tipo: 'int' },
        {
          nome_campo: 'a.data',
          valor: filters.inicio_emissao && filters.fim_emissao ? `'${filters.inicio_emissao}' AND '${filters.fim_emissao}'` : '',
          tipo: 'datas',
        },
        {
          nome_campo: 'a.origem',
          valor: filters.origem,
          tipo: 'contem',
        },
      ];

const formatValues = values => ({
  ...values,
  usuario: UserService.getUser().login,
});

const MapaPedidoService = {
  getAll: (values, onSuccess, onError) => {
    HttpService.call(endpoint, 'getAll', [formatFilters(values)], onSuccess, onError);
  },
  getAllItens: (pedido, onSuccess, onError) => {
    HttpService.call(endpoint, 'getAllItens', [pedido], onSuccess, onError);
  },
  fetchFiliaisRede: (rede, onSuccess, onError) => {
    HttpService.call(endpoint, 'getAllFiliaisRede', [rede], onSuccess, onError);
  },
  salvar: (values, onSuccess, onError) => {
    HttpService.call(endpoint, 'salvar', [formatValues(values)], onSuccess, onError);
  },
  excluir: (record, justificativa, onSuccess) => {
    HttpService.call(endpoint, 'excluir', [record, justificativa, UserService.getUser().login], onSuccess);
  },
};

export default MapaPedidoService;
