import React from 'react';
import './Body.less';

const Body = ({ children, title }) => (
  <div style={{ marginBottom: 24 }}>
    <h3 className="body-title">{title}</h3>
    {children}
  </div>
);

export default Body;
