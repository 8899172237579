import React from 'react';
import { ConfigProvider as AntdConfigProvider } from 'antd';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import moment from 'moment';
import locales, { ptBR } from '~/options/general/locales';

class ConfigProvider extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      locale: ptBR,
    };
  }

  componentDidMount() {
    this.locale();
  }

  componentDidUpdate() {
    this.locale();
  }

  locale = () => {
    const { locale: propsLocale } = this.props;
    const locale = locales.find(item => item.id === propsLocale) || ptBR;

    moment.locale(locale.id);
    this.setState({ locale });
  };

  render() {
    const { locale } = this.state;
    const { settings, locale: propsLocale, ...rest } = this.props;

    return <AntdConfigProvider locale={locale.antd} {...rest} />;
  }
}

const localeSelector = createSelector(
  settings => settings,
  ({ locale }) => locale
);

const mapStateToProps = ({ settings }) => ({ locale: localeSelector(settings) });

export default connect(mapStateToProps)(ConfigProvider);
