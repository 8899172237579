const calculaDigitoMOD11 = bloco => {
  let npeso = 2;
  let soma = 0;

  for (let i = bloco.length; i > 0; i -= 1) {
    const nvalor = parseInt(bloco.substr(i - 1, 1), 10) * npeso;
    soma += nvalor;
    npeso = npeso === 9 ? 2 : npeso + 1;
  }
  const digito = 11 - (soma % 11);
  return digito > 9 ? '1' : digito.toString();
};

export default calculaDigitoMOD11;
