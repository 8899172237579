import { createActions, createReducer } from 'reduxsauce';

// types
const { Types, Creators } = createActions({
  fetchProdutosGrupoSeparacao: [],
  fetchProdutosGrupoSeparacaoSuccess: ['data'],
  fetchProdutosGrupoSeparacaoFailure: [],
  resetProdutosGrupoSeparacao: [],
});

const INITIAL_STATE = {
  loading: false,
  data: [],
};

// reducers
const fetch = (state = INITIAL_STATE) => ({ ...state, loading: true });

const fetchSuccess = (state, { data }) => ({ data, loading: false });

const fetchFailure = (state = INITIAL_STATE) => ({ ...state, loading: false });

const reset = () => INITIAL_STATE;

// Hookup reducers to types
const Reducers = createReducer(INITIAL_STATE, {
  [Types.FETCH_PRODUTOS_GRUPO_SEPARACAO]: fetch,
  [Types.FETCH_PRODUTOS_GRUPO_SEPARACAO_SUCCESS]: fetchSuccess,
  [Types.FETCH_PRODUTOS_GRUPO_SEPARACAO_FAILURE]: fetchFailure,
  [Types.RESET_PRODUTOS_GRUPO_SEPARACAO]: reset,
});

export { Reducers, Types, Creators };
export default Reducers;
