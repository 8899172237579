import React from 'react';
import classNames from 'classnames';
import './GlobalFooter.less';

const GlobalFooter = React.memo(({ className, links, copyright }) => (
  <footer className={classNames('global-footer', className)}>
    {links && (
      <div className="global-footer-links">
        {links.map(link => (
          <a
            key={link.key}
            title={link.key}
            target={link.blankTarget ? '_blank' : '_self'}
            href={link.href}
          >
            {link.title}
          </a>
        ))}
      </div>
    )}
    {copyright && <div className="global-footer-copyright">{copyright}</div>}
  </footer>
));

export default GlobalFooter;
