import { FilePdfTwoTone, InfoCircleTwoTone } from '@ant-design/icons';
import { Divider } from 'antd';
import React from 'react';
import { Page, Button, Table } from '~/components';
import { PedidoService, UserService, WebRelatorioService } from '~/services';
import PedidoVendaMapaDetalheItensView from './PedidoVendaMapaDetalheItensView';

class PedidoVendaMapaDetalheView extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      itens: [],
      recordPedido: {},
    };
  }

  componentDidUpdate(prevProps) {
    const { record } = this.props;
    const actionChange = record.idt !== (prevProps.record || {}).idt;

    if (actionChange && record.idt) {
      this.forceUpdate(() => {
        this.setState({ isLoading: true }, () => {
          PedidoService.getAll(
            { mapa: record.mapa },
            itens => this.setState({ itens, isLoading: false }),
            () => this.setState({ itens: [], isLoading: false })
          );
        });
      });
    }
  }

  imprimir = record => {
    const values = [];

    values.push({ nome_campo: 'mostra_preco_pedidos', valor: UserService.getUser().mostra_custos_site === '1' ? 'S' : 'N' });
    values.push({ nome_campo: 'pedido', valor: record.codigo });
    values.push({ nome_campo: 'exporta_excel', valor: 'N' });
    values.push({ nome_campo: 'exporta_csv', valor: 'N' });
    values.push({ nome_campo: 'exporta_pdf', valor: 'S' });

    WebRelatorioService.getChamaRelatorio(values, 'ServletPedidos', 'Resumo do Pedido');
  };

  render = () => {
    const { onCancel, record } = this.props;
    const { isLoading, itens, recordPedido } = this.state;

    const MOSTRA_CUSTO = UserService.getUser().mostra_custos_site === '1';

    return (
      <>
        <Page.Modal
          visible={record.idt}
          title={`Pedidos Mapa: ${record.idt}`}
          width={800}
          onCancel={onCancel}
          forceRender
          centered
          closable
          destroyOnClose
          footer={
            <div style={{ textAlign: 'center' }}>
              <Button key="back" onClick={onCancel}>
                Fechar
              </Button>
            </div>
          }
        >
          <PedidoVendaMapaDetalheItensView onCancel={() => this.setState({ recordPedido: {} })} record={recordPedido} />
          <Table rowKey="codigo" loading={isLoading} dataSource={itens} onRow={{ onDoubleClick: () => this.handleSelectShow() }}>
            <Table.Column width="10%" title="Código" align="right" dataIndex="codigo" onMobile="show" />
            <Table.Column width="15%" title="Data" align="center" dataIndex="data" type="date" onMobile="show" />
            <Table.Column width="10%" title="Nota" align="center" dataIndex="nota" type="integer" />
            <Table.Column width="10%" title="Total" align="center" dataIndex="valor_bilhete" type="integer" hide={!MOSTRA_CUSTO} />
            <Table.Column width="30%" title="Status" align="center" dataIndex="status" onMobile="show" />
            <Table.Column
              align="center"
              dataIndex="actions"
              width="5%"
              title="Ações"
              exporter={false}
              sorter={false}
              render={(text, recordSelect) => (
                <>
                  <FilePdfTwoTone onClick={() => this.imprimir(recordSelect)} title="Imprimir Pedido" twoToneColor="#cc0000" />
                  <Divider type="vertical" />
                  <InfoCircleTwoTone
                    className={record.pedidos ? '' : 'icon-disabled'}
                    onClick={() => this.setState({ recordPedido: recordSelect })}
                    theme="twoTone"
                    title="Detalhes Pedido"
                    twoToneColor="#0000ff"
                  />
                </>
              )}
            />
          </Table>
        </Page.Modal>
      </>
    );
  };
}

export default PedidoVendaMapaDetalheView;
