import React, { useEffect } from 'react';
import { Strings } from '~/helpers/general';

const Title = React.memo(({ title }) => {
  const originalTitle = document.title;

  useEffect(() => {
    document.title = title;

    return () => {
      document.title = `${title ? `${title} | ` : ''}${Strings.APP_NAME}`;
    };
  }, [title, originalTitle]);
});

export default Title;
