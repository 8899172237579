import React from 'react';
import moment from 'moment';
import { Col } from 'antd';
import { firstLast, getLocale } from '~/utils';
import './Item.less';

const Item = ({ title, date, time, user, onlyChild }) => {
  const { formats } = getLocale();
  const funcionario = user;

  return (
    <Col md={onlyChild ? 24 : 12} className="status-item">
      <span className="status-title">{title}</span>
      <span className="status-content">
        {moment(time ? `${date} ${time}` : date).format(`${formats.date}${time || !date ? ` ${formats.time}` : ''}`)}
        {(funcionario || user) && <>{`(${firstLast(funcionario ? funcionario.nome : user)})`}</>}
      </span>
    </Col>
  );
};

export default Item;
