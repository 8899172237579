import {
  onlyPostal,
  onlyInteger,
  postalValue,
  onlyCnpj,
  cnpjValue,
  isCnpj,
  onlyPhone,
  phoneValue,
  isPhone,
  onlyEmail,
  isEmail,
  ncmValue,
  cestValue,
  onlyNumberDot,
  onlyLicencePlate,
  onlyCpfCnpj,
  onlyCpf,
  cpfCnpjValue,
  isDocument,
  onlyNumberDotDash,
  onlyFatura,
  faturaValue,
  isFatura,
  placaValue,
  onlyPlaca,
} from '~/utils';

const string = {
  id: 'string',
};

const integer = {
  id: 'integer',
  restrict: value => onlyInteger(value),
};

const integerDot = {
  id: 'integerDot',
  restrict: value => onlyNumberDot(value),
  parser: undefined,
};

const integerDotDash = {
  id: 'integerDotDash',
  restrict: value => onlyNumberDotDash(value),
  parser: value => value.trim(),
};

const id = {
  id: 'id',
};

const postal = {
  id: 'postal',
  restrict: value => onlyPostal(value),
  parser: value => postalValue(value),
  length: 10,
};

const placa = {
  id: 'placa',
  restrict: value => onlyPlaca(value),
  parser: value => placaValue(value),
  length: 8,
};

const cest = {
  id: 'cest',
  restrict: value => onlyNumberDot(value),
  parser: value => cestValue(value),
  length: 9,
};

const ncm = {
  id: 'ncm',
  restrict: value => onlyNumberDot(value),
  parser: value => ncmValue(value),
  length: 10,
};

const cnpj = {
  id: 'cnpj',
  restrict: value => onlyCnpj(value),
  parser: value => cnpjValue(value),
  validate: value => isCnpj(value),
};

const cpfCnpj = {
  id: 'cpfCnpj',
  restrict: value => onlyCpfCnpj(value),
  parser: value => cpfCnpjValue(value),
  validate: value => isDocument(value) || undefined,
};

const cpf = {
  id: 'cpf',
  restrict: value => onlyCpf(value),
  parser: value => cpfCnpjValue(value),
  validate: value => isDocument(value) || undefined,
};

const phone = {
  id: 'phone',
  restrict: value => onlyPhone(value),
  parser: value => phoneValue(value),
  validate: value => isPhone(value) || undefined,
};

const email = {
  id: 'email',
  restrict: value => onlyEmail(value),
  validate: value => isEmail(value) || undefined,
};

const licencePlate = {
  id: 'licencePlate',
  restrict: value => onlyLicencePlate(value),
  parser: value => value.toUpperCase(),
  length: 7,
};

const fatura = {
  id: 'fatura',
  restrict: value => onlyFatura(value),
  parser: value => faturaValue(value),
  validate: value => isFatura(value),
};

export default {
  integer,
  string,
  id,
  postal,
  cnpj,
  cpf,
  cpfCnpj,
  phone,
  email,
  cest,
  integerDot,
  ncm,
  licencePlate,
  integerDotDash,
  fatura,
  placa,
};
