const yes = {
  data: 'S',
  booleanData: true,
  label: 'SIM',
};

const no = {
  data: 'N',
  booleanData: false,
  label: 'NÃO',
};

export { yes, no };
export default [yes, no];
