import { Strings } from '~/helpers';

const support = {
  idt: 'suporte',
  nome: 'Suporte',
  chave: 'SuportePage',
  icone: 'customer-service',
};

const releaseNote = {
  idt: 'release-note',
  nome: 'Notas de Lançamentos',
  icone: 'flag',
  token: Strings && Strings.PUBSUB_RELEASE_NOTE,
};

const about = {
  idt: 'about',
  nome: 'Sobre',
  icone: 'trademark',
  token: Strings && Strings.PUBSUB_ABOUT,
};

export { support, releaseNote, about };
export default [support, releaseNote, about];
