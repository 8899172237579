import React from 'react';
import { Drawer, Alert, Button, Divider, List, message, Tooltip, Select, Switch, Input } from 'antd';
import { connect } from 'react-redux';
import { RedoOutlined } from '@ant-design/icons';
import { bindActionCreators } from 'redux';
import PubSub from 'pubsub-js';
import { actions } from '~/stores/ducks';
import { Strings } from '~/helpers/general';
import { BlockCheckbox, Body } from './components';
import { themes, layouts } from './options';
import './SettingsDrawer.less';
import { CustomerService, RememberService, UserService } from '~/services';

const paginacoes = [10, 20, 30, 40, 50, 100];

const { Option } = Select;

class SettingsDrawer extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isCollapsed: true,
      token: undefined,
    };
  }

  componentDidMount() {
    this.handleSubscription(true);
  }

  componentWillUnmount() {
    this.handleSubscription(false);
  }

  handleSubscription = subscribe => {
    let { token } = this.state;

    if (subscribe) {
      token = PubSub.subscribe(Strings.PUBSUB_SETTINGS, this.handleCollapse);
    } else {
      PubSub.unsubscribe(token);
    }

    this.setState({ token });
  };

  handleCollapse = () => {
    const { isCollapsed } = this.state;

    this.setState({ isCollapsed: !isCollapsed });
  };

  handleReset = () => {
    const { resetSettings } = this.props;

    resetSettings();
    message.success('Configurações redefinidas.');
  };

  changeSetting = (key, value) => {
    const { setSettings, settings } = this.props;
    const nextSettings = { ...settings };
    nextSettings[key] = value;

    if (key === 'fixedHeader' && !value) {
      nextSettings.autoHideHeader = false;
    } else if (key === 'layout') {
      nextSettings.contentWidth = value === 'topmenu' ? 'Fixed' : 'Fluid';
    }

    setSettings({ ...nextSettings });
  };

  getLayoutItems = () => {
    const { settings } = this.props;

    return [
      {
        title: 'Largura do conteúdo',
        action: (
          <Select size="small" style={{ width: 80 }} value={settings.contentWidth} onSelect={value => this.changeSetting('contentWidth', value)}>
            {settings.layout === 'sidemenu' ? null : <Select.Option value="Fixed">Fixo</Select.Option>}
            <Select.Option value="Fluid">Fluido</Select.Option>
          </Select>
        ),
      },
      {
        title: 'Cabeçalho Fixo',
        action: <Switch size="small" checked={!!settings.fixedHeader} onChange={checked => this.changeSetting('fixedHeader', checked)} />,
      },
      {
        title: 'Esconder o cabeçalho quando rolar',
        action: <Switch size="small" checked={!!settings.autoHideHeader} onChange={checked => this.changeSetting('autoHideHeader', checked)} />,
        disabled: !settings.fixedHeader,
        disabledReason: 'Funcional apenas quando o cabeçalho fixo estiver habilitado.',
      },
      {
        title: 'Barra lateral fixa',
        action: <Switch size="small" checked={!!settings.fixSiderbar} onChange={checked => this.changeSetting('fixSiderbar', checked)} />,
        disabled: settings.layout === 'topmenu',
        disabledReason: 'Funcional apenas quando o menu lateral estiver habilitado.',
      },
    ];
  };

  renderLayoutItem = item => {
    const action = React.cloneElement(item.action, { disabled: item.disabled });

    return (
      <Tooltip title={item.disabled ? item.disabledReason : ''} placement="left">
        <List.Item actions={[action]}>
          <span style={{ opacity: item.disabled ? '0.5' : '' }}>{item.title}</span>
        </List.Item>
      </Tooltip>
    );
  };

  atualizarStrServer = evt => {
    CustomerService.setCustomer({ ...CustomerService.getCustomer(), server_relatorio: evt.target.value });
  };

  atualizarPaginacao = value => {
    RememberService.setRemember({ ...RememberService.getRemember(), paginacao: value });
  };

  render() {
    const { settings, general } = this.props;
    const { isCollapsed } = this.state;

    return (
      <Drawer visible={!isCollapsed} width={300} className="settings-drawer" placement="right" onClose={this.handleCollapse}>
        <Body title="Estilo da página">
          <BlockCheckbox list={themes} value={settings.theme} onChange={value => this.changeSetting('theme', value)} />
        </Body>
        <Divider />
        {!general.isMobile && (
          <Body title="Modo de navegação">
            <BlockCheckbox list={layouts} value={settings.layout} onChange={value => this.changeSetting('layout', value)} />
          </Body>
        )}
        <List split={false} renderItem={this.renderLayoutItem} dataSource={this.getLayoutItems()} />
        <Divider />
        <Body title="Outras configurações">
          <List split={false}>
            <List.Item actions={[<Switch size="small" checked={!!settings.colorWeak} onChange={checked => this.changeSetting('colorWeak', checked)} />]}>
              Modo Fraco
            </List.Item>
          </List>
        </Body>
        <Divider />
        <div>
          {'Endereço relatório: '}
          <Input
            defaultValue={CustomerService.getCustomer().server_relatorio}
            onBlur={this.atualizarStrServer}
            disabled={UserService.getUser().login !== 'ADMIN'}
          />
        </div>
        <Divider />
        <div>
          {'Paginação: '}
          <Select defaultValue={RememberService.getRemember().paginacao || 10} onChange={this.atualizarPaginacao}>
            {paginacoes.map(item => (
              <Option key={item} value={item}>
                {item}
              </Option>
            ))}
          </Select>
        </div>
        <Divider />
        <Button block icon={<RedoOutlined />} onClick={this.handleReset}>
          Redefinir configurações
        </Button>
        <Alert type="warning" className="settings-drawer-hint" message="As configurações serão redefinidas ao final da sessão do usuário." />
      </Drawer>
    );
  }
}

const mapStateToProps = ({ settings, general }) => ({ settings, general });
const mapDispatchToProps = dispatch => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SettingsDrawer);
