import { combineReducers } from 'redux';
import { resettableReducer } from 'reduxsauce';
import { Strings } from '~/helpers/general';

// reducers
import { remember, user, customer, settings, general, menus, tabs, notifications, perfisLegacy } from './general';

import { produtosGrupos, produtosGrupoSeparacao } from './cadastros';

const resettable = resettableReducer(Strings.REDUX_RESET_KEY);

const rootReducer = combineReducers({
  // general
  remember: resettable(remember.Reducers),
  user: resettable(user.Reducers),
  customer: resettable(customer.Reducers),
  settings: resettable(settings.Reducers),
  general: resettable(general.Reducers),
  menus: resettable(menus.Reducers),
  tabs: resettable(tabs.Reducers),
  notifications: resettable(notifications.Reducers),
  perfisLegacy: resettable(perfisLegacy.Reducers),
  // cadastros
  produtosGrupos: resettable(produtosGrupos.Reducers),
  produtosGrupoSeparacao: resettable(produtosGrupoSeparacao.Reducers),
});

const actions = {
  // general
  ...remember.Creators,
  ...user.Creators,
  ...customer.Creators,
  ...settings.Creators,
  ...general.Creators,
  ...menus.Creators,
  ...tabs.Creators,
  ...notifications.Creators,
  ...perfisLegacy.Creators,
  // cadastros
  ...produtosGrupos.Creators,
  ...produtosGrupoSeparacao.Creators,
};

const types = {
  // general
  ...remember.Types,
  ...user.Types,
  ...customer.Types,
  ...settings.Types,
  ...general.Types,
  ...menus.Types,
  ...tabs.Types,
  ...notifications.Types,
  ...perfisLegacy.Types,
  // cadastros
  ...produtosGrupos.Types,
  ...produtosGrupoSeparacao.Types,
};

export { actions, types };
export default rootReducer;
