import {
  red,
  cyan as cyanColor,
  blue,
  magenta as magentaColor,
  volcano as volcanoColor,
  orange,
  gold,
  yellow,
  lime as limeColor,
  green,
  geekblue,
  purple,
} from '@ant-design/colors';
import './styles/index.less';

const dustRed = {
  id: 'dustRed',
  name: 'Dust Red',
  color: red.primary,
  className: 'color-dust-red',
};

const volcano = {
  id: 'volcano',
  name: 'Volcano',
  color: volcanoColor.primary,
  className: 'color-volcano',
};

const sunsetOrange = {
  id: 'sunsetOrange',
  name: 'Sunset Orange',
  color: orange.primary,
  className: 'color-sunset-orange',
};

const calendulaGold = {
  id: 'calendulaGold',
  name: 'Calendula Gold',
  color: gold.primary,
  className: 'color-calendula-gold',
};

const sunriseYellow = {
  id: 'sunriseYellow',
  name: 'Sunrise Yellow',
  color: yellow.primary,
  className: 'color-sunrise-yellow',
};

const lime = {
  id: 'lime',
  name: 'Lime',
  color: limeColor.primary,
  className: 'color-lime',
};

const polarGreen = {
  id: 'polarGreen',
  name: 'Polar Green',
  color: green[4],
  className: 'color-polar-green',
};

const cyan = {
  id: 'cyan',
  name: 'Cyan',
  color: cyanColor.primary,
  className: 'color-cyan',
};

const daybreakBlue = {
  id: 'daybreakBlue',
  name: 'Daybreak Blue',
  color: blue.primary,
  className: 'color-daybreak-blue',
};

const geekBlue = {
  id: 'geekBlue',
  name: 'Geek Glue',
  color: geekblue.primary,
  className: 'color-geek-blue',
};

const goldenPurple = {
  id: 'goldenPurple',
  name: 'Golden Purple',
  color: purple.primary,
  className: 'color-golden-purple',
};

const magenta = {
  id: 'magenta',
  name: 'Magenta',
  color: magentaColor.primary,
  className: 'color-magenta',
};

const grey = {
  id: 'grey',
  name: 'Cinza',
  color: '#bfbfbf',
  className: 'color-grey',
};

export default {
  dustRed,
  volcano,
  sunsetOrange,
  calendulaGold,
  sunriseYellow,
  lime,
  polarGreen,
  cyan,
  daybreakBlue,
  geekBlue,
  goldenPurple,
  magenta,
  grey,
};
