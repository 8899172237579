import React from 'react';
import KeyboardEventHandler from 'react-keyboard-event-handler';
import { Input as AntdInput } from 'antd';
import classNames from 'classnames';
import './InputCell.less';

let change = false;

class InputCell extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      value: props.defaultValue,
    };
  }

  render() {
    const { type, record, field, functionAction, defaultValue, ...rest } = this.props;
    const { value } = this.state;

    return (
      <KeyboardEventHandler
        handleKeys={['enter']}
        onKeyEvent={(key, e) => {
          const elements = Array.from(e.target.form.elements);
          const moveNext = elements.findIndex(item => item === e.target);
          if (moveNext > -1 && elements[moveNext + 1]) elements[moveNext + 1].focus();
        }}
      >
        <AntdInput
          size="small"
          value={value || defaultValue}
          defaultValue={defaultValue}
          className={classNames('input-number', { block: true })}
          onChange={event => {
            change = true;
            this.setState({ value: event.target.value.toString().toUpperCase() });
          }}
          onBlur={event => {
            if (change) {
              change = false;
              if (functionAction) functionAction({ ...record, [field]: value }, field);
            }
          }}
          {...rest}
        />
      </KeyboardEventHandler>
    );
  }
}

export default InputCell;
