import React from 'react';
import { SearchOutlined, UpOutlined, DownOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import { actions } from '~/options/general';
import Button from '..';

const Search = React.memo(({ children, expanded, noLabel, style = { marginBottom: '10px', marginTop: '21px' }, onExpand, loading, className }) =>
  expanded === undefined ? (
    <Button type="primary" icon={<SearchOutlined />} htmlType="submit" block style={style} loading={loading} className={className}>
      {noLabel ? true : children || actions.search.name}
    </Button>
  ) : (
    <Button.Group style={{ width: '100%', ...style }}>
      <Search style={{ width: 'calc(100% - 32px)', overflow: 'hidden', textOverflow: 'ellipsis' }} loading={loading} className={className}>
        {noLabel ? true : children || actions.search.name}
      </Search>
      <Button
        type="primary"
        icon={expanded ? <UpOutlined /> : <DownOutlined />}
        style={{ fontSize: '14px' }}
        onClick={() => onExpand(!expanded)}
        className={classNames({ 'button-loading': loading }, className)}
      />
    </Button.Group>
  )
);

export default Search;
