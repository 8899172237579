import React from 'react';
import { Menu, Modal } from 'antd';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { bindActionCreators } from 'redux';
import { actions } from '~/stores/ducks';
import { CustomerService, MenuService, UserService } from '~/services';
import './BaseMenu.less';

const menu = require('./menu.json');

class BaseMenu extends React.PureComponent {
  getPopupContainer = () => {
    const { settings } = this.props;

    if (settings.fixedHeader && settings.layout === 'topmenu') {
      return this.wrap;
    }

    return document.body;
  };

  onClick = ({ key }) => {
    const { general, setGeneral } = this.props;

    if (key === '3' && CustomerService.getCustomer().estrutura === 'BENASSI') {
      Modal.info({
        title: 'Mapa de Pedido',
        content: 'Pagina não disponível para essa empresa',
        centered: true,
      });
      return;
    }

    if (key === '5' && CustomerService.getCustomer().estrutura === 'MYSAAS') {
      Modal.info({
        title: 'Pesquisa de Preço',
        content: 'Pagina não disponível para essa empresa',
        centered: true,
      });
      return;
    }

    if (key === '3' && parseFloat(UserService.getUser().rede) === 0) {
      Modal.error({
        title: 'Mapa de Pedido',
        content: 'Cliente sem rede cadastrada!',
        centered: true,
      });
      return;
    }

    if (key === '3' && !(UserService.getUser().tabela_preco || UserService.getUser().tabela_referencial)) {
      Modal.error({
        title: 'Mapa de Pedido',
        content: 'Cliente sem tabela de preço e empresa de venda sem tabela referencial!',
        centered: true,
      });
      return;
    }

    MenuService.open(MenuService.getById(key));

    if (general.isMobile) {
      setGeneral({ ...general, isCollapsed: true });
    }
  };

  renderSubMenuOrItem = item => {
    if (item.submenus) {
      return (
        <Menu.SubMenu key={item.idt} title={item.nome}>
          {item.submenus.map(submenu => this.renderSubMenuOrItem(submenu))}
        </Menu.SubMenu>
      );
    }

    return <Menu.Item key={item.idt}>{item.nome}</Menu.Item>;
  };

  render() {
    const { className, style, mode, settings } = this.props;

    return (
      <>
        <Menu
          className={classNames(className, { 'top-navigator-menu': mode === 'horizontal' })}
          style={style}
          mode={mode}
          subMenuCloseDelay={0.5}
          subMenuOpenDelay={0.3}
          theme={settings.theme}
          getPopupContainer={this.getPopupContainer}
          selectedKeys={[]}
          onClick={this.onClick}
        >
          {menu && menu.map(item => this.renderSubMenuOrItem(item))}
        </Menu>
        <div ref={ref => (this.wrap = ref)} />
      </>
    );
  }
}

const mapStateToProps = ({ settings, general }) => ({ settings, general });
const mapDispatchToProps = dispatch => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(BaseMenu);
