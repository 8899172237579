import { decimalPlace, stringSorter, integerSorter, currencySorter, dateSorter, affirmativeValue, dateValue, cnpjValue } from '~/utils';

const string = {
  id: 'string',
  sorter: (a, b) => stringSorter(a, b),
};

const integer = {
  id: 'integer',
  sorter: (a, b) => integerSorter(a, b),
  align: 'center',
};

const document = {
  id: 'document',
  sorter: (a, b) => stringSorter(a, b),
  align: 'center',
};

const id = {
  id: 'id',
  sorter: (a, b) => stringSorter(a, b),
  align: 'center',
};

const affirmative = {
  id: 'affirmative',
  sorter: (a, b) => stringSorter(a, b),
  align: 'center',
  render: text => affirmativeValue(text),
};

const currency = {
  id: 'currency',
  sorter: (a, b) => currencySorter(a, b),
  align: 'right',
  render: text => (text === '' ? '' : decimalPlace(text, 2)),
};

const decimal = {
  id: 'decimal',
  sorter: (a, b) => currencySorter(a, b),
  align: 'right',
  render: text => decimalPlace(text, 3),
};

const empty = {
  id: 'empty',
};

const date = {
  id: 'date',
  sorter: (a, b) => dateSorter(a, b),
  align: 'center',
  render: text => (text === null || text === '0000-00-00' ? null : dateValue(text)),
};

const cnpj = {
  id: 'cnpj',
  sorter: (a, b) => stringSorter(a, b),
  align: 'center',
  render: text => cnpjValue(text),
};

export default { integer, string, document, id, affirmative, currency, decimal, empty, date, cnpj };
