import React from 'react';
import { Tooltip } from 'antd';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { LoadingOutlined, SyncOutlined } from '@ant-design/icons';
import { StoreService } from '~/services/general';
import { notifySuccess } from '~/utils';

class SyncIcon extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      notify: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { loading } = this.props;

    if (loading !== prevProps.loading && !loading) {
      this.onSync();
    }
  }

  handleSync = () => {
    const { loading } = this.props;

    if (!loading) {
      this.setState({ notify: true });
      StoreService.fetchReload();
    }
  };

  onSync = () => {
    const { notify } = this.state;

    if (notify) {
      notifySuccess({ name: 'Dados', action: { perfectPast: 'sincronizados' } });
      this.setState({ notify: false });
    }
  };

  render() {
    const { className, loading } = this.props;

    return (
      <Tooltip title="Sincronizar">
        <div className={className} onClick={this.handleSync} onKeyPress={this.handleSync} style={loading ? { cursor: 'default' } : undefined} role="button">
          {loading ? <LoadingOutlined style={{ fontSize: '16px' }} /> : <SyncOutlined style={{ fontSize: '16px', color: 'aqua' }} />}
        </div>
      </Tooltip>
    );
  }
}

const loadingSelector = createSelector(
  state => state,
  ({ produtosGrupos, produtosGrupoSeparacao }) => produtosGrupos.loading || produtosGrupoSeparacao.loading
);

const mapStateToProps = state => ({ loading: loadingSelector(state) });

export default connect(mapStateToProps)(SyncIcon);
