import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Result, Button } from 'antd';
import { Creators } from '~/stores/ducks/general/tabs';

const NotFoundPage = ({ tabId, removeTab }) => (
  <Result
    status="404"
    title="404"
    subTitle="A página que você requisitou não existe!"
    extra={
      <Button type="primary" onClick={() => removeTab(tabId)}>
        Fechar Página
      </Button>
    }
  />
);

const mapDispatchToProps = dispatch => bindActionCreators(Creators, dispatch);

export default connect(undefined, mapDispatchToProps)(NotFoundPage);
