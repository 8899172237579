import React from 'react';
import { useField } from 'informed';
import { DatePicker, Tooltip } from 'antd';
import classNames from 'classnames';
import { SizeMe } from 'react-sizeme';
import { isRequired, momentValue, getLocale } from '~/utils';
import { Form } from '~/components/forms';
import './RangePicker.less';

const RangePicker = React.memo(({ required, validate, validateOnChange, ...props }) => {
  const { fieldState, fieldApi, render, userProps } = useField({
    validate: validate || (required && isRequired),
    validateOnChange: validateOnChange || required || validate,
    ...props,
  });
  const {
    onChange,
    onBlur,
    label,
    initialValue,
    forwardedRef,
    allowClear,
    formItemProps,
    className,
    block,
    dropdownClassName,
    popupStyle,
    locale,
    disabledReason,
    tooltipProps,
    ...rest
  } = userProps;
  const showValue = fieldState.value || initialValue || [];

  return render(
    <Form.Item label={label} error={fieldState.error} required={required} {...formItemProps}>
      <Tooltip title={rest.disabled && disabledReason} placement="bottom" {...tooltipProps}>
        <SizeMe>
          {({ size }) => (
            <DatePicker.RangePicker
              value={[momentValue(showValue[0]), momentValue(showValue[1])]}
              ref={forwardedRef}
              className={classNames('date-picker', { block: block === true }, className)}
              dropdownClassName={classNames('date-picker-dropdown', { block: block === true }, dropdownClassName)}
              format={getLocale().formats.date}
              onChange={(dates, dateString) => {
                fieldApi.setValue(dates);

                if (onChange) {
                  onChange(dates, dateString);
                }
              }}
              onBlur={event => {
                fieldApi.setTouched();

                if (onBlur) {
                  onBlur(event);
                }
              }}
              allowClear={!required && !rest.disabled && allowClear}
              popupStyle={{
                ...(block && size.width > 280 && { width: size.width }),
                ...popupStyle,
              }}
              {...rest}
            />
          )}
        </SizeMe>
      </Tooltip>
    </Form.Item>
  );
});

export default RangePicker;
