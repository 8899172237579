import React from 'react';
import { Table as AntdTable, Divider, Row, Col } from 'antd';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { wordCounter } from '~/utils';
import { Export, ExportPDF, Legend } from './components';
import { types, display } from './options';
import { getRowColor } from './utils';
import './Table.less';
import { DatePickerCell, InputCell, InputNumberCell } from '~/components';
import { RememberService } from '~/services';

const { Column } = AntdTable;

class Table extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      record: {},
      pageSize: RememberService.getRemember().paginacao || 100,
      selectedSorter: undefined,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { record, dataSource } = this.props;

    if (JSON.stringify(record) !== JSON.stringify(prevState.record) || dataSource !== prevProps.dataSource) {
      this.onClick(record);
    }
  }

  onClick = newRecord => {
    const { record } = this.state;
    const { onRecordChange, rowKey, dataSource } = this.props;
    const newRecordSource = dataSource.find(item => item[rowKey] === record[rowKey] || -1);

    if (newRecord && record[rowKey] !== newRecord[rowKey] && newRecordSource) {
      this.setState({ record: newRecord });

      if (onRecordChange) {
        onRecordChange(newRecord);
      }
    }
  };

  onExpand = (expanded, record) => {
    const { rowKey, dataSource, onExpand } = this.props;

    this.onClick(
      record,
      dataSource.findIndex(item => item[rowKey] === record[rowKey])
    );

    if (onExpand) {
      onExpand(expanded, record);
    }
  };

  getColumns = expanded => {
    const { columns: propsColumns, children, isMobile } = this.props;
    const columns = propsColumns || React.Children.map(children, ({ props }) => props);

    let newColumns = columns.filter(({ hide }) => !hide);
    if (expanded) {
      if (!isMobile) {
        newColumns = newColumns.filter(({ onDesktop }) => onDesktop === display.hide.id);
      } else {
        newColumns = newColumns.filter(({ onMobile }) => !onMobile || onMobile === display.expanded.id);
      }
    } else if (isMobile) {
      const mobileColumns = newColumns.filter(({ onMobile }) => onMobile === display.show.id);
      newColumns = mobileColumns.length > 0 ? mobileColumns : [{ type: types.empty.id, dataIndex: new Date().toISOString() }];
    } else {
      newColumns = newColumns.filter(({ onDesktop }) => onDesktop !== display.hide.id);
    }

    return newColumns;
  };

  rowClassName = (record, index) => {
    const { record: stateRecord } = this.state;
    const { rowClassName, rowKey, selecionar, rowColor = [] } = this.props;
    const rowColorClassName = rowColor.length > 0 && getRowColor(record, rowColor).className;

    return classNames(
      'table-row',
      { odd: !rowColorClassName && index % 2 !== 0 },
      { selected: stateRecord[rowKey] === record[rowKey] && (selecionar || selecionar === undefined) },
      rowColorClassName,
      { 'color-table-row': rowColorClassName },
      rowClassName
    );
  };

  expandedRowRender = (record, index, indent, expanded) => {
    const { expandedRowRender } = this.props;

    return (
      <Row gutter={4} className="table-expanded">
        {[
          ...this.getColumns(true),
          ...(expandedRowRender
            ? [
                {
                  dataIndex: 'expanded',
                  title: 'Expandido',
                  render: () => expandedRowRender(record, index, indent, expanded),
                },
              ]
            : []),
        ].map(({ dataIndex, title, type = types.string.id, render: propsRender }) => {
          const render = propsRender || types[type].render;

          return (
            <Col span={24} key={dataIndex} className="table-expanded-item">
              <span className="table-expanded-title">{title}</span>
              <span className="table-expanded-content">{render ? render(record[dataIndex], record) : record[dataIndex]}</span>
            </Col>
          );
        })}
      </Row>
    );
  };

  renderFooter() {
    const { dataSource, rowColor = [] } = this.props;
    const { selectedSorter } = this.state;

    return (
      <div className="table-footer">
        <div className="table-footer-left">{wordCounter(dataSource.length, 'registro')}</div>
        <div className="table-footer-right">
          {rowColor.length > 0 && (
            <>
              <Legend colors={rowColor} placement="left" />
              <Divider type="vertical" />
              <ExportPDF
                dataSource={dataSource}
                selectedSorter={selectedSorter}
                columns={this.getColumns()}
                className={dataSource.length === 0 ? 'icon-disabled' : ''}
              />
              <Divider type="vertical" />
            </>
          )}
          <Export dataSource={dataSource} columns={this.getColumns()} />
        </div>
      </div>
    );
  }

  renderChild = ({ dataIndex, type = types.string.id, editable, disabled, functionAction, ...rest }, isMobile) => (
    <Column
      key={dataIndex}
      dataIndex={dataIndex}
      sorter={(a, b) => types[type].sorter(a[dataIndex], b[dataIndex])}
      align={types[type].align}
      render={editable ? (text, record) => this.renderEditable(type, record, disabled, dataIndex, functionAction) : types[type].render}
      {...rest}
      width={isMobile ? 'auto' : rest.width || 'auto'}
    />
  );

  renderEditable = (type, record, disabled, dataIndex, functionAction) => {
    const { skipColumn } = this.props;

    if (type === 'currency' || type === 'decimal')
      return (
        <InputNumberCell
          disabled={disabled}
          type={type}
          field={dataIndex}
          functionAction={functionAction}
          record={record}
          skipColumn={skipColumn || 1}
          defaultValue={record[dataIndex] === null || record[dataIndex] === undefined ? '' : record[dataIndex]}
        />
      );
    if (type === 'string' || type === 'integer')
      return (
        <InputCell
          disabled={disabled}
          type={type}
          field={dataIndex}
          functionAction={functionAction}
          record={record}
          defaultValue={record[dataIndex]}
          skipColumn={skipColumn || 1}
        />
      );
    if (type === 'date')
      return (
        <DatePickerCell
          disabled={disabled}
          type={type}
          field={dataIndex}
          functionAction={functionAction}
          record={record}
          skipColumn={skipColumn || 1}
          defaultValue={(record[dataIndex] || '').toString()}
        />
      );
  };

  onShowSizeChange = (current, pageSize) => {
    this.setState({ pageSize });
  };

  onChangeTable = (pagination, filters, sorter) => {
    this.setState({ selectedSorter: sorter });
  };

  render() {
    const { pageSize } = this.state;
    const { className, rowClassName, pagination, onRow = {}, isMobile, expandedRowRender, ...rest } = this.props;
    const { onClick, ...onRowProps } = onRow;

    const newPagination = pagination || { pageSize: pageSize || 10 };
    const newPaginationState = pageSize !== 10 && !(pagination && pagination.hideOnSinglePage) ? { pageSize } : {};

    const heigthTable = pagination && pagination.hideOnSinglePage && rest.scroll && rest.scroll.y ? { height: `${rest.scroll.y + 35}px` } : {};

    return (
      <div style={heigthTable}>
        <AntdTable
          bordered
          size="middle"
          pagination={{
            showLessItems: isMobile,
            onShowSizeChange: this.onShowSizeChange,
            ...{ ...newPagination, ...newPaginationState },
          }}
          className={classNames('table-main', className)}
          rowClassName={this.rowClassName}
          footer={() => this.renderFooter()}
          onRow={(record, index) => ({
            onClick: () => this.onClick(record, index),
            ...onRowProps,
          })}
          expandRowByClick={isMobile}
          onExpand={this.onExpand}
          onChange={this.onChangeTable}
          expandedRowRender={this.getColumns(true).length > 0 ? this.expandedRowRender : expandedRowRender}
          {...rest}
        >
          {this.getColumns().map(props => this.renderChild(props, isMobile))}
        </AntdTable>
      </div>
    );
  }
}

Table.Column = Column;

const isMobileSelector = createSelector(
  general => general,
  ({ isMobile }) => isMobile
);

const mapStateToProps = ({ general }) => ({ isMobile: isMobileSelector(general) });

export { Column };
export default connect(mapStateToProps)(Table);
