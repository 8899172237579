const table = { key: 'table', tab: 'Tabela' };

const record = { key: 'record', tab: 'Ficha' };

const order = { key: 'order', tab: 'Pedidos' };

const delivery = { key: 'delivery', tab: 'Entrega' };

const settings = { key: 'settings', tab: 'Settings' };

export default { table, record, order, delivery, settings };
